import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
  CommentReportOrderField,
  OrderDirection,
  ReportReadStatus,
  useReportedCommentsLazyQuery,
} from 'generated/graphql'
import { message, Skeleton } from 'antd'
import { AdminMenuTitle } from 'components/AdminMenuTitle'
import TableReportComment from '../component/table/TableReportComment'
import useAuthenticationCheck from 'resources/best-posts/hooks/useAuthenticationCheck'
import generateVariablesReportComment from '../gql/variable/generateVariablesReportComment'
import RadioReadConditionSelect from '../../component/radio/RadioReadConditionSelect'
import FormDateRangePickerWithSelectInput from '../../component/form/FormDateRangePickerWithSelectInput'
import convertPaginiationQueryString from 'app.module/module/convertPaginiationQueryString'

type VaraibleState = {
  readState: string
  startDate: string | null
  endDate: string | null
  selectCondition: string
  inputCondition: string | null
  field: CommentReportOrderField
  direction: OrderDirection
}

const ScreenReportComment = () => {
  useAuthenticationCheck()
  const router = useHistory()
  const { search } = useLocation()
  const urlParams: any = convertPaginiationQueryString(search)

  const [variables, setVariables] = useState<VaraibleState>({
    readState: urlParams.readState || 'unread',
    startDate: null,
    endDate: null,
    selectCondition: 'contentText',
    inputCondition: null,
    field: CommentReportOrderField['LAST_REPORTED_AT'],
    direction: OrderDirection['DESC'],
  })

  const [refetch, { data, loading, error }] = useReportedCommentsLazyQuery({
    fetchPolicy: 'no-cache',
    variables: {
      ...generateVariablesReportComment(),
      filterBy: {
        reportReadStatus:
          ReportReadStatus[variables['readState'].toUpperCase()],
      },
    },
  })

  if (error) {
    message.error('에러가 발생했습니다.')
    return <div>에러가 발생했습니다.</div>
  }

  const handleFormSubmit = async ({
    startDate,
    endDate,
    selectCondition,
    inputCondition,
  }) => {
    setVariables({
      ...variables,
      startDate,
      endDate,
      selectCondition,
      inputCondition,
    })
    router.push(
      `?page=1&pageSize=${Number(urlParams.pageSize) || 10}&readState=${
        variables.readState
      }`,
    )
  }

  useEffect(() => {
    router.push(
      `?page=${Number(urlParams.page) || 1}&pageSize=${Number(
        urlParams.pageSize,
      ) || 10}&readState=${variables.readState}`,
    )
  }, [])

  useEffect(() => {
    refetch({
      variables: {
        filterBy: {
          startDate: variables['startDate'],
          endDate: variables['endDate'],
          reportReadStatus:
            ReportReadStatus[variables['readState'].toUpperCase()],
          [variables.selectCondition]: variables.inputCondition,
        },
        orderBy: {
          field: CommentReportOrderField[variables.field],
          direction: OrderDirection[variables.direction],
        },
        pagination: {
          page: Number(urlParams.page) || 1,
          pageSize: Number(urlParams.pageSize) || 10,
        },
      },
    })
  }, [variables, search])

  const handleChangeReadState = async (state: string) => {
    setVariables({
      ...variables,
      readState: state,
    })
    router.push(
      `?page=1&pageSize=${urlParams.pageSize || 10}&readState=${state}`,
    )
  }

  const handleChangeTableSorting = (field: string, direction: string) => {
    setVariables(prev => {
      if (prev.direction == direction) return prev
      return {
        ...prev,
        field: CommentReportOrderField[field],
        direction: OrderDirection[direction],
      }
    })

    router.push(
      `?page=1&pageSize=${urlParams.pageSize || 10}&readState=${
        variables.readState
      }`,
    )
  }

  return (
    <>
      <AdminMenuTitle
        title="신고 댓글"
        description="신고된 댓글을 조회힙니다"
      />
      <RadioReadConditionSelect onChangeReadState={handleChangeReadState} />
      <FormDateRangePickerWithSelectInput
        type="contentText"
        onFormSubmit={handleFormSubmit}
      />
      {loading ? (
        <Skeleton />
      ) : (
        <TableReportComment
          data={data?.reportedComments?.nodes}
          total={data?.reportedComments?.totalCount}
          onDirectionChange={handleChangeTableSorting}
        />
      )}
    </>
  )
}

export default ScreenReportComment
