import { Rule } from 'antd/lib/form';

export const inputValidationCondition: Rule[] = [
  {
    required: true,
    message: '활동 정지 사유를 작성해주세요.',
  },
  {
    max: 25,
    message: '25자 이내로 입력해주세요.',
  },
  {
    pattern: /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/,
    message: '한글만 입력해주세요.',
  },
];
