import React, { useState } from 'react';
import { Button } from 'antd';

const ColumnsDetailReportComment = (handleReadComment: any) => {
  return [
    {
      title: '신고 일시',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record) => (
        <span className={record.adminReadAt ? 'read-comment-column' : ''}>
          {record['createdAt']}
        </span>
      ),
    },
    {
      title: '신고자',
      dataIndex: ['reporterNickname', 'reporterId'],
      key: 'repoterId',
      render: (_, record) => (
        <span className={record.adminReadAt ? 'read-comment-column' : ''}>
          닉네임 : {record['reporterNickname']} / ID : {record['reporterId']}
        </span>
      ),
    },
    {
      title: '신고사유',
      dataIndex: 'description',
      key: 'description',
      render: (_, record) => (
        <span className={record.adminReadAt ? 'read-comment-column' : ''}>
          {record['description']}
        </span>
      ),
    },
    {
      title: '',
      dataIndex: '',
      render: (_, record: any) => (
        <Button
          size="small"
          disabled={record.adminReadAt !== null}
          onClick={() => handleReadComment(record.key)}
        >
          신고확인
        </Button>
      ),
    },
  ];
};

export default ColumnsDetailReportComment;
