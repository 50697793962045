import React, { FC } from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  Filter,
  DateInput,
} from 'react-admin'

const DateFilter: FC = props => (
  <Filter {...props}>
    <DateInput label="Date" source="dateAt" alwaysOn />
  </Filter>
)

const DailyCommentByUserList: FC = props => (
  <List {...props} filters={<DateFilter />}>
    <Datagrid>
      <TextField source="author.id" label="작성자ID" sortable={false} />
      <TextField source="ipaddress" label="작성자IP" sortable={false} />
      <DateField source="createdAt" label="작성일" showTime sortable={false} />
      <TextField source="board.title" label="게시판이름" sortable={false} />
      <TextField source="author.email" label="작성자이메일" sortable={false} />
      <TextField
        source="author.username"
        label="msg.username"
        sortable={false}
      />
      <TextField source="nickname" label="msg.nickname" sortable={false} />
    </Datagrid>
  </List>
)

export default DailyCommentByUserList
