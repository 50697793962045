import React, { FC, useCallback } from 'react'
import {
  TextField,
  DateField,
  Edit,
  SimpleForm,
  FormDataConsumer,
  TextInput,
  BooleanInput,
  NumberInput,
  ReferenceArrayInput,
  SelectArrayInput,
  AutocompleteArrayInput,
  CardActions,
  refreshView as refreshViewAction,
} from 'react-admin'
import { connect } from 'react-redux'
import { Button } from '@material-ui/core'
import { gql } from 'graphql.macro'
import { usePostCollectionRebuildMutation } from 'generated/graphql'
import { stripTypename } from 'utils'

interface RefreshButtonProps {
  data: any
  refreshView: () => any
  children: any
}

const RefreshButton: FC<RefreshButtonProps> = ({
  data,
  refreshView,
  children,
}) => {
  const [mutate] = usePostCollectionRebuildMutation()
  const handleClick = useCallback(async () => {
    await mutate({
      variables: {
        id: data.id,
        automation: stripTypename(data.automation),
      },
    })

    refreshView()
  }, [data])

  return (
    <Button color="primary" onClick={handleClick}>
      {children}
    </Button>
  )
}

const WrappedRefreshButton = connect(
  null,
  { refreshView: refreshViewAction },
)(RefreshButton)

const PostEditActions: FC = ({ basePath, data, resource }: any) => {
  return (
    <CardActions>
      <WrappedRefreshButton data={data}>자동 작성 실행</WrappedRefreshButton>
    </CardActions>
  )
}

interface PostTitleProps {
  record?: any
}

const PostCollectionTitle: FC<PostTitleProps> = ({ record }) => {
  return <span>Post {record ? `"${record.title}"` : ''}</span>
}

const required = (message = 'Required') => (value: any) =>
  value ? undefined : message

function filterToQuery(searchText: string) {
  return {
    field: 'title',
    word: searchText,
  }
}

const PostCollectionEdit: FC = props => {
  return (
    <Edit
      title={<PostCollectionTitle />}
      actions={<PostEditActions />}
      {...props}
    >
      <SimpleForm>
        <TextField source="id" label="ID" />
        <TextInput source="title" label="제목" validate={required()} />
        <ReferenceArrayInput
          source="postIDs"
          reference="posts"
          filterToQuery={filterToQuery}
        >
          <AutocompleteArrayInput optionText="title" />
        </ReferenceArrayInput>
        <BooleanInput source="automation.enabled" label="자동화 사용 여부" />
        <FormDataConsumer>
          {({ formData, ...rest }: any) =>
            formData.automation.enabled && (
              <ReferenceArrayInput
                source="automation.boardIDs"
                label="자동화 대상 게시판"
                reference="boards"
                filterToQuery={filterToQuery}
                perPage={100}
                {...rest}
              >
                <SelectArrayInput optionText="title" />
              </ReferenceArrayInput>
            )
          }
        </FormDataConsumer>
        <FormDataConsumer>
          {({ formData, ...rest }: any) =>
            formData.automation.enabled && (
              <NumberInput
                source="automation.filters.views.gte"
                label="조회수 최소"
                step={1}
              />
            )
          }
        </FormDataConsumer>
        <DateField source="updatedAt" label="최근 수정일" showTime />
      </SimpleForm>
    </Edit>
  )
}

export default PostCollectionEdit

gql`
  mutation PostCollectionRebuild(
    $id: ID!
    $automation: PostCollectionAutomationInput
  ) {
    postCollectionRebuild(id: $id, automation: $automation) {
      postCollection {
        id
      }
    }
  }
`
