import React from 'react'
import styled from 'styled-components'
import { Button, Table, Typography } from 'antd'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import {
  PostQueryVariables,
  usePostDeleteByReportedMutation,
  useReadAllPostAbuseReportLogMutation,
  useReadPostAbuseReportLogMutation,
} from 'generated/graphql'
import ColumnsDetailReportPost from '../column/ColumnsDetailReportPost'
import convertPaginiationQueryString from 'app.module/module/convertPaginiationQueryString'
import { format } from 'date-fns'
import generateMutationMessage from 'app.feature/board/report/gql/variable/generateMutationMessage'
const { Paragraph } = Typography

type TProps = {
  data: any
  totalCount: number | undefined
  deletedAt: Date | null
  onRefetch: (variables?: PostQueryVariables | undefined) => void
}

const TableDetailReportPost: React.FC<TProps> = ({
  data,
  totalCount,
  deletedAt,
  onRefetch,
}) => {
  const dataSource = data?.map(item => {
    return {
      key: item?.id,
      reporterId: item?.reporter?.id,
      description: item?.description,
      reporterNickname: item?.reporter?.nickname,
      createdAt: format(new Date(item?.createdAt), 'yyyy-MM-dd HH:mm'),
      adminReadAt: item?.adminReadAt,
    }
  })

  const params = useParams()
  const [readPostAbuseReportLogMutation] = useReadPostAbuseReportLogMutation({
    ...generateMutationMessage(
      '신고 확인 처리에 실패했습니다.',
      '신고 확인 처리 되었습니다.',
    ),
  })

  const handleReadReport = async (key: any) => {
    await readPostAbuseReportLogMutation({
      variables: {
        readPostAbuseReportLogId: key,
      },
    })
    onRefetch()
  }

  const tableColumns = ColumnsDetailReportPost(handleReadReport)

  const router = useHistory()
  const { search } = useLocation()
  const pagination: any = convertPaginiationQueryString(search)

  const paginationOptions = {
    pageSize: Number(pagination.pageSize || 10),
    current: Number(pagination.page) || 1,
    showSizeChanger: true,
    total: totalCount,
    onChange(page, pageSize) {
      router.push(`?page=${page}&pageSize=${pageSize}`)
      onRefetch({
        postId: params['id'],
        pagination: {
          page,
          pageSize,
        },
      })
    },
  }

  const [postDeleteByReportedMutation] = usePostDeleteByReportedMutation({
    ...generateMutationMessage(
      '게시글 삭제 처리에 실패했습니다.',
      '해당 게시글이 삭제되었습니다.',
    ),
  })

  const handleConfirmDelete = async () => {
    await postDeleteByReportedMutation({
      variables: {
        postDeleteId: params['id'],
      },
    })
    onRefetch()
  }

  const [
    readAllPostAbuseReportLogMutation,
  ] = useReadAllPostAbuseReportLogMutation({
    ...generateMutationMessage(
      '신고 모두 확인 처리에 실패했습니다.',
      '모든 신고 내역이 확인 처리 되었습니다.',
    ),
  })

  const handleAllConfirmReport = async () => {
    await readAllPostAbuseReportLogMutation({
      variables: {
        postId: params['id'],
      },
    })
    onRefetch()
  }

  return (
    <StyledWrapper>
      <h2>
        <Paragraph>
          <blockquote>신고 상세 내역</blockquote>
        </Paragraph>
      </h2>
      <Table
        size="small"
        bordered
        dataSource={dataSource}
        columns={tableColumns}
        pagination={paginationOptions}
        locale={{ emptyText: '데이터가 없습니다.' }}
      />
      <div className="sort-button">
        <Button
          className="button"
          disabled={deletedAt ? true : false}
          onClick={handleConfirmDelete}
        >
          게시글 삭제
        </Button>
        <Button
          type="primary"
          onClick={handleAllConfirmReport}
          disabled={dataSource.every(item => item.adminReadAt)}
        >
          신고 모두 확인
        </Button>
      </div>
    </StyledWrapper>
  )
}

export default TableDetailReportPost

const StyledWrapper = styled.div`
  .sort-button {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 15px;
  }
  .button {
    margin-left: 10px;
  }
  .read-report-column {
    text-decoration: line-through double;
    text-decoration-color: #404040;
  }
`
