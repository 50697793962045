import { addDays } from 'date-fns';

const getDateRange = (period: string) => {
  const startAt = Date.now();
  const endAt =
    period === 'permanent'
      ? new Date('2999-12-31').getTime()
      : addDays(startAt, Number(period)).getTime();
  return [startAt, endAt];
};

export default getDateRange;
