import { Button } from 'antd'
import React, { FC } from 'react'
import styled from 'styled-components'

interface SubmitButtonsProps {
  isChanging: boolean
  enableSubmit: boolean
  onBackToList: () => void
  onChangeStart: () => void
  onChangeRequirement: () => void
}

const SubmitButtons: FC<SubmitButtonsProps> = ({
  isChanging = false,
  enableSubmit = false,
  onChangeStart,
  onBackToList,
  onChangeRequirement,
}) => {
  return (
    <StyledWrapper>
      {isChanging ? (
        <>
          <Button
            disabled={!enableSubmit}
            className="submit-button"
            htmlType="submit"
            type="primary"
            onClick={onChangeRequirement}
          >
            변경된 조건 반영하기
          </Button>
          <Button
            className="submit-button"
            htmlType="button"
            onClick={onBackToList}
          >
            목록으로
          </Button>
        </>
      ) : (
        <Button
          className="submit-button"
          htmlType="button"
          onClick={onChangeStart}
        >
          조건 변경
        </Button>
      )}
    </StyledWrapper>
  )
}

export default SubmitButtons

const StyledWrapper = styled.div`
  margin-top: 20px;
  .submit-button {
    margin-right: 5px;
  }
`
