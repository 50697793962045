import React from 'react'
import styled from 'styled-components'
import { Table, Typography } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { PostDeleteReportDetailQueryVariables } from 'generated/graphql'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { format } from 'date-fns'
import ColumnDetailDeletedPost from '../column/ColumnDetailDeletedPost'
import convertPaginiationQueryString from 'app.module/module/convertPaginiationQueryString'
const { Title, Paragraph } = Typography

type TProps = {
  data: any
  total: number | undefined
  onRefetch: (
    variables?: PostDeleteReportDetailQueryVariables | undefined,
  ) => void
}

const TableDetailDeletedPost: React.FC<TProps> = ({
  data,
  total,
  onRefetch,
}) => {
  const dataSource = data?.map(item => {
    return {
      key: item?.id,
      createdAt: format(new Date(item?.createdAt), 'yyyy-MM-dd HH:mm'),
      reporterNickname: item?.reporter?.nickname,
      reporterId: item?.reporter?.id,
      description: item?.description,
    }
  })

  const params = useParams()
  const tableColumns: ColumnsType<any> = ColumnDetailDeletedPost()
  const router = useHistory()
  const { search } = useLocation()
  const pagination: any = convertPaginiationQueryString(search)

  const paginationOptions = {
    pageSize: Number(pagination.pageSize || 10),
    current: Number(pagination.page) || 1,
    showSizeChanger: true,
    total,
    onChange(page, pageSize) {
      onRefetch({
        postId: params['id'],
        filterBy: {
          postID: params['id'],
        },
        pagination: {
          page,
          pageSize,
        },
      })
      router.push(`?page=${page}&pageSize=${pageSize}`)
    },
  }

  return (
    <StyledWrapper>
      <Title level={4}>
        <Paragraph>
          <blockquote>신고 상세 내역</blockquote>
        </Paragraph>
      </Title>
      <Table
        bordered
        size="small"
        className="table"
        dataSource={dataSource}
        columns={tableColumns}
        pagination={paginationOptions}
        locale={{ emptyText: '데이터가 없습니다.' }}
      />
    </StyledWrapper>
  )
}

export default TableDetailDeletedPost

const StyledWrapper = styled.div`
  .table {
    padding-top: 15px;
  }
  .sort-button {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 15px;
  }
  .button {
    margin-left: 10px;
  }
`
