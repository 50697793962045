import React, { FC } from 'react'
import { TextInput, SimpleForm, Edit } from 'react-admin'

const TagEdit: FC = props => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="name" />
      </SimpleForm>
    </Edit>
  )
}

export default TagEdit
