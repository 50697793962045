import React from 'react'
import styled from 'styled-components'
import { Button, Table, Typography } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import ColumnsDetailReportComment from '../column/ColumnsDetailReportComment'
import {
  CommentsQueryVariables,
  useCommentDeleteMutation,
  useReadAllCommentAbuseReportLogMutation,
  useReadCommentAbuseReportLogMutation,
} from 'generated/graphql'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import convertPaginiationQueryString from 'app.module/module/convertPaginiationQueryString'
import { format } from 'date-fns'
import generateMutationMessage from 'app.feature/board/report/gql/variable/generateMutationMessage'
const { Title, Paragraph } = Typography

type TProps = {
  data: any
  total: number | undefined
  deletedAt: Date | null
  onRefetch: (variables?: CommentsQueryVariables | undefined) => void
}

const TableDetailReportComment: React.FC<TProps> = ({
  data,
  total,
  deletedAt,
  onRefetch,
}) => {
  const dataSource = data?.map(item => {
    return {
      key: item?.id,
      createdAt: format(new Date(item?.createdAt), 'yyyy-MM-dd HH:mm'),
      reporterId: item?.reporter?.id,
      reporterNickname: item?.reporter?.nickname,
      description: item?.description,
      adminReadAt: item?.adminReadAt,
    }
  })

  const [
    readCommentAbuseReportLogMutation,
  ] = useReadCommentAbuseReportLogMutation({
    ...generateMutationMessage(
      '신고 확인 처리에 실패했습니다.',
      '신고 확인 처리 되었습니다.',
    ),
  })

  const handleReadComment = async (key: string) => {
    await readCommentAbuseReportLogMutation({
      variables: {
        readCommentAbuseReportLogId: key,
      },
    })
    onRefetch()
  }

  const tableColumns: ColumnsType<any> = ColumnsDetailReportComment(
    handleReadComment,
  )

  const router = useHistory()
  const params = useParams()
  const { search } = useLocation()
  const pagination: any = convertPaginiationQueryString(search)

  const paginationOptions = {
    pageSize: Number(pagination.pageSize || 10),
    current: Number(pagination.page) || 1,
    showSizeChanger: true,
    total,
    onChange(page, pageSize) {
      router.push(`?page=${page}&pageSize=${pageSize}`)
      onRefetch({
        commentId: params['id'],
        filterBy: {
          commentID: params['id'],
        },
        pagination: {
          page,
          pageSize,
        },
      })
    },
  }

  const [commentDeleteMutation] = useCommentDeleteMutation({
    ...generateMutationMessage(
      '댓글 삭제 처리에 실패했습니다.',
      '해당 댓글이 삭제되었습니다.',
    ),
  })

  const handleOnDeleteComment = async () => {
    await commentDeleteMutation({
      variables: {
        commentDeleteId: params['id'],
      },
    })
    onRefetch()
  }

  const [
    readAllAbuseReportLogMutation,
  ] = useReadAllCommentAbuseReportLogMutation({
    ...generateMutationMessage(
      '신고 모두 확인 처리에 실패했습니다.',
      '모든 신고 내역이 확인 처리 되었습니다.',
    ),
  })

  const handleAllConfirmComment = async () => {
    await readAllAbuseReportLogMutation({
      variables: {
        commentId: params['id'],
      },
    })
    onRefetch()
  }

  return (
    <StyledWrapper>
      <Title level={4}>
        <Paragraph>
          <blockquote>신고 상세 내역</blockquote>
        </Paragraph>
      </Title>
      <Table
        bordered
        size="small"
        className="table"
        dataSource={dataSource}
        columns={tableColumns}
        pagination={paginationOptions}
        locale={{ emptyText: '데이터가 없습니다.' }}
      />
      <div className="sort-button">
        <Button
          className="button"
          disabled={deletedAt ? true : false}
          onClick={handleOnDeleteComment}
        >
          댓글 삭제
        </Button>
        <Button
          type="primary"
          onClick={handleAllConfirmComment}
          disabled={dataSource.every(item => item.adminReadAt)}
        >
          신고 모두 확인
        </Button>
      </div>
    </StyledWrapper>
  )
}

export default TableDetailReportComment

const StyledWrapper = styled.div`
  .table {
    padding-top: 15px;
  }
  .sort-button {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 15px;
  }
  .button {
    margin-left: 10px;
  }
  .read-comment-column {
    text-decoration: line-through double;
    text-decoration-color: #404040;
  }
`
