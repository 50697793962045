export * from './base.query'

import { createBoardQueryBuilder } from './boards.query'
import { createPostQueryBuilder } from './posts.query'
import { createPostCollectionQueryBuilder } from './post-collections.query'
import { createUserQueryBuilder } from './users.query'
import { createDailyPostQueryBuilder } from './daily-posts.query'
import { createDailyCommentQueryBuilder } from './daily-comments.query'
import { createTagQueryBuilder } from './tag.query'
import { createBoardNavigationQueryBuilder } from './board-navigation'

export default {
  boards: createBoardQueryBuilder(),
  posts: createPostQueryBuilder(),
  'post-collections': createPostCollectionQueryBuilder(),
  users: createUserQueryBuilder(),
  'daily-posts': createDailyPostQueryBuilder(),
  'daily-comments': createDailyCommentQueryBuilder(),
  tags: createTagQueryBuilder(),
  'board-navigations': createBoardNavigationQueryBuilder(),
}
