import React, { FC } from 'react'
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  EditButton,
} from 'react-admin'

const BoardList: FC = props => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" label="ID" />
      <TextField source="name" label="제목" />
      <ReferenceField label="게시판" source="board.id" reference="boards">
        <TextField source="title" />
      </ReferenceField>
      <EditButton />
    </Datagrid>
  </List>
)

export default BoardList
