import React from 'react'
import DropdownUserManagement from 'app.feature/user/ban/component/dropdown/DropdownUserManagement'

const ColumnDeletedComment = () => [
  {
    title: '아이디',
    key: 'id',
    dataIndex: 'id',
    render: id => <DropdownUserManagement id={id} />,
  },
  {
    title: '닉네임',
    key: 'nickname',
    dataIndex: 'nickname',
  },
  {
    title: '내용',
    key: 'contentTextForAdmin',
    dataIndex: 'contentTextForAdmin',
    render: contentTextForAdmin => (
      <div style={{ width: '500px' }}>{contentTextForAdmin}</div>
    ),
  },
  {
    title: '게시판',
    key: 'boardTitle',
    dataIndex: 'boardTitle',
  },
  {
    title: '삭제일',
    key: 'deletedAt',
    dataIndex: 'deletedAt',
    sorter: true,
    sortDirections: ['ascend', 'descend', 'ascend'],
  },
]

export default ColumnDeletedComment
