import React, { FunctionComponent } from 'react';
import { MenuItemLink, useTranslate } from 'react-admin';
import {
  Dashboard as DashboardIcon,
  Group as GroupIcon,
  Description as DescriptionIcon,
  Class as ClassIcon,
  List as ListIcon,
  Settings as SettingsIcon,
  FindInPage as FindInPageIcon,
  Message as MessageIcon,
  ViewQuilt as ViewQuiltIcon,
  Label as LabelIcon,
  Directions as DirectionsIcon,
  ThumbUpOutlined as ThumbUpOutlinedIcon,
  Block as BlockIcon,
  Create as CreateIcon,
  Comment as CommentIcon,
  DeleteSweep as DeleteSweepIcon,
  DeleteForever as DeleteForeverIcon,
} from '@material-ui/icons';
import { makeStyles } from '@material-ui/core';
import { useSelector } from 'react-redux';
import SubMenu from './SubMenu';

const useStyles = makeStyles({
  hide: {
    display: 'none',
  },
});

interface ReactAdminState {
  admin: any;
}

interface MenuProps {
  dense: boolean;
}

const Menu: FunctionComponent<MenuProps> = ({ dense }) => {
  const open = useSelector<ReactAdminState, boolean>(
    (state) => state.admin.ui.sidebarOpen
  );

  const classes = useStyles();
  const translate = useTranslate();
  return (
    <div>
      <MenuItemLink
        to={`/`}
        leftIcon={<DashboardIcon />}
        primaryText={translate(`msg.dashboard`)}
        dense={dense}
      />
      <MenuItemLink
        to={`/boards`}
        leftIcon={<ClassIcon />}
        primaryText={translate(`resources.boards.name`)}
        dense={dense}
      />
      <SubMenu
        sidebarIsOpen={open}
        name="msg.post-admin"
        icon={<DescriptionIcon />}
        dense={dense}
      >
        <MenuItemLink
          to={`/posts`}
          leftIcon={<DescriptionIcon />}
          primaryText={translate(`resources.posts.name`)}
          dense={dense}
        />
        <MenuItemLink
          to={`/post-collections`}
          leftIcon={<ListIcon />}
          primaryText={translate(`resources.post-collections.name`)}
          dense={dense}
        />
        <MenuItemLink
          to={`/reported-post`}
          leftIcon={<CreateIcon />}
          primaryText={'신고 게시글'}
          dense={dense}
        />
        <MenuItemLink
          to={`/reported-comment`}
          leftIcon={<CommentIcon />}
          primaryText={'신고 댓글'}
          dense={dense}
        />
        <MenuItemLink
          to={`/deleted-post`}
          leftIcon={<DeleteSweepIcon />}
          primaryText={'삭제된 게시글'}
          dense={dense}
        />
        <MenuItemLink
          to={`/deleted-comment`}
          leftIcon={<DeleteForeverIcon />}
          primaryText={'삭제된 댓글'}
          dense={dense}
        />
        <MenuItemLink
          to={`/best-posts`}
          leftIcon={<ThumbUpOutlinedIcon />}
          primaryText={'명예의 전당 관리'}
          dense={dense}
        />
        <MenuItemLink
          to={'/tags'}
          leftIcon={<LabelIcon />}
          primaryText={translate(`resources.tags.name`)}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        sidebarIsOpen={open}
        name="유저"
        icon={<GroupIcon />}
        dense={dense}
      >
        <MenuItemLink
          to={`/users`}
          leftIcon={<GroupIcon />}
          primaryText={translate(`resources.users.name`)}
          dense={dense}
        />
        <MenuItemLink
          to={`/user-ban`}
          leftIcon={<BlockIcon />}
          primaryText={'활동 정지 유저'}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        sidebarIsOpen={open}
        name="성과관리"
        icon={<DescriptionIcon />}
        dense={dense}
      >
        <MenuItemLink
          to={`/daily-posts`}
          leftIcon={<GroupIcon />}
          primaryText={translate(`resources.daily-posts.name`)}
          dense={dense}
        />
        <MenuItemLink
          to={`/daily-comments`}
          leftIcon={<GroupIcon />}
          primaryText={translate(`resources.daily-comments.name`)}
          dense={dense}
        />
      </SubMenu>
      <SubMenu
        sidebarIsOpen={open}
        name="설정"
        icon={<SettingsIcon />}
        dense={dense}
      >
        <MenuItemLink
          to="/config/seo"
          leftIcon={<FindInPageIcon />}
          primaryText={translate('msg.config.seo')}
          dense={dense}
        />
        <MenuItemLink
          to="/config/message"
          leftIcon={<MessageIcon />}
          primaryText={translate('msg.config.message')}
          dense={dense}
        />
        <MenuItemLink
          to="/config/ui"
          leftIcon={<ViewQuiltIcon />}
          primaryText={translate('msg.config.ui')}
          dense={dense}
        />
      </SubMenu>
      <MenuItemLink
        to={`/board-navigations`}
        leftIcon={<DirectionsIcon />}
        primaryText={translate(`resources.board-navigations.name`)}
        dense={dense}
      />
    </div>
  );
};

export default Menu;
