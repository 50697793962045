import React from 'react'
import styled from 'styled-components'
import { Button, Form, Input, Select } from 'antd'
import { checkDateEmptyValue } from '../../module/checkDateEmptyValue'
import DateRangePicker from 'app.component/Form/DateRangePicker'
const { Item } = Form
const { Option } = Select

type TProps = {
  type: 'title' | 'contentText'
  onFormSubmit: (values: any) => void
}

const FormDateRangePickerWithSelectInput: React.FC<TProps> = ({
  type,
  onFormSubmit,
}) => {
  const [form] = Form.useForm()

  const handleSubmit = ({
    dateRangePicker,
    selectCondition,
    inputCondition = null,
  }) => {
    const [startDate, endDate] = checkDateEmptyValue(dateRangePicker)
    onFormSubmit({
      startDate,
      endDate,
      selectCondition,
      inputCondition,
    })
  }

  return (
    <StyledWrapper>
      <Form form={form} className="container" onFinish={handleSubmit}>
        <div className="form-top">
          <Item noStyle name="dateRangePicker">
            <DateRangePicker width={250} value />
          </Item>
        </div>
        <div className="form-bottom">
          <Item
            className="form-item"
            name="selectCondition"
            initialValue={type}
          >
            <Select className="form-select">
              <Option value={type}>{type === 'title' ? '제목' : '내용'}</Option>
              <Option value="userID">아이디</Option>
              <Option value="nickname">닉네임</Option>
            </Select>
          </Item>
          <Item className="form-input" name="inputCondition">
            <Input placeholder="검색어를 입력하세요." />
          </Item>
          <Button type="primary" htmlType="submit">
            검색
          </Button>
        </div>
      </Form>
    </StyledWrapper>
  )
}

export default FormDateRangePickerWithSelectInput

const StyledWrapper = styled.div`
  .container {
    display: flex;
    flex-direction: column;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    padding: 10px;
    margin: 15px 0px;
  }

  .form-top {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }

  .form-bottom {
    display: flex;
  }

  .form-item {
    width: 80px;
    margin-right: 5px;
  }

  .form-input {
    width: 500px;
    margin-right: 5px;
  }

  .form-item,
  .form-input {
    margin-bottom: 0px;
  }
`
