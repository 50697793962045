import { endOfDay, format, startOfDay } from 'date-fns'

export const checkDateEmptyValue = date => {
  if (date === undefined) return [null, null]

  const startDate = startOfDay(new Date(date?.[0]?._d))
  const endDate = endOfDay(new Date(date?.[1]?._d))

  return [startDate, endDate]
}
