import gql from 'graphql-tag'
import {
  PostGetListDocument,
  PostGetDocument,
  PostGetManyDocument,
  PostUpdateDocument,
  PostUpdateManyDocument,
  PostDeleteDocument,
  PostDeleteManyDocument,
  PostFragmentFragment,
} from 'generated/graphql'
import { QueryBuilder } from './base.query'

function parseRecord(record: PostFragmentFragment) {
  const { postCollections, tags, ...other } = record
  return {
    ...other,
    postCollectionIDs: postCollections.map(postCollection => postCollection.id),
    tagIDs: tags.map(tag => tag.id),
  }
}

export function createPostQueryBuilder(): QueryBuilder {
  return {
    getList: {
      query: PostGetListDocument,
    },
    getOne: {
      query: PostGetDocument,
    },
    getMany: {
      query: PostGetManyDocument,
    },
    getManyReference: {
      query: PostGetListDocument,
    },
    update: {
      query: PostUpdateDocument,
    },
    updateMany: {
      query: PostUpdateManyDocument,
    },
    delete: {
      query: PostDeleteDocument,
    },
    deleteMany: {
      query: PostDeleteManyDocument,
    },
    parseRecord,
  }
}

gql`
  fragment PostFragment on Post {
    id
    title
    username
    globalNotice
    board {
      id
      title
    }
    postCollections {
      id
    }
    updatedAt
    tags {
      id
    }
  }
`

gql`
  query PostGetList(
    $filterBy: PostFiltersInput
    $orderBy: PostOrder
    $pagination: Pagination
  ) {
    data: posts(
      filterBy: $filterBy
      orderBy: $orderBy
      pagination: $pagination
    ) {
      data: nodes {
        ...PostFragment
      }
      total: totalCount
    }
  }
`

gql`
  query PostGet($id: ID!) {
    data: post(id: $id) {
      ...PostFragment
    }
  }
`

gql`
  query PostGetMany($ids: [ID!]!) {
    data: postsByID(ids: $ids) {
      ...PostFragment
    }
  }
`

gql`
  mutation PostUpdate($id: ID!, $input: PostUpdateInput!) {
    data: postUpdate(id: $id, input: $input) {
      data: post {
        ...PostFragment
      }
    }
  }
`

gql`
  mutation PostUpdateMany($ids: [ID!]!, $input: PostUpdateManyInput!) {
    data: postUpdateMany(ids: $ids, input: $input) {
      data: posts {
        ...PostFragment
      }
    }
  }
`

gql`
  mutation PostDelete($id: ID!) {
    data: postDelete(id: $id) {
      data: post {
        ...PostFragment
      }
    }
  }
`

gql`
  mutation PostDeleteMany($ids: [ID!]!) {
    data: postDeleteMany(ids: $ids) {
      data: posts {
        ...PostFragment
      }
    }
  }
`
