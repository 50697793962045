import React from 'react'
import { Table } from 'antd'
import DropdownUserManagement from '../../dropdown/DropdownUserManagement'

export const ColumnUserBanTable = () => {
  return [
    {
      title: '아이디',
      dataIndex: 'id',
      key: 'id',
      render: id => <DropdownUserManagement id={id} />,
    },
    {
      title: '닉네임',
      dataIndex: 'nickname',
      key: 'nickname',
    },
    {
      title: '사유',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: '처리일',
      dataIndex: 'startAt',
      key: 'startAt',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: '종료일',
      dataIndex: 'endAt',
      key: 'endAt',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: '해제일',
      dataIndex: 'deletedAt',
      key: 'deletedAt',
    },
    Table.SELECTION_COLUMN,
  ]
}
