import React, { FC } from 'react'
import {
  TextField,
  Show,
  TabbedShowLayout,
  Tab,
  ReferenceManyField,
  Pagination,
  Datagrid,
  DateField,
  EditButton,
} from 'react-admin'

const UserShow: FC = props => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="msg.summary">
        <TextField source="id" label="msg.id" />
        <TextField source="username" label="msg.username" />
        <TextField source="nickname" label="msg.nickname" />
      </Tab>
      <Tab label="resources.posts.name">
        <ReferenceManyField
          addLabel={false}
          label="resources.posts.name"
          reference="posts"
          target="userID"
          pagination={<Pagination />}
        >
          <Datagrid>
            <TextField source="id" label="msg.id" />
            <TextField source="board.title" label="게시판" sortable={false} />
            <TextField source="title" label="제목" sortable={false} />
            <DateField source="updatedAt" label="최근 수정일" showTime />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>
  </Show>
)

export default UserShow
