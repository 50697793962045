import moment from 'moment'

export const YYYY_MM = 'YYYY-MM'
export const YYYY_MM_DD = 'YYYY-MM-DD'
export const YYYYMMDDHHmmss = 'YYYYMMDDHHmmss'
export const YMD_HHmmss = 'YYYY-MM-DD HH:mm:ss'
export const DATERANGE_DATE_FORMAT = 'yyyy-MM-dd'
export const DATE_FORMAT = 'YYYY-MM-DD'
export const DATE_MONTH_FORMAT = 'YYYY-MM'
export const DATE_TIME_FORMAT_SLUSH = 'YYYY-MM-DD / HH:mm:ss'
export const DATE_HOUR_MINUTE_FORMAT = 'YYYY-MM-DD HH:mm'

export const Dates = {
  today: moment(),
  yesterday: moment().subtract(1, 'day'),
  oneWeekAgo: moment().subtract(1, 'week'),
  oneMonthAgo: moment().subtract(1, 'month'),
  startOfMonth: moment().startOf('month'),
  endOfMonth: moment().endOf('month'),

  YYYY_MM_DD: 'YYYY-MM-DD',
}

export const defStartDate = moment(Dates.yesterday)
  .add(-14, 'day')
  .format(YYYY_MM_DD)

export const defEndDate = moment().format(YYYY_MM_DD)

export const getFormattedDate = value => moment(value).format(YYYY_MM_DD)

export const getMonthStartDate = (YYYY, MM) =>
  moment(new Date(Number(YYYY), Number(MM) - 1, 1).toLocaleDateString()).format(
    YYYY_MM_DD,
  )

export const getMonthLastDate = (YYYY, MM) =>
  moment(new Date(Number(YYYY), Number(MM), 0).toLocaleDateString()).format(
    YYYY_MM_DD,
  )

export const getWeekLaterDate = inputDate =>
  moment(inputDate)
    .add(6, 'day')
    .format(YYYY_MM_DD)
