import React from 'react'
import { Layout as ReactAdminLayout } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from './AppBar'
import Menu from './Menu'

const useStyles = makeStyles({
  children: {
    padding: '10px',
  },
})

const Layout = props => {
  const { children } = props
  const classes = useStyles()

  return (
    <ReactAdminLayout {...props} appBar={AppBar} menu={Menu}>
      <div className={classes.children}>{children}</div>
    </ReactAdminLayout>
  )
}

export default Layout
