import React, { useState } from 'react'
import { Radio, Space, Input, Form } from 'antd'
import { inputValidationCondition } from 'app.feature/board/report/module/inputValidationCondition'
import { banUserDescription } from 'app.feature/board/report/module/banUserDescription'
const { Group } = Radio
const { Item } = Form

type TProps = {
  onChange?: (value: string) => void
}

type Description =
  | '불법광고 및 스팸 활동'
  | '광고, 도배, 욕설, 비방 등 바람직하지 않은 활동'
  | '기타'

const RadioBanUserDescription: React.FC<TProps> = ({ onChange }) => {
  const [value, setValue] = useState<Description>(
    banUserDescription['illegalAdvertising'],
  )
  const [disabledInput, setDisabledInput] = useState(true)

  const handleOnChangeRadioSelect = (event: any) => {
    const target = event.target.value
    setValue(target)
    setDisabledInput(target !== banUserDescription['etc'] ? true : false)
    onChange && onChange(target)
  }

  return (
    <Group
      className="radio"
      value={value}
      onChange={handleOnChangeRadioSelect}
      defaultValue={banUserDescription['illegalAdvertising']}
    >
      <Space direction="vertical">
        <Radio value={banUserDescription['illegalAdvertising']}>
          {banUserDescription['illegalAdvertising']}
        </Radio>
        <Radio value={banUserDescription['inappropriateBehavior']}>
          {banUserDescription['inappropriateBehavior']}
        </Radio>
        <Radio value={banUserDescription['etc']}>
          {value === banUserDescription['etc'] ? (
            <Item name="banDesciptionInput" rules={inputValidationCondition}>
              <Input
                placeholder="기타 (한글 25자 이내로 작성해주세요)"
                disabled={disabledInput}
                style={{ width: 300 }}
                maxLength={25}
                showCount={true}
                allowClear={true}
              />
            </Item>
          ) : (
            <span>기타</span>
          )}
        </Radio>
      </Space>
    </Group>
  )
}

export default RadioBanUserDescription
