const convertPaginiationQueryString = (paginationString: string) => {
  if (!paginationString) return false;
  return paginationString
    .substring(1)
    .split('&')
    .reduce((result, next) => {
      let pair = next.split('=');
      result[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
      return result;
    }, {});
};

export default convertPaginiationQueryString;
