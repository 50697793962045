import React, { FC } from 'react'
import {
  TextField,
  DateField,
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  SelectArrayInput,
} from 'react-admin'

interface PostTitleProps {
  record?: any
}

const PostTitle: FC<PostTitleProps> = ({ record }) => {
  return <span>Post {record ? `"${record.title}"` : ''}</span>
}

function filterToQuery(searchText: string) {
  return { query: searchText }
}

const required = (message = 'Required') => (value: any) =>
  value ? undefined : message

const PostEdit: FC = props => {
  return (
    <Edit title={<PostTitle />} {...props}>
      <SimpleForm>
        <TextField source="id" label="ID" />
        <TextField source="board.title" label="게시판" />
        <TextInput source="title" label="제목" validate={required()} />
        <BooleanInput source="globalNotice" label="전체 공지 여부" />
        <DateField source="updatedAt" label="최근 수정일" showTime />
        <ReferenceArrayInput
          source="postCollectionIDs"
          reference="post-collections"
          filterToQuery={filterToQuery}
        >
          <AutocompleteArrayInput optionText="title" />
        </ReferenceArrayInput>
        <ReferenceArrayInput
          source="tagIDs"
          reference="tags"
        >
          <SelectArrayInput optionText="name" />
        </ReferenceArrayInput>
      </SimpleForm>
    </Edit>
  )
}

export default PostEdit
