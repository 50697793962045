import React from 'react';
import 'moment/locale/ko';
import localeKR from 'antd/es/date-picker/locale/ko_KR';
import { DatePicker as AntdDatePicker } from 'antd';
import { Dates } from '../../app.module/constant/dates';
import moment from 'moment';
const { RangePicker } = AntdDatePicker;

const DateRangePicker = (props) => {
  return (
    <RangePicker
      style={{ width: props.width || 150 }}
      locale={localeKR}
      {...props}
      ranges={{
        어제: [Dates.yesterday, Dates.yesterday],
        '지난 7일': [
          Dates.oneWeekAgo,
          props.isToday ? Dates.today : Dates.yesterday,
        ],
        '지난 30일': [
          Dates.oneMonthAgo,
          props.isToday ? Dates.today : Dates.yesterday,
        ],
        '이번달 현재까지': [Dates.startOfMonth, Dates.today],
        지난달: [
          moment(Dates.oneMonthAgo).startOf('month'),
          moment(Dates.oneMonthAgo).endOf('month'),
        ],
      }}
    />
  );
};

export default DateRangePicker;
