import React, { FC } from 'react'
import { TextField, Show, SimpleShowLayout } from 'react-admin'

const BoardShow: FC = props => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="title" />
    </SimpleShowLayout>
  </Show>
)

export default BoardShow
