import { Form, Input, InputProps } from 'antd'
import React, { FC } from 'react'
import styled from 'styled-components'
import requirementInputRules from '../modules/utils/inputRules'

interface FormInputsProps extends InputProps {
  label?: string
  name: string
  initialValue?: any
}

const FormInputs: FC<FormInputsProps> = ({
  label = '',
  initialValue = '',
  name,
  ...restProps
}) => {
  return (
    <StyledWrapper>
      <div className="input-label">{label}</div>
      <Form.Item
        initialValue={initialValue}
        name={name}
        rules={requirementInputRules}
      >
        <Input style={{ width: 200 }} type="number" {...restProps} />
      </Form.Item>
    </StyledWrapper>
  )
}

export default FormInputs

const StyledWrapper = styled.div`
  margin-right: 10px;
  .input-label {
    margin-bottom: 5px;
  }
`
