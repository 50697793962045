import React, { FC } from 'react'
import { Create, SimpleForm, TextInput } from 'react-admin'

const TagCreate: FC = props => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="name" />
      </SimpleForm>
    </Create>
  )
}

export default TagCreate
