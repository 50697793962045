import {
  CommentTrashOrderField,
  TrashedCommentsQueryVariables,
  OrderDirection,
} from 'generated/graphql'

const generateVariableDeletedComment: () =>
  | TrashedCommentsQueryVariables
  | undefined = () => {
  return {
    orderBy: {
      field: CommentTrashOrderField['DELETED_AT'],
      direction: OrderDirection['DESC'],
    },
    pagination: {
      page: 1,
      pageSize: 10,
    },
  }
}

export default generateVariableDeletedComment
