import React from 'react';

const ColumnDetailDeletedPost = () => [
  {
    title: '신고 일시',
    dataIndex: 'createdAt',
    key: 'createdAt',
  },
  {
    title: '신고자',
    dataIndex: ['reporterNickname', 'reporterId'],
    key: 'reporterId',
    render: (_, record) => (
      <span>
        닉네임 : {record['reporterNickname']} / ID : {record['reporterId']}
      </span>
    ),
  },
  {
    title: '신고사유',
    dataIndex: 'description',
    key: 'description',
  },
];

export default ColumnDetailDeletedPost;
