import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string,
  String: string,
  Boolean: boolean,
  Int: number,
  Float: number,
  Date: any,
};

export type AcceptMentorCommentPayload = {
   __typename?: 'AcceptMentorCommentPayload',
  comment: Comment,
};

export type Ad = {
   __typename?: 'Ad',
  campaignID?: Maybe<Scalars['ID']>,
  campaignLandingURL?: Maybe<Scalars['String']>,
  imageURL?: Maybe<Scalars['String']>,
  placementID: Scalars['ID'],
  isPlacementActive?: Maybe<Scalars['Boolean']>,
  placementWidth: Scalars['Int'],
  placementHeight: Scalars['Int'],
  defaultImageURL?: Maybe<Scalars['String']>,
  defaultLandingURL?: Maybe<Scalars['String']>,
};

export type AddBookmarkPayload = {
   __typename?: 'AddBookmarkPayload',
  menu?: Maybe<Menu>,
  bookmarkable?: Maybe<Bookmarkable>,
};

export type Attachment = {
   __typename?: 'Attachment',
  id: Scalars['ID'],
  post?: Maybe<Post>,
  comment?: Maybe<Comment>,
  url: Scalars['String'],
  name: Scalars['String'],
  size: Scalars['Int'],
  type: Scalars['String'],
};

export type AttachmentCompleteUploadPayload = {
   __typename?: 'AttachmentCompleteUploadPayload',
  attachment?: Maybe<Attachment>,
};

export type AttachmentDeletePayload = {
   __typename?: 'AttachmentDeletePayload',
  attachment?: Maybe<Attachment>,
};

export type AttachmentPrepareUploadPayload = {
   __typename?: 'AttachmentPrepareUploadPayload',
  attachment: Attachment,
  signedURL: Scalars['String'],
};

export type AuthenticateEmailPayload = {
   __typename?: 'AuthenticateEmailPayload',
  authenticationToken: Scalars['String'],
};

export type AuthenticateWithDocumentsInput = {
  mentorDocumentIds: Array<Scalars['ID']>,
  organizationName: Scalars['String'],
  dutyName?: Maybe<Scalars['String']>,
  dutyId?: Maybe<Scalars['ID']>,
  schoolName: Scalars['String'],
  phoneNumber?: Maybe<Scalars['String']>,
};

export type AuthenticateWithDocumentsPayloadDto = {
   __typename?: 'AuthenticateWithDocumentsPayloadDto',
  user: User,
};

export type AuthenticateWithEmailInput = {
  authenticationToken: Scalars['String'],
  emailCode: Scalars['String'],
  organizationName: Scalars['String'],
  dutyName?: Maybe<Scalars['String']>,
  dutyId?: Maybe<Scalars['ID']>,
  schoolName: Scalars['String'],
  phoneNumber?: Maybe<Scalars['String']>,
};

export type AuthenticateWithEmailPayload = {
   __typename?: 'AuthenticateWithEmailPayload',
  user: User,
};

export enum Bank {
  WOORI_BANK = 'WOORI_BANK',
  KB_BANK = 'KB_BANK',
  NH_BANK = 'NH_BANK',
  SC_BANK = 'SC_BANK',
  SHINHAN_BANK = 'SHINHAN_BANK',
  HANA_BANK = 'HANA_BANK',
  IBK_BANK = 'IBK_BANK',
  SH_BANK = 'SH_BANK',
  CITI_BANK = 'CITI_BANK',
  DAEGU_BANK = 'DAEGU_BANK',
  BUSAN_BANK = 'BUSAN_BANK',
  GWANGJU_BANK = 'GWANGJU_BANK',
  JEJU_BANK = 'JEJU_BANK',
  JEONBUK_BANK = 'JEONBUK_BANK',
  GYEONGNAM_BANK = 'GYEONGNAM_BANK',
  POST_OFFICE_BANK = 'POST_OFFICE_BANK',
  SAEMAEUL_GUMGO = 'SAEMAEUL_GUMGO',
  SHINHYUP = 'SHINHYUP',
  SJ = 'SJ',
  KDB_BANK = 'KDB_BANK',
  HMC = 'HMC',
  K_BANK = 'K_BANK',
  KAKAO_BANK = 'KAKAO_BANK',
  TOSS_BANK = 'TOSS_BANK'
}

export type BankInformation = {
   __typename?: 'BankInformation',
  id: Scalars['ID'],
  realName: Scalars['String'],
  phoneNumber: Scalars['String'],
  bank: Bank,
  bankAccountNumber: Scalars['String'],
  user?: Maybe<User>,
};

export type BanLog = {
   __typename?: 'BanLog',
  id: Scalars['ID'],
  user?: Maybe<User>,
  description?: Maybe<Scalars['String']>,
  startAt?: Maybe<Scalars['Date']>,
  endAt?: Maybe<Scalars['Date']>,
  deletedAt?: Maybe<Scalars['Date']>,
  createdAt?: Maybe<Scalars['Date']>,
};

export type BanLogConnection = {
   __typename?: 'BanLogConnection',
  nodes?: Maybe<Array<BanLog>>,
  totalCount: Scalars['Int'],
};

export type BanLogInput = {
  userID: Scalars['ID'],
  description?: Maybe<Scalars['String']>,
  startAt?: Maybe<Scalars['Date']>,
  endAt?: Maybe<Scalars['Date']>,
};

export type BanLogsFilter = {
  userID?: Maybe<Scalars['ID']>,
  nickname?: Maybe<Scalars['String']>,
};

export type BanLogsOrder = {
  field: BanLogsOrderField,
  direction: OrderDirection,
};

export enum BanLogsOrderField {
  ID = 'ID',
  START_AT = 'START_AT',
  END_AT = 'END_AT',
  CREATED_AT = 'CREATED_AT'
}

export type BestPostListInput = {
  startDate?: Maybe<Scalars['String']>,
  endDate?: Maybe<Scalars['String']>,
  minimumViewCount?: Maybe<Scalars['Int']>,
  minimumLikeCount?: Maybe<Scalars['Int']>,
  minimumCommentCount?: Maybe<Scalars['Int']>,
  order?: Maybe<Scalars['String']>,
  type?: Maybe<BestPostListType>,
  pagination?: Maybe<Pagination>,
};

export type BestPostListPayload = {
   __typename?: 'BestPostListPayload',
  bestPosts?: Maybe<Array<Post>>,
};

export enum BestPostListType {
  RECENT = 'RECENT',
  LAST_WEEK = 'LAST_WEEK',
  LAST_MONTH = 'LAST_MONTH',
  REPLY_COUNT = 'REPLY_COUNT',
  VOTE_COUNT = 'VOTE_COUNT'
}

export type Board = {
   __typename?: 'Board',
  id: Scalars['ID'],
  menteeAcceptableCommentCount?: Maybe<Scalars['Int']>,
  isMentorBoard: Scalars['Boolean'],
  slug: Scalars['String'],
  title: Scalars['String'],
  subTitle?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  module?: Maybe<BoardType>,
  posts?: Maybe<PostConnection>,
  searchPosts: PostConnection,
  notices: Array<Post>,
  globalNotices: Array<Post>,
  boardNotices: Array<Post>,
  hasPermission?: Maybe<Scalars['Boolean']>,
  menu?: Maybe<Menu>,
  placeholder?: Maybe<Scalars['String']>,
  headerText?: Maybe<Scalars['String']>,
  footerText?: Maybe<Scalars['String']>,
  anonymity?: Maybe<BoardAnonymity>,
  orderField?: Maybe<BoardPostOrderField>,
  orderDirection?: Maybe<BoardPostOrderDirection>,
  noticeHidden?: Maybe<Scalars['Boolean']>,
  secretPostWritable?: Maybe<Scalars['Boolean']>,
  permissionGroups: Array<Group>,
};


export type BoardPostsArgs = {
  orderBy?: Maybe<PostOrder>,
  pagination?: Maybe<Pagination>,
  filterBy?: Maybe<PostFiltersInput>
};


export type BoardSearchPostsArgs = {
  filterBy?: Maybe<SearchPostFromBoardFilters>,
  orderBy?: Maybe<PostOrder>,
  pagination?: Maybe<Pagination>
};


export type BoardHasPermissionArgs = {
  type?: Maybe<PermissionType>
};


export type BoardPermissionGroupsArgs = {
  type: BoardPermissionType
};

export enum BoardAnonymity {
  ALL = 'ALL',
  OPTIONAL = 'OPTIONAL',
  NONE = 'NONE'
}

export type BoardConnection = {
   __typename?: 'BoardConnection',
  nodes: Array<Board>,
  totalCount: Scalars['Int'],
};

export type BoardCreateInput = {
  slug: Scalars['String'],
  title: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  headerText?: Maybe<Scalars['String']>,
  footerText?: Maybe<Scalars['String']>,
  content?: Maybe<Scalars['String']>,
  mobileContent?: Maybe<Scalars['String']>,
  isMentorBoard?: Maybe<Scalars['Boolean']>,
};

export type BoardCreatePayload = {
   __typename?: 'BoardCreatePayload',
  board: Board,
};

export type BoardDeletePayload = {
   __typename?: 'BoardDeletePayload',
  board: Board,
};

export type BoardFiltersInput = {
  q?: Maybe<Scalars['String']>,
  permissionType?: Maybe<PermissionType>,
};

export type BoardMenu = IMenu & {
   __typename?: 'BoardMenu',
  board?: Maybe<Board>,
  children: Array<IMenu>,
  description?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  listOrder: Scalars['String'],
  parent?: Maybe<IMenu>,
  permissionViewMenuGroups: Array<Group>,
  sitemap?: Maybe<Sitemap>,
  title?: Maybe<Scalars['String']>,
};

export type BoardMenuCreateInput = {
  boardSlug: Scalars['String'],
  description?: Maybe<Scalars['String']>,
  parentID?: Maybe<Scalars['ID']>,
  sitemapID?: Maybe<Scalars['ID']>,
  title: Scalars['String'],
};

export type BoardMenuCreatePayload = {
   __typename?: 'BoardMenuCreatePayload',
  menu: BoardMenu,
};

export type BoardMenuUpdateInput = {
  boardSlug?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  parentID?: Maybe<Scalars['ID']>,
  sitemapID?: Maybe<Scalars['ID']>,
  title?: Maybe<Scalars['String']>,
  permissionViewMenuGroupIDs?: Maybe<Array<Scalars['ID']>>,
};

export type BoardMenuUpdatePayload = {
   __typename?: 'BoardMenuUpdatePayload',
  menu?: Maybe<BoardMenu>,
};

export type BoardNavigation = {
   __typename?: 'BoardNavigation',
  id: Scalars['ID'],
  name: Scalars['String'],
  board?: Maybe<Board>,
};

export type BoardNavigationConnection = {
   __typename?: 'BoardNavigationConnection',
  nodes: Array<BoardNavigation>,
  totalCount: Scalars['Int'],
};

export type BoardNavigationFiltersInput = {
  name?: Maybe<Scalars['String']>,
};

export type BoardNavigationUpdateInput = {
  board: BoardUpdateInput,
};

export type BoardNavigationUpdatePayload = {
   __typename?: 'BoardNavigationUpdatePayload',
  boardNavigation?: Maybe<BoardNavigation>,
};

export enum BoardPermissionType {
  LIST = 'LIST',
  VIEW = 'VIEW',
  ACCESS = 'ACCESS',
  MANAGER = 'MANAGER',
  WRITE_COMMENT = 'WRITE_COMMENT',
  WRITE_DOCUMENT = 'WRITE_DOCUMENT',
  CONSULTATION_READ = 'CONSULTATION_READ'
}

export enum BoardPostOrderDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export enum BoardPostOrderField {
  CREATED_AT = 'CREATED_AT'
}

export enum BoardTab {
  NOTICE = 'NOTICE',
  BEST = 'BEST',
  COMMUNITY = 'COMMUNITY'
}

export enum BoardType {
  BOARD = 'BOARD',
  PAGE = 'PAGE'
}

export type BoardUpdateInput = {
  id: Scalars['ID'],
  slug?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  headerText?: Maybe<Scalars['String']>,
  footerText?: Maybe<Scalars['String']>,
  content?: Maybe<Scalars['String']>,
  mobileContent?: Maybe<Scalars['String']>,
  anonymity?: Maybe<BoardAnonymity>,
  orderField?: Maybe<BoardPostOrderField>,
  orderDirection?: Maybe<BoardPostOrderDirection>,
  noticeHidden?: Maybe<Scalars['Boolean']>,
  secretPostWritable?: Maybe<Scalars['Boolean']>,
  permissions?: Maybe<Array<PermissionInput>>,
};

export type BoardUpdatePayload = {
   __typename?: 'BoardUpdatePayload',
  board: Board,
};

export type Bookmarkable = {
  id: Scalars['ID'],
  viewerHasBookmarked: Scalars['Boolean'],
};

export type BookmarkableConnection = {
   __typename?: 'BookmarkableConnection',
  nodes: Array<Bookmarkable>,
  totalCount: Scalars['Int'],
};

export type BookmarkOrder = {
  field: BookmarkOrderField,
  direction: OrderDirection,
};

export enum BookmarkOrderField {
  ID = 'ID',
  CREATED_AT = 'CREATED_AT'
}

export enum CacheControlScope {
  PUBLIC = 'PUBLIC',
  PRIVATE = 'PRIVATE'
}

export type CollectionRequirement = {
   __typename?: 'CollectionRequirement',
  id: Scalars['ID'],
  view: Scalars['Int'],
  scrap: Scalars['Int'],
  like: Scalars['Int'],
  comment: Scalars['Int'],
  slug: Scalars['String'],
  createdAt?: Maybe<Scalars['Date']>,
};

export type CollectionRequirementUpdateInput = {
  view?: Maybe<Scalars['Int']>,
  scrap?: Maybe<Scalars['Int']>,
  like?: Maybe<Scalars['Int']>,
  comment?: Maybe<Scalars['Int']>,
};

export type CollectionRequirementUpdatePayload = {
   __typename?: 'CollectionRequirementUpdatePayload',
  collectionRequirement?: Maybe<CollectionRequirement>,
};

export type Comment = {
   __typename?: 'Comment',
  id: Scalars['ID'],
  organizationName?: Maybe<Scalars['String']>,
  isApprovedMentorComment?: Maybe<Scalars['Boolean']>,
  menteeAcceptedAt?: Maybe<Scalars['Date']>,
  post?: Maybe<Post>,
  dutyName?: Maybe<Scalars['String']>,
  duty?: Maybe<MentorDuty>,
  matchOrganizationName: Scalars['Boolean'],
  matchDutyName: Scalars['Boolean'],
  matchSchoolName: Scalars['Boolean'],
  isMentorComment: Scalars['Boolean'],
  viewerCanAcceptComment: Scalars['Boolean'],
  rewardAmount: Scalars['Int'],
  content?: Maybe<Scalars['String']>,
  contentText?: Maybe<Scalars['String']>,
  fullContentText?: Maybe<Scalars['String']>,
  excerpt?: Maybe<Scalars['String']>,
  likes: Scalars['Int'],
  dislikes: Scalars['Int'],
  postID: Scalars['Int'],
  boardID?: Maybe<Scalars['Int']>,
  userID?: Maybe<Scalars['Int']>,
  username?: Maybe<Scalars['String']>,
  isSecret: Scalars['Boolean'],
  ipaddress?: Maybe<Scalars['String']>,
  createdAt: Scalars['Date'],
  updatedAt: Scalars['Date'],
  deletedAt?: Maybe<Scalars['Date']>,
  childComment: Array<Comment>,
  childCommentCount: Scalars['Int'],
  childComments?: Maybe<CommentConnection>,
  isAuthor: Scalars['Boolean'],
  isChild: Scalars['Boolean'],
  isPostAuthor: Scalars['Boolean'],
  nickname: Scalars['String'],
  author?: Maybe<User>,
  board?: Maybe<Board>,
  attachments: Array<Attachment>,
  myRating?: Maybe<RatingType>,
  isAnonymous: Scalars['Boolean'],
  viewerHasMuteAuthor: Scalars['Boolean'],
  reportCount?: Maybe<Scalars['Int']>,
  lastReportedAt?: Maybe<Scalars['Date']>,
  adminReportReadAt?: Maybe<Scalars['Date']>,
  hasUnreadReport?: Maybe<Scalars['Boolean']>,
  reportDescription?: Maybe<Scalars['String']>,
  reportLogs?: Maybe<CommentAbuseReportLogConnection>,
  reportCountByDescription?: Maybe<Array<CommetAbuseReportLogCountByDescription>>,
  contentTextForAdmin?: Maybe<Scalars['String']>,
  originContentText?: Maybe<Scalars['String']>,
  mentionedComment?: Maybe<Comment>,
  anonymousIndex?: Maybe<Scalars['Int']>,
};


export type CommentContentTextArgs = {
  pruneLength?: Maybe<Scalars['Int']>
};


export type CommentExcerptArgs = {
  pruneLength?: Maybe<Scalars['Int']>
};


export type CommentReportLogsArgs = {
  filterBy?: Maybe<CommentAbuseReportLogFilters>,
  orderBy?: Maybe<CommentAbuseReportLogOrder>,
  pagination?: Maybe<Pagination>
};

export type CommentAbuseReportLog = {
   __typename?: 'CommentAbuseReportLog',
  id?: Maybe<Scalars['ID']>,
  comment?: Maybe<Comment>,
  reporter?: Maybe<User>,
  ipaddress?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  adminReadAt?: Maybe<Scalars['Date']>,
  createdAt?: Maybe<Scalars['Date']>,
};

export type CommentAbuseReportLogBulkUpdatePayload = {
   __typename?: 'CommentAbuseReportLogBulkUpdatePayload',
  updatedCommentAbuseReportLogs: Scalars['Int'],
};

export type CommentAbuseReportLogConnection = {
   __typename?: 'CommentAbuseReportLogConnection',
  nodes?: Maybe<Array<CommentAbuseReportLog>>,
  totalCount: Scalars['Int'],
};

export type CommentAbuseReportLogFilters = {
  commentID?: Maybe<Scalars['ID']>,
};

export type CommentAbuseReportLogOrder = {
  field: CommentAbuseReportLogOrderField,
  direction: OrderDirection,
};

export enum CommentAbuseReportLogOrderField {
  CREATED_AT = 'CREATED_AT',
  ID = 'ID',
  ADMIN_READ_AT = 'ADMIN_READ_AT'
}

export type CommentAbuseReportLogUpdatePayload = {
   __typename?: 'CommentAbuseReportLogUpdatePayload',
  commentAbuseReportLog?: Maybe<CommentAbuseReportLog>,
};

export type CommentConnection = {
   __typename?: 'CommentConnection',
  nodes: Array<Comment>,
  totalCount: Scalars['Int'],
};

export type CommentCreatePayload = {
   __typename?: 'CommentCreatePayload',
  comment?: Maybe<Comment>,
  post?: Maybe<Post>,
  parentComment?: Maybe<Comment>,
};

export type CommentDeletePayload = {
   __typename?: 'CommentDeletePayload',
  deletedCommentID?: Maybe<Scalars['String']>,
  post?: Maybe<Post>,
  parentComment?: Maybe<Comment>,
};

export type CommentDislikePayload = {
   __typename?: 'CommentDislikePayload',
  comment?: Maybe<Comment>,
};

export type CommentFiltersInput = {
  userID?: Maybe<Scalars['ID']>,
  parentID?: Maybe<Scalars['ID']>,
  hasChildren?: Maybe<Scalars['Boolean']>,
  page?: Maybe<Scalars['Int']>,
};

export type CommentInput = {
  content: Scalars['String'],
  contentText?: Maybe<Scalars['String']>,
  attachmentIDs?: Maybe<Array<Scalars['ID']>>,
  isSecret?: Maybe<Scalars['Boolean']>,
  mentionedCommentID?: Maybe<Scalars['ID']>,
  isMentor?: Maybe<Scalars['Boolean']>,
};

export type CommentLikePayload = {
   __typename?: 'CommentLikePayload',
  comment?: Maybe<Comment>,
};

export type CommentOrder = {
  field: CommentOrderField,
  direction: OrderDirection,
};

export enum CommentOrderField {
  ID = 'ID',
  LIKES = 'LIKES',
  CREATED_AT = 'CREATED_AT'
}

export type CommentRatePayload = {
   __typename?: 'CommentRatePayload',
  comment?: Maybe<Comment>,
};

export type CommentRemoveLikePayload = {
   __typename?: 'CommentRemoveLikePayload',
  comment?: Maybe<Comment>,
};

export type CommentReportConnection = {
   __typename?: 'CommentReportConnection',
  nodes?: Maybe<Array<Comment>>,
  totalCount: Scalars['Int'],
};

export type CommentReportFilters = {
  userID?: Maybe<Scalars['ID']>,
  nickname?: Maybe<Scalars['String']>,
  contentText?: Maybe<Scalars['String']>,
  startDate?: Maybe<Scalars['Date']>,
  endDate?: Maybe<Scalars['Date']>,
  reportReadStatus?: Maybe<ReportReadStatus>,
};

export type CommentReportOrder = {
  field: CommentReportOrderField,
  direction: OrderDirection,
};

export enum CommentReportOrderField {
  LAST_REPORTED_AT = 'LAST_REPORTED_AT',
  ID = 'ID',
  DELETED_AT = 'DELETED_AT',
  REPORT_COUNT = 'REPORT_COUNT'
}

export type CommentTrashFilters = {
  userID?: Maybe<Scalars['ID']>,
  nickname?: Maybe<Scalars['String']>,
  contentText?: Maybe<Scalars['String']>,
  startDate?: Maybe<Scalars['Date']>,
  endDate?: Maybe<Scalars['Date']>,
};

export type CommentTrashOrder = {
  field: CommentTrashOrderField,
  direction: OrderDirection,
};

export enum CommentTrashOrderField {
  LAST_REPORTED_AT = 'LAST_REPORTED_AT',
  ID = 'ID',
  DELETED_AT = 'DELETED_AT'
}

export type CommentUpdateInput = {
  content: Scalars['String'],
  attachmentIDs?: Maybe<Array<Scalars['ID']>>,
};

export type CommentUpdatePayload = {
   __typename?: 'CommentUpdatePayload',
  comment?: Maybe<Comment>,
};

export type CommetAbuseReportLogCountByDescription = {
   __typename?: 'CommetAbuseReportLogCountByDescription',
  description?: Maybe<Scalars['String']>,
  count?: Maybe<Scalars['Int']>,
};

export type Config = {
   __typename?: 'Config',
  id: Scalars['ID'],
  name: Scalars['String'],
  description: Scalars['String'],
  value?: Maybe<Scalars['String']>,
  defaultValue?: Maybe<Scalars['String']>,
};

export type ConfigInput = {
  value?: Maybe<Scalars['String']>,
};

export type CreateMentorBoardPayload = {
   __typename?: 'CreateMentorBoardPayload',
  board: Board,
};

export type CreateMentorCommentInput = {
  postId: Scalars['ID'],
  parentID?: Maybe<Scalars['ID']>,
  content: Scalars['String'],
  contentText?: Maybe<Scalars['String']>,
  attachmentIDs?: Maybe<Array<Scalars['ID']>>,
  isSecret?: Maybe<Scalars['Boolean']>,
  mentionedCommentID?: Maybe<Scalars['ID']>,
};

export type CreateMentorCommentPayload = {
   __typename?: 'CreateMentorCommentPayload',
  comment: Comment,
};

export type CreateMentorPostInput = {
  boardId: Scalars['ID'],
  title: Scalars['String'],
  organizationName?: Maybe<Scalars['String']>,
  dutyName?: Maybe<Scalars['String']>,
  schoolName?: Maybe<Scalars['String']>,
  dutyId?: Maybe<Scalars['ID']>,
  content: Scalars['String'],
  contentText: Scalars['String'],
  status: PostStatus,
  commentStatus?: Maybe<PostCommentStatus>,
  notifyMessage?: Maybe<Scalars['Boolean']>,
  allowTrackback?: Maybe<Scalars['Boolean']>,
  isNotice?: Maybe<Scalars['Boolean']>,
  tags?: Maybe<Array<Scalars['String']>>,
  containsImage?: Maybe<Scalars['Boolean']>,
  containsYoutube?: Maybe<Scalars['Boolean']>,
  imageSrc?: Maybe<Scalars['String']>,
  youtubeSrc?: Maybe<Scalars['String']>,
  attachmentIDs?: Maybe<Array<Scalars['String']>>,
  dutyIds?: Maybe<Array<Scalars['ID']>>,
  schoolIds?: Maybe<Array<Scalars['ID']>>,
  organizationIds?: Maybe<Array<Scalars['ID']>>,
};

export type CreateMentorPostPayload = {
   __typename?: 'CreateMentorPostPayload',
  post: Post,
};

export type CreateOrganizationGroupInput = {
  name: Scalars['String'],
};

export type CreateOrganizationGroupPayload = {
   __typename?: 'CreateOrganizationGroupPayload',
  organizationGroup: OrganizationGroup,
};

export type CreateOrganizationInput = {
  name: Scalars['String'],
};

export type CreateOrganizationPayload = {
   __typename?: 'CreateOrganizationPayload',
  organization: Organization,
};

export type CurrentUserScrapConnection = {
   __typename?: 'CurrentUserScrapConnection',
  nodes: Array<UserScrap>,
  totalCount: Scalars['Int'],
};

export type CurrentUserScrapCreatePayload = {
   __typename?: 'CurrentUserScrapCreatePayload',
  userScrap?: Maybe<UserScrap>,
};

export type CurrentUserScrapDeletePayload = {
   __typename?: 'CurrentUserScrapDeletePayload',
  userScrap?: Maybe<UserScrap>,
};

export type CurrentUserScrapsDeleteAllPayload = {
   __typename?: 'CurrentUserScrapsDeleteAllPayload',
  userScrap: Array<UserScrap>,
};

export type CurrentUserScrapsFilter = {
  q?: Maybe<Scalars['String']>,
  postID?: Maybe<Scalars['ID']>,
};

export type DailyCommentByUserConnection = {
   __typename?: 'DailyCommentByUserConnection',
  nodes?: Maybe<Array<Comment>>,
  totalCount: Scalars['Int'],
};

export type DailyCommentByUserFilters = {
  dateAt?: Maybe<Scalars['String']>,
};

export type DailyCommentByUserOrder = {
  field: DailyCommentByUserOrderField,
  direction: OrderDirection,
};

export enum DailyCommentByUserOrderField {
  ID = 'ID'
}

export type DailyPostByUserConnection = {
   __typename?: 'DailyPostByUserConnection',
  nodes?: Maybe<Array<Post>>,
  totalCount: Scalars['Int'],
};

export type DailyPostByUserFilters = {
  dateAt?: Maybe<Scalars['String']>,
};

export type DailyPostByUserOrder = {
  field: DailyPostByUserOrderField,
  direction: OrderDirection,
};

export enum DailyPostByUserOrderField {
  ID = 'ID'
}


export type DateRange = {
  from: Scalars['Date'],
  to: Scalars['Date'],
};

export type DeleteMentorBoardPayload = {
   __typename?: 'DeleteMentorBoardPayload',
  board?: Maybe<Board>,
};

export type DeleteMentorCommentPayload = {
   __typename?: 'DeleteMentorCommentPayload',
  comment: Comment,
};

export type DeleteMentorDocumentPayload = {
   __typename?: 'DeleteMentorDocumentPayload',
  mentorDocument: MentorDocument,
};

export type DeleteMentorPostPayload = {
   __typename?: 'DeleteMentorPostPayload',
  post: Post,
};

export type DeleteOrganizationGroupPayload = {
   __typename?: 'DeleteOrganizationGroupPayload',
  organizationGroup: OrganizationGroup,
};

export type Group = {
   __typename?: 'Group',
  description?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  isAdmin: Scalars['Boolean'],
  isDefault: Scalars['Boolean'],
  title: Scalars['String'],
  users: UserConnection,
};


export type GroupUsersArgs = {
  filterBy?: Maybe<UserFilters>,
  orderBy?: Maybe<UserOrder>,
  pagination?: Maybe<Pagination>
};

export type GroupConnection = {
   __typename?: 'GroupConnection',
  nodes: Array<Group>,
  totalCount: Scalars['Int'],
};

export type GroupCreateInput = {
  description?: Maybe<Scalars['String']>,
  title: Scalars['String'],
};

export type GroupCreatePayload = {
   __typename?: 'GroupCreatePayload',
  group: Group,
};

export type GroupDeletePayload = {
   __typename?: 'GroupDeletePayload',
  group?: Maybe<Group>,
};

export type GroupFilters = {
  q?: Maybe<Scalars['String']>,
};

export type GroupUpdateInput = {
  description?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
};

export type GroupUpdatePayload = {
   __typename?: 'GroupUpdatePayload',
  group?: Maybe<Group>,
};

export type HidePostPayload = {
   __typename?: 'HidePostPayload',
  post?: Maybe<Post>,
};

export type IMenu = {
  children: Array<IMenu>,
  description?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  listOrder: Scalars['String'],
  parent?: Maybe<IMenu>,
  permissionViewMenuGroups: Array<Group>,
  sitemap?: Maybe<Sitemap>,
  title?: Maybe<Scalars['String']>,
};

export enum MentorAuthenticationMethod {
  EMAIL = 'EMAIL',
  DOCUMENT = 'DOCUMENT'
}

export enum MentorAuthenticationStatus {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  NOT_AUTHENTICATED = 'NOT_AUTHENTICATED'
}

export type MentorCommentsFilters = {
  createdAtRange?: Maybe<DateRange>,
  query?: Maybe<Scalars['String']>,
};

export type MentorDocument = {
   __typename?: 'MentorDocument',
  id: Scalars['ID'],
  name: Scalars['String'],
  size: Scalars['Int'],
  type: Scalars['String'],
  path?: Maybe<Scalars['String']>,
  url: Scalars['String'],
};

export type MentorDocumentCompleteUploadPayload = {
   __typename?: 'MentorDocumentCompleteUploadPayload',
  mentorDocument: MentorDocument,
};

export type MentorDocumentConnection = {
   __typename?: 'MentorDocumentConnection',
  nodes: Array<MentorDocument>,
  totalCount: Scalars['Int'],
};

export type MentorDocumentPrepareUploadInput = {
  name: Scalars['String'],
  size: Scalars['Int'],
  type: Scalars['String'],
  path?: Maybe<Scalars['String']>,
};

export type MentorDocumentPrepareUploadPayload = {
   __typename?: 'MentorDocumentPrepareUploadPayload',
  mentorDocument: MentorDocument,
  signedUrl: Scalars['String'],
};

export type MentorDuty = {
   __typename?: 'MentorDuty',
  id: Scalars['ID'],
  name: Scalars['String'],
  parent?: Maybe<MentorDuty>,
  children: Array<MentorDuty>,
};

export type MentorRewardLog = {
   __typename?: 'MentorRewardLog',
  id: Scalars['ID'],
  amount: Scalars['Int'],
  createdAt: Scalars['Date'],
  user: User,
  reason: RewardReason,
  userRewardAmount: Scalars['Int'],
  comment?: Maybe<Comment>,
};

export type MentorRewardLogConnection = {
   __typename?: 'MentorRewardLogConnection',
  nodes: Array<MentorRewardLog>,
  totalCount: Scalars['Int'],
};

export type MentorRewardLogFilters = {
  dateRange?: Maybe<DateRange>,
  query?: Maybe<Scalars['String']>,
  mentorRewardLogType?: Maybe<MentorRewardLogType>,
};

export enum MentorRewardLogType {
  USE_POINT = 'USE_POINT',
  GRANT_POINT = 'GRANT_POINT'
}

export type MentorSchool = {
   __typename?: 'MentorSchool',
  id: Scalars['ID'],
  name: Scalars['String'],
};

export type MentorUserFilters = {
  mentorApprovedAtRange?: Maybe<DateRange>,
  mentorAuthenticationRequestedAtRange?: Maybe<DateRange>,
  query?: Maybe<Scalars['String']>,
  queryField?: Maybe<MentorUserQueryField>,
  mentorAuthenticationStatus?: Maybe<SearchMentorAuthenticationStatus>,
};

export type MentorUserKpi = {
   __typename?: 'MentorUserKpi',
  id: Scalars['ID'],
  date: Scalars['Date'],
  activeMentorUserCount: Scalars['Int'],
  newMentorUserCount: Scalars['Int'],
  mentorCommentCount: Scalars['Int'],
  organizationNameMatchedCommentCount: Scalars['Int'],
  dutyNameMatchedCommentCount: Scalars['Int'],
  schoolNameMatchedCommentCount: Scalars['Int'],
  acceptedCommentCount: Scalars['Int'],
  createdAt: Scalars['Date'],
};

export type MentorUserKpiConnection = {
   __typename?: 'MentorUserKpiConnection',
  nodes: Array<MentorUserKpi>,
  totalCount: Scalars['Int'],
};

export type MentorUserKpiFilters = {
  dateRange: DateRange,
};

export type MentorUserKpiGroups = {
  period: Period,
};

export enum MentorUserOrderField {
  MENTOR_APPROVED_AT = 'MENTOR_APPROVED_AT',
  MENTOR_AUTHENTICATION_DOCUMENT_REQUESTED_AT = 'MENTOR_AUTHENTICATION_DOCUMENT_REQUESTED_AT',
  MENTOR_COMMENT_COUNT = 'MENTOR_COMMENT_COUNT',
  ACCEPTED_COMMENT_COUNT = 'ACCEPTED_COMMENT_COUNT',
  MENTOR_REWARD_AMOUNT = 'MENTOR_REWARD_AMOUNT'
}

export type MentorUserOrders = {
  field: MentorUserOrderField,
  direction: OrderDirection,
};

export enum MentorUserQueryField {
  MENTOR_NICKNAME = 'MENTOR_NICKNAME',
  MENTOR_REAL_NAME = 'MENTOR_REAL_NAME',
  ORGANIZATION_NAME = 'ORGANIZATION_NAME',
  DUTY_NAME = 'DUTY_NAME',
  SCHOOL_NAME = 'SCHOOL_NAME',
  ORGANIZATION_NAME_IN_REVIEW = 'ORGANIZATION_NAME_IN_REVIEW',
  DUTY_NAME_IN_REVIEW = 'DUTY_NAME_IN_REVIEW',
  SCHOOL_NAME_IN_REVIEW = 'SCHOOL_NAME_IN_REVIEW'
}

export type Menu = Bookmarkable & {
   __typename?: 'Menu',
  id: Scalars['ID'],
  menuType?: Maybe<Scalars['String']>,
  parentID?: Maybe<Scalars['String']>,
  subMenus: Array<Menu>,
  groupIDList?: Maybe<Scalars['String']>,
  groupIDs: Array<Scalars['ID']>,
  slug?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  order?: Maybe<Scalars['Int']>,
  isShortcut: Scalars['Boolean'],
  updatedAt?: Maybe<Scalars['Date']>,
  viewerHasBookmarked: Scalars['Boolean'],
  hasNewPost: Scalars['Boolean'],
  bookmarkable: Scalars['Boolean'],
  board?: Maybe<Board>,
  permissionViewMenuGroups: Array<Group>,
};

export type MenuConnection = {
   __typename?: 'MenuConnection',
  nodes: Array<IMenu>,
  totalCount: Scalars['Int'],
};

export type MenuDeletePayload = {
   __typename?: 'MenuDeletePayload',
  menu?: Maybe<IMenu>,
};

export type MenuDuplicatePayload = {
   __typename?: 'MenuDuplicatePayload',
  menu: IMenu,
};

export type MenuFilters = {
  q?: Maybe<Scalars['String']>,
};

export enum MenuMoveDirection {
  DOWN = 'DOWN',
  UP = 'UP'
}

export type MenuMovePayload = {
   __typename?: 'MenuMovePayload',
  menu?: Maybe<IMenu>,
  swappedMenu?: Maybe<IMenu>,
};

export type MenuOrder = {
  field: MenuOrderField,
  direction: OrderDirection,
};

export enum MenuOrderField {
  ORDER = 'ORDER'
}

export type Message = {
   __typename?: 'Message',
  id: Scalars['ID'],
  receivedMessageID: Scalars['Int'],
  senderID: Scalars['Int'],
  receiverID: Scalars['Int'],
  sender?: Maybe<User>,
  receiver?: Maybe<User>,
  type: Scalars['String'],
  notificationType?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  content: Scalars['String'],
  excerpt?: Maybe<Scalars['String']>,
  linkURL?: Maybe<Scalars['String']>,
  isReplied?: Maybe<Scalars['Boolean']>,
  hasRead: Scalars['Boolean'],
  createdAt: Scalars['Date'],
  readAt?: Maybe<Scalars['Date']>,
  order?: Maybe<Scalars['Int']>,
};


export type MessageExcerptArgs = {
  pruneLength?: Maybe<Scalars['Int']>
};

export type MessageConnection = {
   __typename?: 'MessageConnection',
  nodes?: Maybe<Array<Message>>,
  totalCount: Scalars['Int'],
};

export type MessageCreateInput = {
  receiverID: Scalars['Int'],
  content: Scalars['String'],
  isReplied: Scalars['Boolean'],
};

export type MessageCreatePayload = {
   __typename?: 'MessageCreatePayload',
  message?: Maybe<Message>,
};

export type MessageDeleteAllPayload = {
   __typename?: 'MessageDeleteAllPayload',
  count?: Maybe<Scalars['Int']>,
};

export type MessageDeletePayload = {
   __typename?: 'MessageDeletePayload',
  message?: Maybe<Message>,
};

export type MessageFiltersInput = {
  type: Scalars['String'],
};

export type MessageOrder = {
  field: MessageOrderField,
  direction: OrderDirection,
};

export enum MessageOrderField {
  ID = 'ID',
  CREATED_AT = 'CREATED_AT'
}

export type MessageStorePayload = {
   __typename?: 'MessageStorePayload',
  message?: Maybe<Message>,
};

export type MessageUpdatePayload = {
   __typename?: 'MessageUpdatePayload',
  message?: Maybe<Message>,
};

export type Mutation = {
   __typename?: 'Mutation',
  createMentorBoard: CreateMentorBoardPayload,
  updateMentorBoards: UpdateMentorBoardsPayload,
  deleteMentorBoard: DeleteMentorBoardPayload,
  acceptMentorComment: AcceptMentorCommentPayload,
  createMentorComment: CreateMentorCommentPayload,
  deleteMentorComment: DeleteMentorCommentPayload,
  createMentorPost: CreateMentorPostPayload,
  updateMentorPost: UpdateMentorPostPayload,
  updateMentorPosts: UpdateMentorPostsPayload,
  deleteMentorPost: DeleteMentorPostPayload,
  restoreMentorPost: RestoreMentorPostPayload,
  updateMentorUserStatus: UpdateMentorUserStatusPayload,
  authenticateEmail: AuthenticateEmailPayload,
  authenticateWithEmail: AuthenticateWithEmailPayload,
  authenticateWithDocuments: AuthenticateWithDocumentsPayloadDto,
  createOrganization: CreateOrganizationPayload,
  createOrganizationGroup: CreateOrganizationGroupPayload,
  updateOrganizationGroup: UpdateOrganizationGroupPayload,
  deleteOrganizationGroup: DeleteOrganizationGroupPayload,
  duplicateCheckOrganizationGroupName: Scalars['Boolean'],
  updateRewardAmountSettings: UpdateRewardAmountSettingsPayload,
  mentorDocumentPrepareUpload: MentorDocumentPrepareUploadPayload,
  mentorDocumentCompleteUpload: MentorDocumentCompleteUploadPayload,
  deleteMentorDocument: DeleteMentorDocumentPayload,
  requestCashout: RequestCashoutPayload,
  attachmentPrepareUpload?: Maybe<AttachmentPrepareUploadPayload>,
  attachmentCompleteUpload?: Maybe<AttachmentCompleteUploadPayload>,
  attachmentDelete?: Maybe<AttachmentDeletePayload>,
  banUser?: Maybe<BanLog>,
  unbanUsers?: Maybe<UnbanLogsPayLoad>,
  issueSignedURL?: Maybe<Scalars['String']>,
  boardCreate: BoardCreatePayload,
  boardUpdate: BoardUpdatePayload,
  boardDelete: BoardDeletePayload,
  boardNavigationUpdate?: Maybe<BoardNavigationUpdatePayload>,
  addBookmark?: Maybe<AddBookmarkPayload>,
  removeBookmark?: Maybe<RemoveBookmarkPayload>,
  updateBestPostRequirement?: Maybe<CollectionRequirementUpdatePayload>,
  updateBestOfBestRequirement?: Maybe<CollectionRequirementUpdatePayload>,
  commentDelete?: Maybe<CommentDeletePayload>,
  commentCreate?: Maybe<CommentCreatePayload>,
  commentUpdate?: Maybe<CommentUpdatePayload>,
  commentRate?: Maybe<CommentRatePayload>,
  commentLike?: Maybe<CommentLikePayload>,
  commentDislike?: Maybe<CommentDislikePayload>,
  commentRemoveLike?: Maybe<CommentRemoveLikePayload>,
  commentAbuseReport?: Maybe<Scalars['Boolean']>,
  reportComment?: Maybe<ReportCommentPayload>,
  readCommentAbuseReportLog?: Maybe<CommentAbuseReportLogUpdatePayload>,
  readAllCommentAbuseReportLog?: Maybe<CommentAbuseReportLogBulkUpdatePayload>,
  configUpdate?: Maybe<Config>,
  configReset?: Maybe<Scalars['Boolean']>,
  groupCreate: GroupCreatePayload,
  groupDelete: GroupDeletePayload,
  groupUpdate: GroupUpdatePayload,
  boardMenuCreate: BoardMenuCreatePayload,
  boardMenuUpdate: BoardMenuUpdatePayload,
  mainMenuSet?: Maybe<IMenu>,
  menuAndBoardDelete: MenuDeletePayload,
  menuDelete: MenuDeletePayload,
  menuDuplicate: MenuDuplicatePayload,
  menuMove: MenuMovePayload,
  shortcutMenuCreate: ShortcutMenuCreatePayload,
  shortcutMenuUpdate: ShortcutMenuUpdatePayload,
  simpleMenuCreate: SimpleMenuCreatePayload,
  simpleMenuUpdate: SimpleMenuUpdatePayload,
  messageCreate?: Maybe<MessageCreatePayload>,
  messageUpdate?: Maybe<MessageUpdatePayload>,
  messageStore?: Maybe<MessageStorePayload>,
  messageDelete?: Maybe<MessageDeletePayload>,
  messageDeleteAll?: Maybe<MessageDeleteAllPayload>,
  muteUser?: Maybe<User>,
  unmuteUser?: Maybe<User>,
  notificationCreate?: Maybe<NotificationCreatePayload>,
  notificationUpdate?: Maybe<NotificationUpdatePayload>,
  notificationDelete?: Maybe<NotificationDeletePayload>,
  notificationDeleteAll?: Maybe<NotificationDeleteAllPayload>,
  postUpdate?: Maybe<PostUpdatePayload>,
  postUpdateMany?: Maybe<PostUpdateManyPayload>,
  postDelete?: Maybe<PostDeletePayload>,
  postDeleteMany?: Maybe<PostDeleteManyPayload>,
  postCreate?: Maybe<PostCreatePayload>,
  postsUpdate?: Maybe<PostsUpdatePayload>,
  postRate?: Maybe<PostRatePayload>,
  postLike?: Maybe<PostLikePayload>,
  postDislike?: Maybe<PostDislikePayload>,
  postRemoveLike?: Maybe<PostRemoveLikePayload>,
  postRead?: Maybe<PostReadPayload>,
  postAbuseReport?: Maybe<Scalars['Boolean']>,
  reportPost?: Maybe<ReportPostPayload>,
  restorePost?: Maybe<RestorePostPayload>,
  hidePostFromOthers?: Maybe<HidePostPayload>,
  readPostAbuseReportLog?: Maybe<PostAbuseReportLogUpdatePayload>,
  readAllPostAbuseReportLog?: Maybe<PostAbuseReportLogBulkUpdatePayload>,
  postCollectionCreate?: Maybe<PostCollectionCreatePayload>,
  postCollectionUpdate?: Maybe<PostCollectionUpdatePayload>,
  postCollectionDelete?: Maybe<PostCollectionDeletePayload>,
  postCollectionRebuild?: Maybe<PostCollectionRebuildPayload>,
  sitemapCreate: SitemapCreatePayload,
  sitemapDelete: SitemapDeletePayload,
  normalizeListOrder: Scalars['Boolean'],
  sitemapMove: SitemapMovePayload,
  sitemapUpdate: SitemapUpdatePayload,
  tagCreate?: Maybe<TagCreatePayload>,
  tagUpdate?: Maybe<TagUpdatePayload>,
  tagDelete?: Maybe<TagDeletePayload>,
  tagDeleteMany?: Maybe<TagDeleteManyPayload>,
  trashCreate?: Maybe<TrashCreatePayload>,
  updateUserProfileImage?: Maybe<User>,
  currentUserScrapCreate?: Maybe<CurrentUserScrapCreatePayload>,
  currentUserScrapDelete?: Maybe<CurrentUserScrapDeletePayload>,
  currentUserScrapsDeleteAll?: Maybe<CurrentUserScrapsDeleteAllPayload>,
};


export type MutationCreateMentorBoardArgs = {
  id: Scalars['ID'],
  menteeAcceptableCommentCount?: Maybe<Scalars['Int']>
};


export type MutationUpdateMentorBoardsArgs = {
  input: UpdateMentorBoardsInput
};


export type MutationDeleteMentorBoardArgs = {
  id: Scalars['ID']
};


export type MutationAcceptMentorCommentArgs = {
  id: Scalars['ID']
};


export type MutationCreateMentorCommentArgs = {
  input: CreateMentorCommentInput
};


export type MutationDeleteMentorCommentArgs = {
  id: Scalars['ID']
};


export type MutationCreateMentorPostArgs = {
  input: CreateMentorPostInput
};


export type MutationUpdateMentorPostArgs = {
  input: UpdateMentorPostInput
};


export type MutationUpdateMentorPostsArgs = {
  input: UpdateMentorPostsInput
};


export type MutationDeleteMentorPostArgs = {
  id: Scalars['ID']
};


export type MutationRestoreMentorPostArgs = {
  id: Scalars['ID']
};


export type MutationUpdateMentorUserStatusArgs = {
  input: UpdateMentorUserStatusInput
};


export type MutationAuthenticateEmailArgs = {
  email: Scalars['String']
};


export type MutationAuthenticateWithEmailArgs = {
  input: AuthenticateWithEmailInput
};


export type MutationAuthenticateWithDocumentsArgs = {
  input: AuthenticateWithDocumentsInput
};


export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput
};


export type MutationCreateOrganizationGroupArgs = {
  input: CreateOrganizationGroupInput
};


export type MutationUpdateOrganizationGroupArgs = {
  input: UpdateOrganizationGroupInput
};


export type MutationDeleteOrganizationGroupArgs = {
  id: Scalars['ID']
};


export type MutationDuplicateCheckOrganizationGroupNameArgs = {
  name: Scalars['String']
};


export type MutationUpdateRewardAmountSettingsArgs = {
  input: UpdateRewardAmountSettingsInput
};


export type MutationMentorDocumentPrepareUploadArgs = {
  input: MentorDocumentPrepareUploadInput
};


export type MutationMentorDocumentCompleteUploadArgs = {
  id: Scalars['ID']
};


export type MutationDeleteMentorDocumentArgs = {
  id: Scalars['ID']
};


export type MutationRequestCashoutArgs = {
  input: RequestCashoutInput
};


export type MutationAttachmentPrepareUploadArgs = {
  postID?: Maybe<Scalars['ID']>,
  commentID?: Maybe<Scalars['ID']>,
  name?: Maybe<Scalars['String']>,
  size?: Maybe<Scalars['Int']>,
  type: Scalars['String']
};


export type MutationAttachmentCompleteUploadArgs = {
  attachmentID: Scalars['ID']
};


export type MutationAttachmentDeleteArgs = {
  attachmentID: Scalars['ID']
};


export type MutationBanUserArgs = {
  input: BanLogInput
};


export type MutationUnbanUsersArgs = {
  logIDs: Array<Scalars['ID']>
};


export type MutationIssueSignedUrlArgs = {
  contentType?: Maybe<Scalars['String']>
};


export type MutationBoardCreateArgs = {
  input: BoardCreateInput,
  sitemapID: Scalars['ID']
};


export type MutationBoardUpdateArgs = {
  input: BoardUpdateInput
};


export type MutationBoardDeleteArgs = {
  id: Scalars['ID']
};


export type MutationBoardNavigationUpdateArgs = {
  id: Scalars['ID'],
  input: BoardNavigationUpdateInput
};


export type MutationAddBookmarkArgs = {
  menuID?: Maybe<Scalars['ID']>,
  id?: Maybe<Scalars['ID']>
};


export type MutationRemoveBookmarkArgs = {
  menuID?: Maybe<Scalars['ID']>,
  id?: Maybe<Scalars['ID']>
};


export type MutationUpdateBestPostRequirementArgs = {
  input: CollectionRequirementUpdateInput
};


export type MutationUpdateBestOfBestRequirementArgs = {
  input: CollectionRequirementUpdateInput
};


export type MutationCommentDeleteArgs = {
  id: Scalars['ID']
};


export type MutationCommentCreateArgs = {
  postID: Scalars['ID'],
  parentID?: Maybe<Scalars['ID']>,
  input: CommentInput
};


export type MutationCommentUpdateArgs = {
  id: Scalars['ID'],
  input: CommentInput
};


export type MutationCommentRateArgs = {
  id: Scalars['ID'],
  rating: RatingType
};


export type MutationCommentLikeArgs = {
  id: Scalars['ID']
};


export type MutationCommentDislikeArgs = {
  id: Scalars['ID']
};


export type MutationCommentRemoveLikeArgs = {
  id: Scalars['ID']
};


export type MutationCommentAbuseReportArgs = {
  id: Scalars['ID']
};


export type MutationReportCommentArgs = {
  input: ReportCommentInput
};


export type MutationReadCommentAbuseReportLogArgs = {
  id: Scalars['ID']
};


export type MutationReadAllCommentAbuseReportLogArgs = {
  commentID: Scalars['ID']
};


export type MutationConfigUpdateArgs = {
  id: Scalars['ID'],
  input: ConfigInput
};


export type MutationConfigResetArgs = {
  id: Scalars['ID']
};


export type MutationGroupCreateArgs = {
  input: GroupCreateInput
};


export type MutationGroupDeleteArgs = {
  id: Scalars['ID']
};


export type MutationGroupUpdateArgs = {
  id: Scalars['ID'],
  input: GroupUpdateInput
};


export type MutationBoardMenuCreateArgs = {
  input: BoardMenuCreateInput
};


export type MutationBoardMenuUpdateArgs = {
  id: Scalars['ID'],
  input: BoardMenuUpdateInput
};


export type MutationMainMenuSetArgs = {
  id: Scalars['ID']
};


export type MutationMenuAndBoardDeleteArgs = {
  id: Scalars['ID']
};


export type MutationMenuDeleteArgs = {
  id: Scalars['ID']
};


export type MutationMenuDuplicateArgs = {
  id: Scalars['ID'],
  parentID?: Maybe<Scalars['ID']>,
  sitemapID?: Maybe<Scalars['ID']>
};


export type MutationMenuMoveArgs = {
  direction: MenuMoveDirection,
  id: Scalars['ID']
};


export type MutationShortcutMenuCreateArgs = {
  input: ShortcutMenuCreateInput
};


export type MutationShortcutMenuUpdateArgs = {
  id: Scalars['ID'],
  input: ShortcutMenuUpdateInput
};


export type MutationSimpleMenuCreateArgs = {
  input: SimpleMenuCreateInput
};


export type MutationSimpleMenuUpdateArgs = {
  id: Scalars['ID'],
  input: SimpleMenuUpdateInput
};


export type MutationMessageCreateArgs = {
  input: MessageCreateInput
};


export type MutationMessageUpdateArgs = {
  id: Scalars['ID']
};


export type MutationMessageStoreArgs = {
  id: Scalars['ID']
};


export type MutationMessageDeleteArgs = {
  id: Scalars['ID']
};


export type MutationMessageDeleteAllArgs = {
  ids: Array<Scalars['ID']>
};


export type MutationMuteUserArgs = {
  mutedUserID: Scalars['ID']
};


export type MutationUnmuteUserArgs = {
  mutedUserID: Scalars['ID']
};


export type MutationNotificationCreateArgs = {
  input: NotificationCreateInput
};


export type MutationNotificationUpdateArgs = {
  id: Scalars['ID'],
  hasRead: Scalars['Boolean']
};


export type MutationNotificationDeleteArgs = {
  id: Scalars['ID']
};


export type MutationPostUpdateArgs = {
  id: Scalars['ID'],
  input: PostUpdateInput
};


export type MutationPostUpdateManyArgs = {
  ids: Array<Scalars['ID']>,
  input: PostUpdateManyInput
};


export type MutationPostDeleteArgs = {
  id: Scalars['ID']
};


export type MutationPostDeleteManyArgs = {
  ids: Array<Scalars['ID']>
};


export type MutationPostCreateArgs = {
  input: PostInput
};


export type MutationPostsUpdateArgs = {
  boardID?: Maybe<Scalars['ID']>,
  input: PostsUpdateInput
};


export type MutationPostRateArgs = {
  id: Scalars['ID'],
  rating: RatingType
};


export type MutationPostLikeArgs = {
  id: Scalars['ID']
};


export type MutationPostDislikeArgs = {
  id: Scalars['ID']
};


export type MutationPostRemoveLikeArgs = {
  id: Scalars['ID']
};


export type MutationPostReadArgs = {
  id: Scalars['ID']
};


export type MutationPostAbuseReportArgs = {
  id: Scalars['ID']
};


export type MutationReportPostArgs = {
  input: ReportPostInput
};


export type MutationRestorePostArgs = {
  id: Scalars['ID']
};


export type MutationHidePostFromOthersArgs = {
  id: Scalars['ID'],
  expiresAt?: Maybe<Scalars['Date']>
};


export type MutationReadPostAbuseReportLogArgs = {
  id: Scalars['ID']
};


export type MutationReadAllPostAbuseReportLogArgs = {
  postID: Scalars['ID']
};


export type MutationPostCollectionCreateArgs = {
  input: PostCollectionInput
};


export type MutationPostCollectionUpdateArgs = {
  id: Scalars['ID'],
  input: PostCollectionInput
};


export type MutationPostCollectionDeleteArgs = {
  id: Scalars['ID']
};


export type MutationPostCollectionRebuildArgs = {
  id: Scalars['ID'],
  automation?: Maybe<PostCollectionAutomationInput>
};


export type MutationSitemapCreateArgs = {
  input: SitemapCreateInput
};


export type MutationSitemapDeleteArgs = {
  id: Scalars['ID']
};


export type MutationSitemapMoveArgs = {
  direction: SitemapMoveDirection,
  id: Scalars['ID']
};


export type MutationSitemapUpdateArgs = {
  id: Scalars['ID'],
  input: SitemapUpdateInput
};


export type MutationTagCreateArgs = {
  input: TagInput
};


export type MutationTagUpdateArgs = {
  id: Scalars['ID'],
  input: TagInput
};


export type MutationTagDeleteArgs = {
  id: Scalars['ID']
};


export type MutationTagDeleteManyArgs = {
  ids: Array<Scalars['ID']>
};


export type MutationTrashCreateArgs = {
  input?: Maybe<TrashInput>
};


export type MutationUpdateUserProfileImageArgs = {
  input: UpdateUserInput
};


export type MutationCurrentUserScrapCreateArgs = {
  postID: Scalars['ID']
};


export type MutationCurrentUserScrapDeleteArgs = {
  postID: Scalars['ID']
};

export type Notification = {
   __typename?: 'Notification',
  id: Scalars['ID'],
  hasRead: Scalars['Boolean'],
  type?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  content: Scalars['String'],
  senderNickname?: Maybe<Scalars['String']>,
  linkURL: Scalars['String'],
  senderID?: Maybe<Scalars['Int']>,
  receiverID?: Maybe<Scalars['Int']>,
  createdAt: Scalars['Date'],
  deletedAt?: Maybe<Scalars['Date']>,
};

export type NotificationConnection = {
   __typename?: 'NotificationConnection',
  notifications?: Maybe<Array<Maybe<Notification>>>,
  nodes?: Maybe<Array<Maybe<Notification>>>,
  totalCount?: Maybe<Scalars['Int']>,
};

export type NotificationCreateInput = {
  receiverID: Scalars['Int'],
  content: Scalars['String'],
  notificationType: Scalars['String'],
  linkURL: Scalars['String'],
};

export type NotificationCreatePayload = {
   __typename?: 'NotificationCreatePayload',
  notification?: Maybe<Message>,
};

export type NotificationDeleteAllPayload = {
   __typename?: 'NotificationDeleteAllPayload',
  count?: Maybe<Scalars['Int']>,
};

export type NotificationDeletePayload = {
   __typename?: 'NotificationDeletePayload',
  notification?: Maybe<Message>,
};

export type NotificationUpdatePayload = {
   __typename?: 'NotificationUpdatePayload',
  notification?: Maybe<Message>,
};

export enum OrderDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type Organization = {
   __typename?: 'Organization',
  id: Scalars['ID'],
  name: Scalars['String'],
  organizationGroup?: Maybe<OrganizationGroup>,
};

export enum OrganizationAddedByAdminQueryField {
  ORGANIZATION_NAME = 'ORGANIZATION_NAME'
}

export type OrganizationConnection = {
   __typename?: 'OrganizationConnection',
  nodes: Array<Organization>,
  totalCount: Scalars['Int'],
};

export type OrganizationGroup = {
   __typename?: 'OrganizationGroup',
  id: Scalars['ID'],
  name: Scalars['String'],
  createdAt: Scalars['Date'],
  organizations: OrganizationConnection,
};

export type OrganizationGroupConnection = {
   __typename?: 'OrganizationGroupConnection',
  nodes: Array<OrganizationGroup>,
  totalCount: Scalars['Int'],
};

export type OrganizationGroupFilters = {
  field?: Maybe<OrganizationGroupQueryField>,
  query?: Maybe<Scalars['String']>,
};

export enum OrganizationGroupQueryField {
  ORGANIZATION_NAME = 'ORGANIZATION_NAME',
  ORGANIZATION_GROUP_NAME = 'ORGANIZATION_GROUP_NAME'
}

export type OrganizationsAddedByAdminFiltersInput = {
  field?: Maybe<OrganizationAddedByAdminQueryField>,
  query?: Maybe<Scalars['String']>,
};

export type Pagination = {
  page: Scalars['Int'],
  pageSize: Scalars['Int'],
};

export enum Period {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY'
}

export type PermissionInput = {
  type: BoardPermissionType,
  groupIDs: Array<Scalars['ID']>,
};

export enum PermissionType {
  LIST = 'LIST',
  VIEW = 'VIEW',
  ACCESS = 'ACCESS',
  MANAGER = 'MANAGER',
  WRITE_COMMENT = 'WRITE_COMMENT',
  WRITE_DOCUMENT = 'WRITE_DOCUMENT',
  CONSULTATION_READ = 'CONSULTATION_READ'
}

export type Post = {
   __typename?: 'Post',
  id: Scalars['ID'],
  organizationName?: Maybe<Scalars['String']>,
  schoolName?: Maybe<Scalars['String']>,
  acceptedCommentCount: Scalars['Int'],
  board?: Maybe<Board>,
  isMentorPost: Scalars['Boolean'],
  dutyName?: Maybe<Scalars['String']>,
  duty?: Maybe<MentorDuty>,
  remainingAcceptableCommentCount?: Maybe<Scalars['Int']>,
  mentorComments: CommentConnection,
  isViewerMatchingOrganization: Scalars['Boolean'],
  isViewerMatchingMentorDuty: Scalars['Boolean'],
  isViewerMatchingMentorSchool: Scalars['Boolean'],
  isViewerAvailableMatchingReward: Scalars['Boolean'],
  isViewerAvailableAcceptReward: Scalars['Boolean'],
  hasViewerAcceptedCommentInPost: Scalars['Boolean'],
  organizations?: Maybe<Array<Organization>>,
  duties?: Maybe<Array<MentorDuty>>,
  schools?: Maybe<Array<MentorSchool>>,
  createdAt: Scalars['Date'],
  updatedAt: Scalars['Date'],
  deletedAt?: Maybe<Scalars['Date']>,
  url?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  content?: Maybe<Scalars['String']>,
  contentText?: Maybe<Scalars['String']>,
  excerpt?: Maybe<Scalars['String']>,
  views: Scalars['Int'],
  commentCount: Scalars['Int'],
  comments: CommentConnection,
  commentsExcludingCommentIds: CommentConnection,
  likes: Scalars['Int'],
  dislikes: Scalars['Int'],
  myRating?: Maybe<RatingType>,
  globalNotice?: Maybe<Scalars['Boolean']>,
  postCollections: Array<PostCollection>,
  commentStatus?: Maybe<PostCommentStatus>,
  allowTrackback?: Maybe<Scalars['Boolean']>,
  notifyMessage?: Maybe<Scalars['Boolean']>,
  isNotice?: Maybe<Scalars['Boolean']>,
  isAuthor?: Maybe<Scalars['Boolean']>,
  status?: Maybe<PostStatus>,
  nickname?: Maybe<Scalars['String']>,
  username?: Maybe<Scalars['String']>,
  author?: Maybe<User>,
  ipaddress?: Maybe<Scalars['String']>,
  containsImage: Scalars['Boolean'],
  containsYoutube: Scalars['Boolean'],
  imageSrc?: Maybe<Scalars['String']>,
  youtubeSrc?: Maybe<Scalars['String']>,
  attachments: Array<Attachment>,
  tags: Array<Tag>,
  prevPostID?: Maybe<Scalars['ID']>,
  nextPostID?: Maybe<Scalars['ID']>,
  scrapCount: Scalars['Int'],
  isScrapped: Scalars['Boolean'],
  score: Scalars['Int'],
  isAnonymous: Scalars['Boolean'],
  viewerHasMuteAuthor: Scalars['Boolean'],
  reportCount?: Maybe<Scalars['Int']>,
  lastReportedAt?: Maybe<Scalars['Date']>,
  adminReportReadAt?: Maybe<Scalars['Date']>,
  hasUnreadReport?: Maybe<Scalars['Boolean']>,
  reportDescription?: Maybe<Scalars['String']>,
  reportLogs?: Maybe<PostAbuseReportLogConnection>,
  reportCountByDescription?: Maybe<Array<PostAbuseReportLogCountByDescription>>,
  originContentText?: Maybe<Scalars['String']>,
};


export type PostMentorCommentsArgs = {
  pagination?: Maybe<Pagination>,
  orderBy?: Maybe<CommentOrder>
};


export type PostContentTextArgs = {
  pruneLength?: Maybe<Scalars['Int']>
};


export type PostExcerptArgs = {
  pruneLength?: Maybe<Scalars['Int']>
};


export type PostCommentsArgs = {
  orderBy?: Maybe<CommentOrder>,
  pagination?: Maybe<Pagination>
};


export type PostCommentsExcludingCommentIdsArgs = {
  orderBy?: Maybe<CommentOrder>,
  excludingCommentIds: Array<Scalars['ID']>,
  take: Scalars['Int'],
  skip: Scalars['Int']
};


export type PostPrevPostIdArgs = {
  postCursorType?: Maybe<PostCursorType>
};


export type PostNextPostIdArgs = {
  postCursorType?: Maybe<PostCursorType>
};


export type PostReportLogsArgs = {
  orderBy?: Maybe<PostAbuseReportLogOrder>,
  pagination?: Maybe<Pagination>
};

export type PostAbuseReportLog = {
   __typename?: 'PostAbuseReportLog',
  id?: Maybe<Scalars['ID']>,
  post?: Maybe<Post>,
  reporter?: Maybe<User>,
  ipaddress?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  adminReadAt?: Maybe<Scalars['Date']>,
  createdAt?: Maybe<Scalars['Date']>,
};

export type PostAbuseReportLogBulkUpdatePayload = {
   __typename?: 'PostAbuseReportLogBulkUpdatePayload',
  updatedPostAbuseReportLogs: Scalars['Int'],
};

export type PostAbuseReportLogConnection = {
   __typename?: 'PostAbuseReportLogConnection',
  nodes?: Maybe<Array<PostAbuseReportLog>>,
  totalCount: Scalars['Int'],
};

export type PostAbuseReportLogCountByDescription = {
   __typename?: 'PostAbuseReportLogCountByDescription',
  description?: Maybe<Scalars['String']>,
  count?: Maybe<Scalars['Int']>,
};

export type PostAbuseReportLogFilters = {
  postID?: Maybe<Scalars['ID']>,
};

export type PostAbuseReportLogOrder = {
  field: PostAbuseReportLogOrderField,
  direction: OrderDirection,
};

export enum PostAbuseReportLogOrderField {
  CREATED_AT = 'CREATED_AT',
  ID = 'ID',
  ADMIN_READ_AT = 'ADMIN_READ_AT'
}

export type PostAbuseReportLogUpdatePayload = {
   __typename?: 'PostAbuseReportLogUpdatePayload',
  postAbuseReportLog?: Maybe<PostAbuseReportLog>,
};

export type PostCollection = {
   __typename?: 'PostCollection',
  id: Scalars['ID'],
  title?: Maybe<Scalars['String']>,
  posts: Array<Post>,
  automation?: Maybe<PostCollectionAutomation>,
  updatedAt: Scalars['Date'],
};


export type PostCollectionPostsArgs = {
  orderBy?: Maybe<PostOrder>,
  pagination?: Maybe<Pagination>
};

export type PostCollectionAutomation = {
   __typename?: 'PostCollectionAutomation',
  enabled: Scalars['Boolean'],
  boards: Array<Board>,
  filters?: Maybe<PostFilters>,
};

export type PostCollectionAutomationInput = {
  enabled?: Maybe<Scalars['Boolean']>,
  boardIDs?: Maybe<Array<Scalars['ID']>>,
  filters?: Maybe<PostFiltersInput>,
};

export type PostCollectionConnection = {
   __typename?: 'PostCollectionConnection',
  nodes?: Maybe<Array<Maybe<PostCollection>>>,
  totalCount: Scalars['Int'],
};

export type PostCollectionCreatePayload = {
   __typename?: 'PostCollectionCreatePayload',
  postCollection?: Maybe<PostCollection>,
};

export type PostCollectionDeletePayload = {
   __typename?: 'PostCollectionDeletePayload',
  postCollection?: Maybe<PostCollection>,
};

export type PostCollectionFiltersInput = {
  q?: Maybe<Scalars['String']>,
};

export type PostCollectionInput = {
  title?: Maybe<Scalars['String']>,
  postIDs?: Maybe<Array<Scalars['ID']>>,
  automation?: Maybe<PostCollectionAutomationInput>,
};

export type PostCollectionOrder = {
  field: PostCollectionOrderField,
  direction: OrderDirection,
};

export enum PostCollectionOrderField {
  ID = 'ID'
}

export type PostCollectionRebuildPayload = {
   __typename?: 'PostCollectionRebuildPayload',
  postCollection?: Maybe<PostCollection>,
};

export type PostCollectionUpdatePayload = {
   __typename?: 'PostCollectionUpdatePayload',
  postCollection?: Maybe<PostCollection>,
};

export enum PostCommentStatus {
  ALLOW = 'ALLOW',
  DENY = 'DENY'
}

export type PostConnection = {
   __typename?: 'PostConnection',
  nodes?: Maybe<Array<Post>>,
  totalCount: Scalars['Int'],
};

export type PostCreatePayload = {
   __typename?: 'PostCreatePayload',
  post?: Maybe<Post>,
  menu?: Maybe<Menu>,
};

export enum PostCursorType {
  ALL = 'ALL',
  BEST = 'BEST',
  BOARD = 'BOARD'
}

export type PostDeleteManyPayload = {
   __typename?: 'PostDeleteManyPayload',
  posts: Array<Post>,
};

export type PostDeletePayload = {
   __typename?: 'PostDeletePayload',
  deletedPostID?: Maybe<Scalars['ID']>,
  post?: Maybe<Post>,
};

export type PostDislikePayload = {
   __typename?: 'PostDislikePayload',
  post?: Maybe<Post>,
};

export type PostFilters = {
   __typename?: 'PostFilters',
  views?: Maybe<Range>,
  globalNotice?: Maybe<Scalars['Boolean']>,
};

export type PostFiltersInput = {
  q?: Maybe<Scalars['String']>,
  tagID?: Maybe<Scalars['ID']>,
  views?: Maybe<RangeInput>,
  globalNotice?: Maybe<Scalars['Boolean']>,
  userID?: Maybe<Scalars['ID']>,
  minimumCommentCount?: Maybe<Scalars['Int']>,
  minimumViewCount?: Maybe<Scalars['Int']>,
  minimumLikeCount?: Maybe<Scalars['Int']>,
  field?: Maybe<Scalars['String']>,
  word?: Maybe<Scalars['String']>,
  withDelete?: Maybe<Scalars['Boolean']>,
  onlyDelete?: Maybe<Scalars['Boolean']>,
  commentUserID?: Maybe<Scalars['ID']>,
  boardID?: Maybe<Scalars['ID']>,
  excludedBoardIDs?: Maybe<Array<Scalars['ID']>>,
  scrapCount?: Maybe<Scalars['Int']>,
};

export type PostInput = {
  title: Scalars['String'],
  content: Scalars['String'],
  contentText: Scalars['String'],
  boardID: Scalars['Int'],
  status: PostStatus,
  commentStatus?: Maybe<PostCommentStatus>,
  notifyMessage?: Maybe<Scalars['Boolean']>,
  allowTrackback?: Maybe<Scalars['Boolean']>,
  isNotice?: Maybe<Scalars['Boolean']>,
  tags?: Maybe<Array<Scalars['String']>>,
  containsImage?: Maybe<Scalars['Boolean']>,
  containsYoutube?: Maybe<Scalars['Boolean']>,
  imageSrc?: Maybe<Scalars['String']>,
  youtubeSrc?: Maybe<Scalars['String']>,
  attachmentIDs?: Maybe<Array<Scalars['ID']>>,
};

export type PostLikePayload = {
   __typename?: 'PostLikePayload',
  post?: Maybe<Post>,
};

export type PostOrder = {
  field: PostOrderField,
  direction: OrderDirection,
};

export enum PostOrderField {
  ID = 'ID',
  GLOBAL_NOTICE = 'GLOBAL_NOTICE',
  CREATED_AT = 'CREATED_AT',
  UPDATED_AT = 'UPDATED_AT',
  SCORE = 'SCORE',
  VIEWS = 'VIEWS',
  LIKES = 'LIKES',
  SCRAP_COUNT = 'SCRAP_COUNT',
  COMMENT_COUNT = 'COMMENT_COUNT',
  RELEVANCE = 'RELEVANCE'
}

export type PostRatePayload = {
   __typename?: 'PostRatePayload',
  post?: Maybe<Post>,
};

export type PostReadPayload = {
   __typename?: 'PostReadPayload',
  post?: Maybe<Post>,
};

export type PostRemoveLikePayload = {
   __typename?: 'PostRemoveLikePayload',
  post?: Maybe<Post>,
};

export type PostReportFilters = {
  userID?: Maybe<Scalars['ID']>,
  nickname?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  startDate?: Maybe<Scalars['Date']>,
  endDate?: Maybe<Scalars['Date']>,
  reportReadStatus?: Maybe<ReportReadStatus>,
};

export type PostReportOrder = {
  field: PostReportOrderField,
  direction: OrderDirection,
};

export enum PostReportOrderField {
  LAST_REPORTED_AT = 'LAST_REPORTED_AT',
  ID = 'ID',
  DELETED_AT = 'DELETED_AT',
  REPORT_COUNT = 'REPORT_COUNT'
}

export type PostSearchFiltersInput = {
  query?: Maybe<Scalars['String']>,
  boardSlugs?: Maybe<Array<Scalars['String']>>,
  minimumShouldMatch?: Maybe<Scalars['String']>,
};

export enum PostSearchOrderField {
  VIEW_COUNT = 'VIEW_COUNT',
  CREATED_AT = 'CREATED_AT',
  RELEVANCE = 'RELEVANCE'
}

export type PostSearchOrderInput = {
  field: PostSearchOrderField,
  direction: OrderDirection,
};

export type PostSearchResult = {
   __typename?: 'PostSearchResult',
  score?: Maybe<Scalars['Float']>,
  source?: Maybe<Post>,
};

export type PostSearchResultConnection = {
   __typename?: 'PostSearchResultConnection',
  nodes?: Maybe<Array<Maybe<PostSearchResult>>>,
  totalCount: Scalars['Int'],
};

export enum PostStatus {
  PUBLIC = 'PUBLIC',
  TEMP = 'TEMP'
}

export type PostsUpdateInput = {
  postIds?: Maybe<Array<Scalars['ID']>>,
  isDelete: Scalars['Boolean'],
};

export type PostsUpdatePayload = {
   __typename?: 'PostsUpdatePayload',
  posts?: Maybe<Array<Maybe<Post>>>,
};

export type PostTrashFilters = {
  userID?: Maybe<Scalars['ID']>,
  nickname?: Maybe<Scalars['String']>,
  title?: Maybe<Scalars['String']>,
  startDate?: Maybe<Scalars['Date']>,
  endDate?: Maybe<Scalars['Date']>,
};

export type PostTrashOrder = {
  field: PostTrashOrderField,
  direction: OrderDirection,
};

export enum PostTrashOrderField {
  LAST_REPORTED_AT = 'LAST_REPORTED_AT',
  ID = 'ID',
  DELETED_AT = 'DELETED_AT'
}

export type PostUpdateInput = {
  title?: Maybe<Scalars['String']>,
  content?: Maybe<Scalars['String']>,
  contentText?: Maybe<Scalars['String']>,
  boardID?: Maybe<Scalars['Int']>,
  status?: Maybe<PostStatus>,
  commentStatus?: Maybe<PostCommentStatus>,
  notifyMessage?: Maybe<Scalars['Boolean']>,
  allowTrackback?: Maybe<Scalars['Boolean']>,
  isNotice?: Maybe<Scalars['Boolean']>,
  tags?: Maybe<Array<Scalars['String']>>,
  containsImage?: Maybe<Scalars['Boolean']>,
  containsYoutube?: Maybe<Scalars['Boolean']>,
  attachmentIDs?: Maybe<Array<Scalars['ID']>>,
  imageSrc?: Maybe<Scalars['String']>,
  youtubeSrc?: Maybe<Scalars['String']>,
  globalNotice?: Maybe<Scalars['Boolean']>,
  postCollectionIDs?: Maybe<Array<Scalars['ID']>>,
  tagIDs?: Maybe<Array<Scalars['ID']>>,
  updatedAt?: Maybe<Scalars['Date']>,
};

export type PostUpdateManyInput = {
  globalNotice?: Maybe<Scalars['Boolean']>,
};

export type PostUpdateManyPayload = {
   __typename?: 'PostUpdateManyPayload',
  posts: Array<Post>,
};

export type PostUpdatePayload = {
   __typename?: 'PostUpdatePayload',
  post?: Maybe<Post>,
};

export type Query = {
   __typename?: 'Query',
  mentorBoards: BoardConnection,
  viewerMentorComments: CommentConnection,
  viewerRecommendedMentorPosts: PostConnection,
  mentorRewardLogs: MentorRewardLogConnection,
  viewerMentorRewardLogs: MentorRewardLogConnection,
  mentors: UserConnection,
  organizationsAddedByAdmin: OrganizationConnection,
  organizationGroup?: Maybe<OrganizationGroup>,
  organizationGroups: OrganizationGroupConnection,
  mentorDutyByName?: Maybe<MentorDuty>,
  mentorDutiesByName: Array<MentorDuty>,
  searchMentorDuties: Array<MentorDuty>,
  mentorSchoolByName: Array<MentorSchool>,
  mentorRewardAmountSettings: RewardAmountSettings,
  mentorRewardAmountSettingsChangeLogs: RewardAmountSettingsChangeLogConnection,
  requestCashoutLogs: RequestCashoutLogConnection,
  mentorUserKpi: MentorUserKpiConnection,
  rewardKpi: RewardKpiConnection,
  currentAdByPlacementCode?: Maybe<Ad>,
  banLogs?: Maybe<BanLogConnection>,
  boards?: Maybe<BoardConnection>,
  boardsByID?: Maybe<Array<Maybe<Board>>>,
  boardBySlug?: Maybe<Board>,
  board?: Maybe<Board>,
  boardNavigation?: Maybe<BoardNavigation>,
  boardNavigations?: Maybe<BoardNavigationConnection>,
  bookmarks: BookmarkableConnection,
  bestPostRequirement?: Maybe<CollectionRequirement>,
  bestOfBestRequirement?: Maybe<CollectionRequirement>,
  comment?: Maybe<Comment>,
  comments?: Maybe<CommentConnection>,
  reportedComments?: Maybe<CommentConnection>,
  trashedComments?: Maybe<CommentConnection>,
  commentAbuseReportLogs: CommentAbuseReportLogConnection,
  config?: Maybe<Config>,
  configsAll: Array<Config>,
  group?: Maybe<Group>,
  groups: GroupConnection,
  viewerGroups?: Maybe<Array<Maybe<Group>>>,
  admin_menu?: Maybe<IMenu>,
  menus: MenuConnection,
  menu?: Maybe<Menu>,
  message?: Maybe<Message>,
  messages?: Maybe<MessageConnection>,
  messagesByUserId?: Maybe<MessageConnection>,
  messageUnreadCount: Scalars['Int'],
  notifications?: Maybe<NotificationConnection>,
  notificationsUnreadCount: Scalars['Int'],
  externalNotificationsUnreadCount: Scalars['Int'],
  posts?: Maybe<PostConnection>,
  postsByID?: Maybe<Array<Maybe<Post>>>,
  post?: Maybe<Post>,
  bestOfBestPostPreviews?: Maybe<PostConnection>,
  postSearch?: Maybe<PostSearchResultConnection>,
  bestPostList?: Maybe<BestPostListPayload>,
  reportedPosts?: Maybe<PostConnection>,
  trashedPosts?: Maybe<PostConnection>,
  postAbuseReportLogs: PostAbuseReportLogConnection,
  postCollections?: Maybe<PostCollectionConnection>,
  postCollectionsByID?: Maybe<Array<Maybe<PostCollection>>>,
  postCollection?: Maybe<PostCollection>,
  dailyPostByUsers?: Maybe<DailyPostByUserConnection>,
  dailyCommentByUsers?: Maybe<DailyCommentByUserConnection>,
  sitemap?: Maybe<Sitemap>,
  sitemaps: SitemapConnection,
  tag?: Maybe<Tag>,
  tags: TagConnection,
  tagsByID: Array<Tag>,
  trash: Trash,
  trashes: TrashConnection,
  oidcClientID: Scalars['String'],
  currentUser?: Maybe<User>,
  user?: Maybe<User>,
  users: UserConnection,
  usersByID: Array<User>,
  userByExternalId?: Maybe<User>,
  usersByExternalId: Array<Maybe<User>>,
  currentUserScrap?: Maybe<UserScrap>,
  currentUserScraps: CurrentUserScrapConnection,
  currentUserScrapsCount: Scalars['Int'],
};


export type QueryMentorBoardsArgs = {
  pagination?: Maybe<Pagination>
};


export type QueryViewerMentorCommentsArgs = {
  pagination?: Maybe<Pagination>
};


export type QueryViewerRecommendedMentorPostsArgs = {
  filterBy?: Maybe<ViewerRecommendedMentorPostFilters>,
  pagination?: Maybe<Pagination>
};


export type QueryMentorRewardLogsArgs = {
  filterBy?: Maybe<MentorRewardLogFilters>,
  pagination?: Maybe<Pagination>
};


export type QueryViewerMentorRewardLogsArgs = {
  pagination?: Maybe<Pagination>
};


export type QueryMentorsArgs = {
  filterBy?: Maybe<MentorUserFilters>,
  orderBy?: Maybe<MentorUserOrders>,
  pagination?: Maybe<Pagination>
};


export type QueryOrganizationsAddedByAdminArgs = {
  filterBy?: Maybe<OrganizationsAddedByAdminFiltersInput>,
  pagination?: Pagination
};


export type QueryOrganizationGroupArgs = {
  id: Scalars['ID']
};


export type QueryOrganizationGroupsArgs = {
  filterBy?: Maybe<OrganizationGroupFilters>
};


export type QueryMentorDutyByNameArgs = {
  name: Scalars['String']
};


export type QueryMentorDutiesByNameArgs = {
  name: Scalars['String']
};


export type QuerySearchMentorDutiesArgs = {
  filterBy: SearchMentorDutiesFilters
};


export type QueryMentorSchoolByNameArgs = {
  name: Scalars['String']
};


export type QueryMentorRewardAmountSettingsChangeLogsArgs = {
  pagination?: Maybe<Pagination>
};


export type QueryRequestCashoutLogsArgs = {
  filterBy?: Maybe<RequestCashoutLogFilters>,
  pagination?: Maybe<Pagination>
};


export type QueryMentorUserKpiArgs = {
  groupBy?: Maybe<MentorUserKpiGroups>,
  pagination?: Maybe<Pagination>,
  filterBy?: Maybe<MentorUserKpiFilters>
};


export type QueryRewardKpiArgs = {
  groupBy?: Maybe<RewardKpiGroups>,
  pagination?: Maybe<Pagination>,
  filterBy?: Maybe<RewardKpiFilters>
};


export type QueryCurrentAdByPlacementCodeArgs = {
  code: Scalars['String'],
  currentTime: Scalars['String']
};


export type QueryBanLogsArgs = {
  filterBy?: Maybe<BanLogsFilter>,
  orderBy?: Maybe<BanLogsOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryBoardsArgs = {
  query?: Maybe<Scalars['String']>,
  filterBy?: Maybe<BoardFiltersInput>,
  pagination?: Maybe<Pagination>
};


export type QueryBoardsByIdArgs = {
  ids: Array<Scalars['ID']>
};


export type QueryBoardBySlugArgs = {
  boardSlug: Scalars['String'],
  isNotice?: Maybe<Scalars['Boolean']>,
  isBest?: Maybe<Scalars['Boolean']>,
  boardTab?: Maybe<BoardTab>
};


export type QueryBoardArgs = {
  id: Scalars['ID']
};


export type QueryBoardNavigationArgs = {
  id: Scalars['ID']
};


export type QueryBoardNavigationsArgs = {
  filterBy?: Maybe<BoardNavigationFiltersInput>
};


export type QueryBookmarksArgs = {
  orderBy?: Maybe<BookmarkOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryCommentArgs = {
  id: Scalars['ID']
};


export type QueryCommentsArgs = {
  filterBy?: Maybe<CommentFiltersInput>,
  orderBy?: Maybe<CommentOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryReportedCommentsArgs = {
  filterBy?: Maybe<CommentReportFilters>,
  orderBy?: Maybe<CommentReportOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryTrashedCommentsArgs = {
  filterBy?: Maybe<CommentTrashFilters>,
  orderBy?: Maybe<CommentTrashOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryCommentAbuseReportLogsArgs = {
  filterBy?: Maybe<CommentAbuseReportLogFilters>,
  orderBy?: Maybe<CommentAbuseReportLogOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryConfigArgs = {
  id: Scalars['ID']
};


export type QueryGroupArgs = {
  id: Scalars['ID']
};


export type QueryGroupsArgs = {
  filterBy?: Maybe<GroupFilters>
};


export type QueryAdmin_MenuArgs = {
  id: Scalars['ID']
};


export type QueryMenusArgs = {
  filterBy?: Maybe<MenuFilters>
};


export type QueryMessageArgs = {
  id: Scalars['ID']
};


export type QueryMessagesArgs = {
  orderBy?: Maybe<MessageOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryMessagesByUserIdArgs = {
  userID: Scalars['ID'],
  filterBy?: Maybe<MessageFiltersInput>,
  orderBy?: Maybe<MessageOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryNotificationsArgs = {
  pagination?: Maybe<Pagination>
};


export type QueryPostsArgs = {
  query?: Maybe<Scalars['String']>,
  filterBy?: Maybe<PostFiltersInput>,
  orderBy?: Maybe<PostOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryPostsByIdArgs = {
  ids: Array<Scalars['ID']>
};


export type QueryPostArgs = {
  id: Scalars['ID'],
  permissionType?: Maybe<PermissionType>,
  fromBestList?: Maybe<Scalars['Boolean']>
};


export type QueryBestOfBestPostPreviewsArgs = {
  filterBy?: Maybe<PostFiltersInput>,
  orderBy?: Maybe<PostOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryPostSearchArgs = {
  filterBy: PostSearchFiltersInput,
  orderBy?: Maybe<PostSearchOrderInput>,
  pagination?: Maybe<Pagination>
};


export type QueryBestPostListArgs = {
  input: BestPostListInput
};


export type QueryReportedPostsArgs = {
  filterBy?: Maybe<PostReportFilters>,
  orderBy?: Maybe<PostReportOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryTrashedPostsArgs = {
  filterBy?: Maybe<PostTrashFilters>,
  orderBy?: Maybe<PostTrashOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryPostAbuseReportLogsArgs = {
  filterBy?: Maybe<PostAbuseReportLogFilters>,
  orderBy?: Maybe<PostAbuseReportLogOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryPostCollectionsArgs = {
  query?: Maybe<Scalars['String']>,
  filterBy?: Maybe<PostCollectionFiltersInput>,
  orderBy?: Maybe<PostCollectionOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryPostCollectionsByIdArgs = {
  ids: Array<Scalars['ID']>
};


export type QueryPostCollectionArgs = {
  id: Scalars['ID']
};


export type QueryDailyPostByUsersArgs = {
  filterBy?: Maybe<DailyPostByUserFilters>,
  orderBy?: Maybe<DailyPostByUserOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryDailyCommentByUsersArgs = {
  filterBy?: Maybe<DailyCommentByUserFilters>,
  orderBy?: Maybe<DailyCommentByUserOrder>,
  pagination?: Maybe<Pagination>
};


export type QuerySitemapArgs = {
  id: Scalars['ID']
};


export type QuerySitemapsArgs = {
  filterBy?: Maybe<SitemapFiltersInput>
};


export type QueryTagArgs = {
  id: Scalars['ID']
};


export type QueryTagsArgs = {
  filterBy?: Maybe<TagFilters>,
  orderBy?: Maybe<TagOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryTagsByIdArgs = {
  ids: Array<Scalars['ID']>
};


export type QueryTrashArgs = {
  id: Scalars['ID']
};


export type QueryTrashesArgs = {
  pagination?: Maybe<Pagination>
};


export type QueryUserArgs = {
  id: Scalars['ID']
};


export type QueryUsersArgs = {
  filterBy?: Maybe<UserFilters>,
  orderBy?: Maybe<UserOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryUsersByIdArgs = {
  ids: Array<Scalars['ID']>
};


export type QueryUserByExternalIdArgs = {
  id: Scalars['ID']
};


export type QueryUsersByExternalIdArgs = {
  ids: Array<Scalars['ID']>
};


export type QueryCurrentUserScrapArgs = {
  postID: Scalars['ID']
};


export type QueryCurrentUserScrapsArgs = {
  filterBy?: Maybe<CurrentUserScrapsFilter>,
  orderBy?: Maybe<UserScrapOrder>,
  pagination?: Maybe<Pagination>
};


export type QueryCurrentUserScrapsCountArgs = {
  filterBy: CurrentUserScrapsFilter
};

export type Range = {
   __typename?: 'Range',
  gte?: Maybe<Scalars['Int']>,
};

export type RangeInput = {
  gte?: Maybe<Scalars['Int']>,
};

export enum RatingType {
  DISLIKE = 'DISLIKE',
  CANCEL = 'CANCEL',
  LIKE = 'LIKE'
}

export type RemoveBookmarkPayload = {
   __typename?: 'RemoveBookmarkPayload',
  menu?: Maybe<Menu>,
  bookmarkable?: Maybe<Bookmarkable>,
};

export type ReportCommentInput = {
  commentID: Scalars['ID'],
  description?: Maybe<Scalars['String']>,
};

export type ReportCommentPayload = {
   __typename?: 'ReportCommentPayload',
  comment?: Maybe<Comment>,
};

export type ReportPostInput = {
  postID: Scalars['ID'],
  description?: Maybe<Scalars['String']>,
};

export type ReportPostPayload = {
   __typename?: 'ReportPostPayload',
  post?: Maybe<Post>,
};

export enum ReportReadStatus {
  READ = 'READ',
  UNREAD = 'UNREAD'
}

export type RequestCashoutInput = {
  amount: Scalars['Int'],
  realName: Scalars['String'],
  phoneNumber: Scalars['String'],
  bank: Bank,
  bankAccountNumber: Scalars['String'],
};

export type RequestCashoutLog = {
   __typename?: 'RequestCashoutLog',
  id: Scalars['ID'],
  amount: Scalars['Int'],
  realName: Scalars['String'],
  phoneNumber: Scalars['String'],
  bank: Bank,
  bankAccountNumber: Scalars['String'],
  createdAt: Scalars['Date'],
  user?: Maybe<User>,
};

export type RequestCashoutLogConnection = {
   __typename?: 'RequestCashoutLogConnection',
  nodes: Array<RequestCashoutLog>,
  totalCount: Scalars['Int'],
};

export type RequestCashoutLogFilters = {
  dateRange?: Maybe<DateRange>,
  query?: Maybe<Scalars['String']>,
};

export type RequestCashoutPayload = {
   __typename?: 'RequestCashoutPayload',
  requestCashoutLog: RequestCashoutLog,
};

export type RestoreMentorPostPayload = {
   __typename?: 'RestoreMentorPostPayload',
  post: Post,
};

export type RestorePostPayload = {
   __typename?: 'RestorePostPayload',
  post?: Maybe<Post>,
};

export type RewardAmountSettings = {
   __typename?: 'RewardAmountSettings',
  id: Scalars['ID'],
  forOrganizationNameMatchedComment: Scalars['Int'],
  forDutyNameMatchedComment: Scalars['Int'],
  forSchoolNameMatchedComment: Scalars['Int'],
  forAcceptedComment: Scalars['Int'],
  createdAt: Scalars['Date'],
  updatedAt?: Maybe<Scalars['Date']>,
};

export type RewardAmountSettingsChangeLog = {
   __typename?: 'RewardAmountSettingsChangeLog',
  id: Scalars['ID'],
  rewardReason: RewardReason,
  beforeAmount: Scalars['Int'],
  afterAmount: Scalars['Int'],
  createdAt: Scalars['Date'],
};

export type RewardAmountSettingsChangeLogConnection = {
   __typename?: 'RewardAmountSettingsChangeLogConnection',
  nodes: Array<RewardAmountSettingsChangeLog>,
  totalCount: Scalars['Int'],
};

export type RewardKpi = {
   __typename?: 'RewardKpi',
  id: Scalars['ID'],
  date: Scalars['Date'],
  totalRewardAmountForOrgNameMatchedComments: Scalars['Int'],
  totalRewardAmountForDutyNameMatchedComments: Scalars['Int'],
  totalRewardAmountForSchoolNameMatchedComments: Scalars['Int'],
  totalRewardAmountForAcceptedComments: Scalars['Int'],
  totalRewardAmount: Scalars['Int'],
  totalCashoutRequestAmount: Scalars['Int'],
  createdAt: Scalars['Date'],
};

export type RewardKpiConnection = {
   __typename?: 'RewardKpiConnection',
  nodes: Array<RewardKpi>,
  totalCount: Scalars['Int'],
};

export type RewardKpiFilters = {
  dateRange: DateRange,
};

export type RewardKpiGroups = {
  period: Period,
};

export enum RewardReason {
  ORGANIZATION_NAME_MATCHED_COMMENT = 'ORGANIZATION_NAME_MATCHED_COMMENT',
  DUTY_NAME_MATCHED_COMMENT = 'DUTY_NAME_MATCHED_COMMENT',
  SCHOOL_NAME_MATCHED_COMMENT = 'SCHOOL_NAME_MATCHED_COMMENT',
  ACCEPTED_COMMENT = 'ACCEPTED_COMMENT',
  REQUEST_CASHOUT = 'REQUEST_CASHOUT',
  MONTHLY_POINT_EXPIRES = 'MONTHLY_POINT_EXPIRES'
}

export enum SearchMentorAuthenticationStatus {
  PENDING = 'PENDING',
  UPDATE_IN_REVIEW = 'UPDATE_IN_REVIEW',
  PENDING_OR_UPDATE_IN_REVIEW = 'PENDING_OR_UPDATE_IN_REVIEW',
  APPROVED_OR_REJECTED = 'APPROVED_OR_REJECTED'
}

export type SearchMentorDutiesFilters = {
  name: Scalars['String'],
  retrieveLastDepthData?: Maybe<Scalars['Boolean']>,
};

export type SearchPostFromBoardFilters = {
  word?: Maybe<Scalars['String']>,
  field?: Maybe<SearchPostFromBoardWordField>,
  organizationName?: Maybe<Scalars['String']>,
  dutyName?: Maybe<Scalars['String']>,
  dutyId?: Maybe<Scalars['ID']>,
  schoolName?: Maybe<Scalars['String']>,
};

export enum SearchPostFromBoardWordField {
  TITLE = 'TITLE',
  CONTENT = 'CONTENT',
  TITLE_WITH_CONTENT = 'TITLE_WITH_CONTENT',
  NICK_NAME = 'NICK_NAME'
}

export type ShortcutMenu = IMenu & {
   __typename?: 'ShortcutMenu',
  children: Array<IMenu>,
  description?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  listOrder: Scalars['String'],
  parent?: Maybe<IMenu>,
  permissionViewMenuGroups: Array<Group>,
  sitemap?: Maybe<Sitemap>,
  title?: Maybe<Scalars['String']>,
  url?: Maybe<Scalars['String']>,
};

export type ShortcutMenuCreateInput = {
  description?: Maybe<Scalars['String']>,
  parentID?: Maybe<Scalars['ID']>,
  sitemapID?: Maybe<Scalars['ID']>,
  title: Scalars['String'],
  slug: Scalars['String'],
};

export type ShortcutMenuCreatePayload = {
   __typename?: 'ShortcutMenuCreatePayload',
  menu: ShortcutMenu,
};

export type ShortcutMenuUpdateInput = {
  description?: Maybe<Scalars['String']>,
  parentID?: Maybe<Scalars['ID']>,
  sitemapID?: Maybe<Scalars['ID']>,
  title?: Maybe<Scalars['String']>,
  slug?: Maybe<Scalars['String']>,
  permissionViewMenuGroupIDs?: Maybe<Array<Scalars['ID']>>,
};

export type ShortcutMenuUpdatePayload = {
   __typename?: 'ShortcutMenuUpdatePayload',
  menu?: Maybe<ShortcutMenu>,
};

export type SimpleMenu = IMenu & {
   __typename?: 'SimpleMenu',
  children: Array<IMenu>,
  description?: Maybe<Scalars['String']>,
  id: Scalars['ID'],
  listOrder: Scalars['String'],
  parent?: Maybe<IMenu>,
  permissionViewMenuGroups: Array<Group>,
  sitemap?: Maybe<Sitemap>,
  title?: Maybe<Scalars['String']>,
};

export type SimpleMenuCreateInput = {
  description?: Maybe<Scalars['String']>,
  parentID?: Maybe<Scalars['ID']>,
  sitemapID?: Maybe<Scalars['ID']>,
  title: Scalars['String'],
};

export type SimpleMenuCreatePayload = {
   __typename?: 'SimpleMenuCreatePayload',
  menu: SimpleMenu,
};

export type SimpleMenuUpdateInput = {
  description?: Maybe<Scalars['String']>,
  parentID?: Maybe<Scalars['ID']>,
  sitemapID?: Maybe<Scalars['ID']>,
  title?: Maybe<Scalars['String']>,
  permissionViewMenuGroupIDs?: Maybe<Array<Scalars['ID']>>,
};

export type SimpleMenuUpdatePayload = {
   __typename?: 'SimpleMenuUpdatePayload',
  menu?: Maybe<SimpleMenu>,
};

export type Sitemap = {
   __typename?: 'Sitemap',
  children: Array<IMenu>,
  id: Scalars['ID'],
  listOrder: Scalars['String'],
  title?: Maybe<Scalars['String']>,
};

export type SitemapConnection = {
   __typename?: 'SitemapConnection',
  nodes: Array<Sitemap>,
  totalCount: Scalars['Int'],
};

export type SitemapCreateInput = {
  title: Scalars['String'],
};

export type SitemapCreatePayload = {
   __typename?: 'SitemapCreatePayload',
  sitemap: Sitemap,
};

export type SitemapDeletePayload = {
   __typename?: 'SitemapDeletePayload',
  sitemap?: Maybe<Sitemap>,
};

export type SitemapFiltersInput = {
  q?: Maybe<Scalars['String']>,
};

export enum SitemapMoveDirection {
  DOWN = 'DOWN',
  UP = 'UP'
}

export type SitemapMovePayload = {
   __typename?: 'SitemapMovePayload',
  sitemap?: Maybe<Sitemap>,
  swappedSitemap?: Maybe<Sitemap>,
};

export type SitemapUpdateInput = {
  title?: Maybe<Scalars['String']>,
};

export type SitemapUpdatePayload = {
   __typename?: 'SitemapUpdatePayload',
  sitemap?: Maybe<Sitemap>,
};

export type Tag = {
   __typename?: 'Tag',
  id: Scalars['ID'],
  name: Scalars['String'],
  createdAt: Scalars['Date'],
  updatedAt: Scalars['Date'],
};

export type TagConnection = {
   __typename?: 'TagConnection',
  nodes: Array<Tag>,
  totalCount: Scalars['Int'],
};

export type TagCreatePayload = {
   __typename?: 'TagCreatePayload',
  tag?: Maybe<Tag>,
};

export type TagDeleteManyPayload = {
   __typename?: 'TagDeleteManyPayload',
  tags: Array<Tag>,
};

export type TagDeletePayload = {
   __typename?: 'TagDeletePayload',
  tag?: Maybe<Tag>,
};

export type TagFilters = {
  q?: Maybe<Scalars['String']>,
};

export type TagInput = {
  name?: Maybe<Scalars['String']>,
};

export type TagOrder = {
  field: TagOrderField,
  direction: OrderDirection,
};

export enum TagOrderField {
  ID = 'ID',
  NAME = 'NAME',
  CREATED_AT = 'CREATED_AT',
  UPDATED_AT = 'UPDATED_AT'
}

export type TagUpdatePayload = {
   __typename?: 'TagUpdatePayload',
  tag?: Maybe<Tag>,
};

export type Trash = {
   __typename?: 'Trash',
  id: Scalars['ID'],
  title?: Maybe<Scalars['String']>,
  originModule?: Maybe<Scalars['String']>,
  serializedObject?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  ipAddrress?: Maybe<Scalars['String']>,
  userID?: Maybe<Scalars['Int']>,
  createdAt: Scalars['Date'],
};

export type TrashConnection = {
   __typename?: 'TrashConnection',
  nodes: Array<Trash>,
  totalCount: Scalars['Int'],
};

export type TrashCreatePayload = {
   __typename?: 'TrashCreatePayload',
  trash?: Maybe<Trash>,
};

export type TrashInput = {
  title: Scalars['String'],
  originModule?: Maybe<Scalars['String']>,
  serializedObject?: Maybe<Scalars['String']>,
  description?: Maybe<Scalars['String']>,
  ipAddrress?: Maybe<Scalars['String']>,
  userID?: Maybe<Scalars['Int']>,
  createdAt: Scalars['Date'],
};

export type UnbanLogsPayLoad = {
   __typename?: 'UnbanLogsPayLoad',
  unbanLogs?: Maybe<Array<Maybe<BanLog>>>,
};

export type UpdateMentorBoardInput = {
  id: Scalars['ID'],
  menteeAcceptableCommentCount?: Maybe<Scalars['Int']>,
};

export type UpdateMentorBoardsInput = {
  updateMentorBoardInputs: Array<UpdateMentorBoardInput>,
};

export type UpdateMentorBoardsPayload = {
   __typename?: 'UpdateMentorBoardsPayload',
  boards: Array<Board>,
};

export type UpdateMentorPostInput = {
  id: Scalars['ID'],
  boardId?: Maybe<Scalars['ID']>,
  title?: Maybe<Scalars['String']>,
  organizationName?: Maybe<Scalars['String']>,
  dutyName?: Maybe<Scalars['String']>,
  schoolName?: Maybe<Scalars['String']>,
  dutyId?: Maybe<Scalars['ID']>,
  content?: Maybe<Scalars['String']>,
  contentText?: Maybe<Scalars['String']>,
  status?: Maybe<PostStatus>,
  commentStatus?: Maybe<PostCommentStatus>,
  notifyMessage?: Maybe<Scalars['Boolean']>,
  allowTrackback?: Maybe<Scalars['Boolean']>,
  isNotice?: Maybe<Scalars['Boolean']>,
  tags?: Maybe<Array<Scalars['String']>>,
  containsImage?: Maybe<Scalars['Boolean']>,
  containsYoutube?: Maybe<Scalars['Boolean']>,
  attachmentIDs?: Maybe<Array<Scalars['String']>>,
  imageSrc?: Maybe<Scalars['String']>,
  youtubeSrc?: Maybe<Scalars['String']>,
  globalNotice?: Maybe<Scalars['Boolean']>,
  postCollectionIDs?: Maybe<Array<Scalars['String']>>,
  tagIDs?: Maybe<Array<Scalars['String']>>,
  updatedAt?: Maybe<Scalars['Date']>,
  dutyIds?: Maybe<Array<Scalars['ID']>>,
  schoolIds?: Maybe<Array<Scalars['ID']>>,
  organizationIds?: Maybe<Array<Scalars['ID']>>,
};

export type UpdateMentorPostPayload = {
   __typename?: 'UpdateMentorPostPayload',
  post: Post,
};

export type UpdateMentorPostsInput = {
  ids: Array<Scalars['ID']>,
  boardId?: Maybe<Scalars['ID']>,
  isDelete: Scalars['Boolean'],
};

export type UpdateMentorPostsPayload = {
   __typename?: 'UpdateMentorPostsPayload',
  posts: Array<Post>,
};

export type UpdateMentorUserStatusInput = {
  id: Scalars['ID'],
  mentorAuthenticationStatus?: Maybe<MentorAuthenticationStatus>,
  mentorRejectionReason?: Maybe<Scalars['String']>,
  mentorRevokeReason?: Maybe<Scalars['String']>,
  organizationNameInReview?: Maybe<Scalars['String']>,
  dutyIdInReview?: Maybe<Scalars['ID']>,
  schoolNameInReview?: Maybe<Scalars['String']>,
  phoneNumberInReview?: Maybe<Scalars['String']>,
};

export type UpdateMentorUserStatusPayload = {
   __typename?: 'UpdateMentorUserStatusPayload',
  user: User,
};

export type UpdateOrganizationGroupInput = {
  id: Scalars['ID'],
  organizationIds: Array<Scalars['ID']>,
};

export type UpdateOrganizationGroupPayload = {
   __typename?: 'UpdateOrganizationGroupPayload',
  organizationGroup: OrganizationGroup,
};

export type UpdateRewardAmountSettingsInput = {
  forOrganizationNameMatchedComment?: Maybe<Scalars['Int']>,
  forDutyNameMatchedComment?: Maybe<Scalars['Int']>,
  forSchoolNameMatchedComment?: Maybe<Scalars['Int']>,
  forAcceptedComment?: Maybe<Scalars['Int']>,
};

export type UpdateRewardAmountSettingsPayload = {
   __typename?: 'UpdateRewardAmountSettingsPayload',
  rewardAmountSettings: RewardAmountSettings,
};

export type UpdateUserInput = {
  profileURL: Scalars['String'],
};

export type User = {
   __typename?: 'User',
  id: Scalars['ID'],
  phoneNumber?: Maybe<Scalars['String']>,
  organizationName?: Maybe<Scalars['String']>,
  schoolName?: Maybe<Scalars['String']>,
  phoneNumberInReview?: Maybe<Scalars['String']>,
  organizationNameInReview?: Maybe<Scalars['String']>,
  schoolNameInReview?: Maybe<Scalars['String']>,
  mentorAuthenticationMethod?: Maybe<MentorAuthenticationMethod>,
  mentorAuthenticationRequestedAt?: Maybe<Scalars['Date']>,
  mentorAuthenticationStatus?: Maybe<MentorAuthenticationStatus>,
  mentorApprovedAt?: Maybe<Scalars['Date']>,
  mentorRejectedAt?: Maybe<Scalars['Date']>,
  mentorRejectionReason?: Maybe<Scalars['String']>,
  mentorRevokedAt?: Maybe<Scalars['Date']>,
  mentorRevokeReason?: Maybe<Scalars['String']>,
  isMentorPending: Scalars['Boolean'],
  isMentorUpdateInReview: Scalars['Boolean'],
  dutyName?: Maybe<Scalars['String']>,
  duty?: Maybe<MentorDuty>,
  dutyNameInReview?: Maybe<Scalars['String']>,
  dutyInReview?: Maybe<MentorDuty>,
  bankInformation?: Maybe<BankInformation>,
  mentorAuthenticationEmail?: Maybe<Scalars['String']>,
  mentorAuthenticationDocuments: MentorDocumentConnection,
  monthlyExpiringRewardAmount: Scalars['Int'],
  monthlyRequestedCashoutAmount: Scalars['Int'],
  monthlyRequestCashoutLimitAmount: Scalars['Int'],
  rewardLogs: MentorRewardLogConnection,
  mentorComments: CommentConnection,
  weeklyMentorCommentAcceptedRate: Scalars['Int'],
  mentorCommentAcceptedRate: Scalars['Int'],
  totalCommentCount: Scalars['Int'],
  acceptedMentorCommentCount: Scalars['Int'],
  rewardAmount: Scalars['Int'],
  nickname?: Maybe<Scalars['String']>,
  username?: Maybe<Scalars['String']>,
  email?: Maybe<Scalars['String']>,
  posts?: Maybe<PostConnection>,
  isAdmin?: Maybe<Scalars['Boolean']>,
  allowMessage?: Maybe<Scalars['Boolean']>,
  profileImageURL?: Maybe<Scalars['String']>,
  banLog?: Maybe<BanLogConnection>,
  lastBanLog?: Maybe<BanLog>,
  externalID?: Maybe<Scalars['ID']>,
  scrapCount?: Maybe<Scalars['Int']>,
};


export type UserRewardLogsArgs = {
  pagination?: Maybe<Pagination>,
  filterBy?: Maybe<MentorRewardLogFilters>
};


export type UserMentorCommentsArgs = {
  filterBy?: Maybe<MentorCommentsFilters>,
  pagination?: Maybe<Pagination>
};


export type UserPostsArgs = {
  orderBy?: Maybe<PostOrder>,
  pagination?: Maybe<Pagination>
};

export type UserConnection = {
   __typename?: 'UserConnection',
  nodes: Array<User>,
  totalCount: Scalars['Int'],
};

export type UserFilters = {
  q?: Maybe<Scalars['String']>,
};

export type UserOrder = {
  field: UserOrderField,
  direction: OrderDirection,
};

export enum UserOrderField {
  ID = 'ID'
}

export type UserScrap = {
   __typename?: 'UserScrap',
  id: Scalars['ID'],
  userID: Scalars['ID'],
  postID: Scalars['ID'],
  externalID?: Maybe<Scalars['String']>,
  listOrder?: Maybe<Scalars['Int']>,
  createdAt?: Maybe<Scalars['Date']>,
  deletedAt?: Maybe<Scalars['Date']>,
  post?: Maybe<Post>,
};

export type UserScrapOrder = {
  field: UserScrapOrderField,
  direction: OrderDirection,
};

export enum UserScrapOrderField {
  LIST_ORDER = 'LIST_ORDER',
  CREATED_AT = 'CREATED_AT'
}

export type ViewerRecommendedMentorPostFilters = {
  matchOrganizationName?: Maybe<Scalars['Boolean']>,
  matchDutyName?: Maybe<Scalars['Boolean']>,
  matchSchoolName?: Maybe<Scalars['Boolean']>,
};

export type TrashedCommentsQueryVariables = {
  filterBy?: Maybe<CommentTrashFilters>,
  orderBy?: Maybe<CommentTrashOrder>,
  pagination?: Maybe<Pagination>
};


export type TrashedCommentsQuery = (
  { __typename?: 'Query' }
  & { trashedComments: Maybe<(
    { __typename?: 'CommentConnection' }
    & Pick<CommentConnection, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'Comment' }
      & Pick<Comment, 'id' | 'contentTextForAdmin' | 'nickname' | 'deletedAt'>
      & { author: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
      )>, board: Maybe<(
        { __typename?: 'Board' }
        & Pick<Board, 'title'>
      )> }
    )> }
  )> }
);

export type TrashedPostsQueryVariables = {
  filterBy?: Maybe<PostTrashFilters>,
  orderBy?: Maybe<PostTrashOrder>,
  pagination?: Maybe<Pagination>
};


export type TrashedPostsQuery = (
  { __typename?: 'Query' }
  & { trashedPosts: Maybe<(
    { __typename?: 'PostConnection' }
    & Pick<PostConnection, 'totalCount'>
    & { nodes: Maybe<Array<(
      { __typename?: 'Post' }
      & Pick<Post, 'id' | 'nickname' | 'title' | 'deletedAt'>
      & { author: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
      )>, board: Maybe<(
        { __typename?: 'Board' }
        & Pick<Board, 'title'>
      )> }
    )>> }
  )> }
);

export type PostDeleteReportDetailQueryVariables = {
  postId: Scalars['ID'],
  filterBy?: Maybe<PostAbuseReportLogFilters>,
  pagination?: Maybe<Pagination>
};


export type PostDeleteReportDetailQuery = (
  { __typename?: 'Query' }
  & { post: Maybe<(
    { __typename?: 'Post' }
    & Pick<Post, 'id' | 'nickname' | 'title' | 'reportCount'>
    & { author: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>, reportCountByDescription: Maybe<Array<(
      { __typename?: 'PostAbuseReportLogCountByDescription' }
      & Pick<PostAbuseReportLogCountByDescription, 'description' | 'count'>
    )>> }
  )>, postAbuseReportLogs: (
    { __typename?: 'PostAbuseReportLogConnection' }
    & Pick<PostAbuseReportLogConnection, 'totalCount'>
    & { nodes: Maybe<Array<(
      { __typename?: 'PostAbuseReportLog' }
      & Pick<PostAbuseReportLog, 'id' | 'createdAt' | 'description'>
      & { reporter: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'nickname'>
      )> }
    )>> }
  ) }
);

export type CommentDeleteMutationVariables = {
  commentDeleteId: Scalars['ID']
};


export type CommentDeleteMutation = (
  { __typename?: 'Mutation' }
  & { commentDelete: Maybe<(
    { __typename?: 'CommentDeletePayload' }
    & Pick<CommentDeletePayload, 'deletedCommentID'>
    & { post: Maybe<(
      { __typename?: 'Post' }
      & Pick<Post, 'title'>
    )> }
  )> }
);

export type ReadCommentAbuseReportLogMutationVariables = {
  readCommentAbuseReportLogId: Scalars['ID']
};


export type ReadCommentAbuseReportLogMutation = (
  { __typename?: 'Mutation' }
  & { readCommentAbuseReportLog: Maybe<(
    { __typename?: 'CommentAbuseReportLogUpdatePayload' }
    & { commentAbuseReportLog: Maybe<(
      { __typename?: 'CommentAbuseReportLog' }
      & Pick<CommentAbuseReportLog, 'id'>
      & { comment: Maybe<(
        { __typename?: 'Comment' }
        & Pick<Comment, 'contentText'>
      )> }
    )> }
  )> }
);

export type ReadAllCommentAbuseReportLogMutationVariables = {
  commentId: Scalars['ID']
};


export type ReadAllCommentAbuseReportLogMutation = (
  { __typename?: 'Mutation' }
  & { readAllCommentAbuseReportLog: Maybe<(
    { __typename?: 'CommentAbuseReportLogBulkUpdatePayload' }
    & Pick<CommentAbuseReportLogBulkUpdatePayload, 'updatedCommentAbuseReportLogs'>
  )> }
);

export type ReportedCommentsQueryVariables = {
  filterBy?: Maybe<CommentReportFilters>,
  orderBy?: Maybe<CommentReportOrder>,
  pagination?: Maybe<Pagination>
};


export type ReportedCommentsQuery = (
  { __typename?: 'Query' }
  & { reportedComments: Maybe<(
    { __typename?: 'CommentConnection' }
    & Pick<CommentConnection, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'Comment' }
      & Pick<Comment, 'id' | 'nickname' | 'originContentText' | 'reportDescription' | 'lastReportedAt' | 'reportCount' | 'ipaddress'>
      & { author: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
      )> }
    )> }
  )> }
);

export type CommentsQueryVariables = {
  commentId: Scalars['ID'],
  filterBy?: Maybe<CommentAbuseReportLogFilters>,
  pagination?: Maybe<Pagination>
};


export type CommentsQuery = (
  { __typename?: 'Query' }
  & { comment: Maybe<(
    { __typename?: 'Comment' }
    & Pick<Comment, 'originContentText' | 'nickname' | 'reportCount' | 'deletedAt'>
    & { post: Maybe<(
      { __typename?: 'Post' }
      & Pick<Post, 'id'>
    )>, author: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>, reportCountByDescription: Maybe<Array<(
      { __typename?: 'CommetAbuseReportLogCountByDescription' }
      & Pick<CommetAbuseReportLogCountByDescription, 'description' | 'count'>
    )>> }
  )>, commentAbuseReportLogs: (
    { __typename?: 'CommentAbuseReportLogConnection' }
    & Pick<CommentAbuseReportLogConnection, 'totalCount'>
    & { nodes: Maybe<Array<(
      { __typename?: 'CommentAbuseReportLog' }
      & Pick<CommentAbuseReportLog, 'id' | 'createdAt' | 'description' | 'adminReadAt'>
      & { reporter: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'nickname' | 'id'>
      )> }
    )>> }
  ) }
);

export type PostDeleteByReportedMutationVariables = {
  postDeleteId: Scalars['ID']
};


export type PostDeleteByReportedMutation = (
  { __typename?: 'Mutation' }
  & { postDelete: Maybe<(
    { __typename?: 'PostDeletePayload' }
    & Pick<PostDeletePayload, 'deletedPostID'>
    & { post: Maybe<(
      { __typename?: 'Post' }
      & Pick<Post, 'id'>
    )> }
  )> }
);

export type ReadPostAbuseReportLogMutationVariables = {
  readPostAbuseReportLogId: Scalars['ID']
};


export type ReadPostAbuseReportLogMutation = (
  { __typename?: 'Mutation' }
  & { readPostAbuseReportLog: Maybe<(
    { __typename?: 'PostAbuseReportLogUpdatePayload' }
    & { postAbuseReportLog: Maybe<(
      { __typename?: 'PostAbuseReportLog' }
      & Pick<PostAbuseReportLog, 'id'>
    )> }
  )> }
);

export type ReadAllPostAbuseReportLogMutationVariables = {
  postId: Scalars['ID']
};


export type ReadAllPostAbuseReportLogMutation = (
  { __typename?: 'Mutation' }
  & { readAllPostAbuseReportLog: Maybe<(
    { __typename?: 'PostAbuseReportLogBulkUpdatePayload' }
    & Pick<PostAbuseReportLogBulkUpdatePayload, 'updatedPostAbuseReportLogs'>
  )> }
);

export type BanUserMutationVariables = {
  input: BanLogInput
};


export type BanUserMutation = (
  { __typename?: 'Mutation' }
  & { banUser: Maybe<(
    { __typename?: 'BanLog' }
    & Pick<BanLog, 'id'>
  )> }
);

export type ReportedPostsQueryVariables = {
  pagination?: Maybe<Pagination>,
  orderBy?: Maybe<PostReportOrder>,
  filterBy?: Maybe<PostReportFilters>
};


export type ReportedPostsQuery = (
  { __typename?: 'Query' }
  & { reportedPosts: Maybe<(
    { __typename?: 'PostConnection' }
    & Pick<PostConnection, 'totalCount'>
    & { nodes: Maybe<Array<(
      { __typename?: 'Post' }
      & Pick<Post, 'id' | 'nickname' | 'title' | 'lastReportedAt' | 'reportCount' | 'ipaddress' | 'hasUnreadReport' | 'reportDescription' | 'deletedAt'>
      & { author: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
      )> }
    )>> }
  )> }
);

export type PostQueryVariables = {
  filterBy?: Maybe<PostAbuseReportLogFilters>,
  pagination?: Maybe<Pagination>,
  postId: Scalars['ID']
};


export type PostQuery = (
  { __typename?: 'Query' }
  & { post: Maybe<(
    { __typename?: 'Post' }
    & Pick<Post, 'id' | 'title' | 'nickname' | 'reportCount' | 'deletedAt'>
    & { author: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )>, reportCountByDescription: Maybe<Array<(
      { __typename?: 'PostAbuseReportLogCountByDescription' }
      & Pick<PostAbuseReportLogCountByDescription, 'description' | 'count'>
    )>> }
  )>, postAbuseReportLogs: (
    { __typename?: 'PostAbuseReportLogConnection' }
    & Pick<PostAbuseReportLogConnection, 'totalCount'>
    & { nodes: Maybe<Array<(
      { __typename?: 'PostAbuseReportLog' }
      & Pick<PostAbuseReportLog, 'id' | 'description' | 'adminReadAt' | 'createdAt'>
      & { reporter: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'nickname'>
      )> }
    )>> }
  ) }
);

export type UnbanUsersMutationVariables = {
  logIDs: Array<Scalars['ID']>
};


export type UnbanUsersMutation = (
  { __typename?: 'Mutation' }
  & { unbanUsers: Maybe<(
    { __typename?: 'UnbanLogsPayLoad' }
    & { unbanLogs: Maybe<Array<Maybe<(
      { __typename?: 'BanLog' }
      & Pick<BanLog, 'id' | 'description' | 'startAt' | 'endAt' | 'deletedAt'>
      & { user: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'nickname' | 'username'>
      )> }
    )>>> }
  )> }
);

export type BanLogsQueryVariables = {
  orderBy?: Maybe<BanLogsOrder>,
  pagination?: Maybe<Pagination>,
  filterBy?: Maybe<BanLogsFilter>
};


export type BanLogsQuery = (
  { __typename?: 'Query' }
  & { banLogs: Maybe<(
    { __typename?: 'BanLogConnection' }
    & Pick<BanLogConnection, 'totalCount'>
    & { nodes: Maybe<Array<(
      { __typename?: 'BanLog' }
      & Pick<BanLog, 'id' | 'description' | 'startAt' | 'endAt' | 'deletedAt'>
      & { user: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'nickname' | 'username'>
      )> }
    )>> }
  )> }
);

export type ConfigFragmentFragment = (
  { __typename?: 'Config' }
  & Pick<Config, 'id' | 'name' | 'description' | 'value' | 'defaultValue'>
);

export type ConfigUpdateMutationVariables = {
  id: Scalars['ID'],
  value?: Maybe<Scalars['String']>
};


export type ConfigUpdateMutation = (
  { __typename?: 'Mutation' }
  & { configUpdate: Maybe<(
    { __typename?: 'Config' }
    & ConfigFragmentFragment
  )> }
);

export type ConfigResetMutationVariables = {
  id: Scalars['ID']
};


export type ConfigResetMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'configReset'>
);

export type BoardNavigationFragmentFragment = (
  { __typename?: 'BoardNavigation' }
  & Pick<BoardNavigation, 'id' | 'name'>
  & { board: Maybe<(
    { __typename?: 'Board' }
    & BoardFragmentFragment
  )> }
);

export type BoardNavigationGetListQueryVariables = {
  filterBy?: Maybe<BoardNavigationFiltersInput>
};


export type BoardNavigationGetListQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'BoardNavigationConnection' }
    & { total: BoardNavigationConnection['totalCount'] }
    & { data: Array<(
      { __typename?: 'BoardNavigation' }
      & BoardNavigationFragmentFragment
    )> }
  )> }
);

export type BoardNavigationGetQueryVariables = {
  id: Scalars['ID']
};


export type BoardNavigationGetQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'BoardNavigation' }
    & BoardNavigationFragmentFragment
  )> }
);

export type BoardNavigationUpdateMutationVariables = {
  id: Scalars['ID'],
  input: BoardNavigationUpdateInput
};


export type BoardNavigationUpdateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'BoardNavigationUpdatePayload' }
    & { data: Maybe<(
      { __typename?: 'BoardNavigation' }
      & BoardNavigationFragmentFragment
    )> }
  )> }
);

export type BoardFragmentFragment = (
  { __typename?: 'Board' }
  & Pick<Board, 'id' | 'title'>
);

export type BoardGetListQueryVariables = {
  filterBy?: Maybe<BoardFiltersInput>,
  pagination?: Maybe<Pagination>
};


export type BoardGetListQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'BoardConnection' }
    & { total: BoardConnection['totalCount'] }
    & { data: Array<(
      { __typename?: 'Board' }
      & BoardFragmentFragment
    )> }
  )> }
);

export type BoardGetQueryVariables = {
  id: Scalars['ID']
};


export type BoardGetQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'Board' }
    & BoardFragmentFragment
  )> }
);

export type BoardGetManyQueryVariables = {
  ids: Array<Scalars['ID']>
};


export type BoardGetManyQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<Array<Maybe<(
    { __typename?: 'Board' }
    & BoardFragmentFragment
  )>>> }
);

export type DailyCommentByUserFragmentFragment = (
  { __typename?: 'Comment' }
  & Pick<Comment, 'id' | 'ipaddress' | 'createdAt' | 'nickname'>
  & { author: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'username' | 'nickname'>
  )>, board: Maybe<(
    { __typename?: 'Board' }
    & Pick<Board, 'id' | 'title'>
  )> }
);

export type DailyCommentByUserGetListQueryVariables = {
  filterBy?: Maybe<DailyCommentByUserFilters>,
  orderBy?: Maybe<DailyCommentByUserOrder>,
  pagination?: Maybe<Pagination>
};


export type DailyCommentByUserGetListQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'DailyCommentByUserConnection' }
    & { total: DailyCommentByUserConnection['totalCount'] }
    & { data: Maybe<Array<(
      { __typename?: 'Comment' }
      & DailyCommentByUserFragmentFragment
    )>> }
  )> }
);

export type DailyPostByUserFragmentFragment = (
  { __typename?: 'Post' }
  & Pick<Post, 'id' | 'ipaddress' | 'createdAt' | 'nickname'>
  & { author: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'username' | 'nickname'>
  )>, board: Maybe<(
    { __typename?: 'Board' }
    & Pick<Board, 'id' | 'title'>
  )> }
);

export type DailyPostByUserGetListQueryVariables = {
  filterBy?: Maybe<DailyPostByUserFilters>,
  orderBy?: Maybe<DailyPostByUserOrder>,
  pagination?: Maybe<Pagination>
};


export type DailyPostByUserGetListQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'DailyPostByUserConnection' }
    & { total: DailyPostByUserConnection['totalCount'] }
    & { data: Maybe<Array<(
      { __typename?: 'Post' }
      & DailyPostByUserFragmentFragment
    )>> }
  )> }
);

export type PostCollectionFragmentFragment = (
  { __typename?: 'PostCollection' }
  & Pick<PostCollection, 'id' | 'title' | 'updatedAt'>
  & { posts: Array<(
    { __typename?: 'Post' }
    & Pick<Post, 'id'>
  )>, automation: Maybe<(
    { __typename?: 'PostCollectionAutomation' }
    & Pick<PostCollectionAutomation, 'enabled'>
    & { boards: Array<(
      { __typename?: 'Board' }
      & Pick<Board, 'id'>
    )>, filters: Maybe<(
      { __typename?: 'PostFilters' }
      & { views: Maybe<(
        { __typename?: 'Range' }
        & Pick<Range, 'gte'>
      )> }
    )> }
  )> }
);

export type PostCollectionGetListQueryVariables = {
  filterBy?: Maybe<PostCollectionFiltersInput>,
  orderBy?: Maybe<PostCollectionOrder>,
  pagination?: Maybe<Pagination>
};


export type PostCollectionGetListQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'PostCollectionConnection' }
    & { total: PostCollectionConnection['totalCount'] }
    & { data: Maybe<Array<Maybe<(
      { __typename?: 'PostCollection' }
      & PostCollectionFragmentFragment
    )>>> }
  )> }
);

export type PostCollectionGetQueryVariables = {
  id: Scalars['ID']
};


export type PostCollectionGetQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'PostCollection' }
    & PostCollectionFragmentFragment
  )> }
);

export type PostCollectionGetManyQueryVariables = {
  ids: Array<Scalars['ID']>
};


export type PostCollectionGetManyQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<Array<Maybe<(
    { __typename?: 'PostCollection' }
    & PostCollectionFragmentFragment
  )>>> }
);

export type PostCollectionCreateMutationVariables = {
  input: PostCollectionInput
};


export type PostCollectionCreateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'PostCollectionCreatePayload' }
    & { data: Maybe<(
      { __typename?: 'PostCollection' }
      & PostCollectionFragmentFragment
    )> }
  )> }
);

export type PostCollectionUpdateMutationVariables = {
  id: Scalars['ID'],
  input: PostCollectionInput
};


export type PostCollectionUpdateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'PostCollectionUpdatePayload' }
    & { data: Maybe<(
      { __typename?: 'PostCollection' }
      & PostCollectionFragmentFragment
    )> }
  )> }
);

export type PostFragmentFragment = (
  { __typename?: 'Post' }
  & Pick<Post, 'id' | 'title' | 'username' | 'globalNotice' | 'updatedAt'>
  & { board: Maybe<(
    { __typename?: 'Board' }
    & Pick<Board, 'id' | 'title'>
  )>, postCollections: Array<(
    { __typename?: 'PostCollection' }
    & Pick<PostCollection, 'id'>
  )>, tags: Array<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'id'>
  )> }
);

export type PostGetListQueryVariables = {
  filterBy?: Maybe<PostFiltersInput>,
  orderBy?: Maybe<PostOrder>,
  pagination?: Maybe<Pagination>
};


export type PostGetListQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'PostConnection' }
    & { total: PostConnection['totalCount'] }
    & { data: Maybe<Array<(
      { __typename?: 'Post' }
      & PostFragmentFragment
    )>> }
  )> }
);

export type PostGetQueryVariables = {
  id: Scalars['ID']
};


export type PostGetQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'Post' }
    & PostFragmentFragment
  )> }
);

export type PostGetManyQueryVariables = {
  ids: Array<Scalars['ID']>
};


export type PostGetManyQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<Array<Maybe<(
    { __typename?: 'Post' }
    & PostFragmentFragment
  )>>> }
);

export type PostUpdateMutationVariables = {
  id: Scalars['ID'],
  input: PostUpdateInput
};


export type PostUpdateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'PostUpdatePayload' }
    & { data: Maybe<(
      { __typename?: 'Post' }
      & PostFragmentFragment
    )> }
  )> }
);

export type PostUpdateManyMutationVariables = {
  ids: Array<Scalars['ID']>,
  input: PostUpdateManyInput
};


export type PostUpdateManyMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'PostUpdateManyPayload' }
    & { data: Array<(
      { __typename?: 'Post' }
      & PostFragmentFragment
    )> }
  )> }
);

export type PostDeleteMutationVariables = {
  id: Scalars['ID']
};


export type PostDeleteMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'PostDeletePayload' }
    & { data: Maybe<(
      { __typename?: 'Post' }
      & PostFragmentFragment
    )> }
  )> }
);

export type PostDeleteManyMutationVariables = {
  ids: Array<Scalars['ID']>
};


export type PostDeleteManyMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'PostDeleteManyPayload' }
    & { data: Array<(
      { __typename?: 'Post' }
      & PostFragmentFragment
    )> }
  )> }
);

export type TagFragmentFragment = (
  { __typename?: 'Tag' }
  & Pick<Tag, 'id' | 'name' | 'createdAt' | 'updatedAt'>
);

export type TagGetQueryVariables = {
  id: Scalars['ID']
};


export type TagGetQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'Tag' }
    & TagFragmentFragment
  )> }
);

export type TagGetListQueryVariables = {
  filterBy?: Maybe<TagFilters>,
  orderBy?: Maybe<TagOrder>,
  pagination?: Maybe<Pagination>
};


export type TagGetListQuery = (
  { __typename?: 'Query' }
  & { data: (
    { __typename?: 'TagConnection' }
    & { total: TagConnection['totalCount'] }
    & { data: Array<(
      { __typename?: 'Tag' }
      & TagFragmentFragment
    )> }
  ) }
);

export type TagGetManyQueryVariables = {
  ids: Array<Scalars['ID']>
};


export type TagGetManyQuery = (
  { __typename?: 'Query' }
  & { data: Array<(
    { __typename?: 'Tag' }
    & TagFragmentFragment
  )> }
);

export type TagCreateMutationVariables = {
  input: TagInput
};


export type TagCreateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'TagCreatePayload' }
    & { data: Maybe<(
      { __typename?: 'Tag' }
      & TagFragmentFragment
    )> }
  )> }
);

export type TagUpdateMutationVariables = {
  id: Scalars['ID'],
  input: TagInput
};


export type TagUpdateMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'TagUpdatePayload' }
    & { data: Maybe<(
      { __typename?: 'Tag' }
      & TagFragmentFragment
    )> }
  )> }
);

export type TagDeleteMutationVariables = {
  id: Scalars['ID']
};


export type TagDeleteMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'TagDeletePayload' }
    & { data: Maybe<(
      { __typename?: 'Tag' }
      & TagFragmentFragment
    )> }
  )> }
);

export type TagDeleteManyMutationVariables = {
  ids: Array<Scalars['ID']>
};


export type TagDeleteManyMutation = (
  { __typename?: 'Mutation' }
  & { data: Maybe<(
    { __typename?: 'TagDeleteManyPayload' }
    & { data: Array<(
      { __typename?: 'Tag' }
      & TagFragmentFragment
    )> }
  )> }
);

export type UserFragmentFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'username' | 'nickname'>
  & { posts: Maybe<(
    { __typename?: 'PostConnection' }
    & Pick<PostConnection, 'totalCount'>
  )> }
);

export type UserGetListQueryVariables = {
  filterBy?: Maybe<UserFilters>,
  orderBy?: Maybe<UserOrder>,
  pagination?: Maybe<Pagination>
};


export type UserGetListQuery = (
  { __typename?: 'Query' }
  & { data: (
    { __typename?: 'UserConnection' }
    & { total: UserConnection['totalCount'] }
    & { data: Array<(
      { __typename?: 'User' }
      & UserFragmentFragment
    )> }
  ) }
);

export type UserGetQueryVariables = {
  id: Scalars['ID']
};


export type UserGetQuery = (
  { __typename?: 'Query' }
  & { data: Maybe<(
    { __typename?: 'User' }
    & UserFragmentFragment
  )> }
);

export type UserGetManyQueryVariables = {
  ids: Array<Scalars['ID']>
};


export type UserGetManyQuery = (
  { __typename?: 'Query' }
  & { data: Array<(
    { __typename?: 'User' }
    & UserFragmentFragment
  )> }
);

export type RequirementInputsFragment = (
  { __typename?: 'CollectionRequirement' }
  & Pick<CollectionRequirement, 'view' | 'scrap' | 'like' | 'comment'>
);

export type BestPostInfomationFragment = (
  { __typename?: 'Post' }
  & Pick<Post, 'score' | 'views' | 'commentCount' | 'likes' | 'scrapCount' | 'title' | 'createdAt'>
);

export type BestOfBestRequirementQueryVariables = {};


export type BestOfBestRequirementQuery = (
  { __typename?: 'Query' }
  & { bestOfBestRequirement: Maybe<(
    { __typename?: 'CollectionRequirement' }
    & Pick<CollectionRequirement, 'id'>
    & RequirementInputsFragment
  )> }
);

export type BestPostsQueryVariables = {
  pagination?: Maybe<Pagination>,
  filterBy?: Maybe<PostFiltersInput>,
  orderBy?: Maybe<PostOrder>
};


export type BestPostsQuery = (
  { __typename?: 'Query' }
  & { bestOfBestPostPreviews: Maybe<(
    { __typename?: 'PostConnection' }
    & { nodes: Maybe<Array<(
      { __typename?: 'Post' }
      & Pick<Post, 'id' | 'createdAt' | 'views' | 'commentCount' | 'likes' | 'score' | 'scrapCount' | 'title'>
    )>> }
  )> }
);

export type UpdateBestOfBestRequirementMutationVariables = {
  input: CollectionRequirementUpdateInput
};


export type UpdateBestOfBestRequirementMutation = (
  { __typename?: 'Mutation' }
  & { updateBestOfBestRequirement: Maybe<(
    { __typename?: 'CollectionRequirementUpdatePayload' }
    & { collectionRequirement: Maybe<(
      { __typename?: 'CollectionRequirement' }
      & Pick<CollectionRequirement, 'comment' | 'like' | 'scrap' | 'view' | 'id'>
    )> }
  )> }
);

export type PostCollectionRebuildMutationVariables = {
  id: Scalars['ID'],
  automation?: Maybe<PostCollectionAutomationInput>
};


export type PostCollectionRebuildMutation = (
  { __typename?: 'Mutation' }
  & { postCollectionRebuild: Maybe<(
    { __typename?: 'PostCollectionRebuildPayload' }
    & { postCollection: Maybe<(
      { __typename?: 'PostCollection' }
      & Pick<PostCollection, 'id'>
    )> }
  )> }
);

export type ConfigMessageQueryVariables = {};


export type ConfigMessageQuery = (
  { __typename?: 'Query' }
  & { configLoginAlertDescription: Maybe<(
    { __typename?: 'Config' }
    & ConfigFragmentFragment
  )>, configGlobalNoticeBaseURL: Maybe<(
    { __typename?: 'Config' }
    & ConfigFragmentFragment
  )>, configPostDetailSiteName: Maybe<(
    { __typename?: 'Config' }
    & ConfigFragmentFragment
  )>, configBestBoardNoticeTitle: Maybe<(
    { __typename?: 'Config' }
    & ConfigFragmentFragment
  )> }
);

export type ConfigSeoQueryVariables = {};


export type ConfigSeoQuery = (
  { __typename?: 'Query' }
  & { configSEODefaultTitle: Maybe<(
    { __typename?: 'Config' }
    & ConfigFragmentFragment
  )>, configSEODefaultDescription: Maybe<(
    { __typename?: 'Config' }
    & ConfigFragmentFragment
  )>, configSEOOpengraphTitle: Maybe<(
    { __typename?: 'Config' }
    & ConfigFragmentFragment
  )>, configSEOOpengraphDescription: Maybe<(
    { __typename?: 'Config' }
    & ConfigFragmentFragment
  )>, configSEOOpengraphURL: Maybe<(
    { __typename?: 'Config' }
    & ConfigFragmentFragment
  )>, configSEOOpengraphSiteName: Maybe<(
    { __typename?: 'Config' }
    & ConfigFragmentFragment
  )> }
);

export type ConfigUiQueryVariables = {};


export type ConfigUiQuery = (
  { __typename?: 'Query' }
  & { configHeaderURL: Maybe<(
    { __typename?: 'Config' }
    & ConfigFragmentFragment
  )>, configHeaderDefaultHeight: Maybe<(
    { __typename?: 'Config' }
    & ConfigFragmentFragment
  )>, configHeaderExpandedHeight: Maybe<(
    { __typename?: 'Config' }
    & ConfigFragmentFragment
  )>, configFooterURL: Maybe<(
    { __typename?: 'Config' }
    & ConfigFragmentFragment
  )>, configFooterDefaultHeight: Maybe<(
    { __typename?: 'Config' }
    & ConfigFragmentFragment
  )> }
);

export const ConfigFragmentFragmentDoc = gql`
    fragment ConfigFragment on Config {
  id
  name
  description
  value
  defaultValue
}
    `;
export const BoardFragmentFragmentDoc = gql`
    fragment BoardFragment on Board {
  id
  title
}
    `;
export const BoardNavigationFragmentFragmentDoc = gql`
    fragment BoardNavigationFragment on BoardNavigation {
  id
  name
  board {
    ...BoardFragment
  }
}
    ${BoardFragmentFragmentDoc}`;
export const DailyCommentByUserFragmentFragmentDoc = gql`
    fragment DailyCommentByUserFragment on Comment {
  id
  ipaddress
  createdAt
  nickname
  author {
    id
    email
    username
    nickname
  }
  board {
    id
    title
  }
}
    `;
export const DailyPostByUserFragmentFragmentDoc = gql`
    fragment DailyPostByUserFragment on Post {
  id
  ipaddress
  createdAt
  nickname
  author {
    id
    email
    username
    nickname
  }
  board {
    id
    title
  }
}
    `;
export const PostCollectionFragmentFragmentDoc = gql`
    fragment PostCollectionFragment on PostCollection {
  id
  title
  posts(pagination: {page: 1, pageSize: 100}) {
    id
  }
  automation {
    enabled
    boards {
      id
    }
    filters {
      views {
        gte
      }
    }
  }
  updatedAt
}
    `;
export const PostFragmentFragmentDoc = gql`
    fragment PostFragment on Post {
  id
  title
  username
  globalNotice
  board {
    id
    title
  }
  postCollections {
    id
  }
  updatedAt
  tags {
    id
  }
}
    `;
export const TagFragmentFragmentDoc = gql`
    fragment TagFragment on Tag {
  id
  name
  createdAt
  updatedAt
}
    `;
export const UserFragmentFragmentDoc = gql`
    fragment UserFragment on User {
  id
  username
  nickname
  posts {
    totalCount
  }
}
    `;
export const RequirementInputsFragmentDoc = gql`
    fragment RequirementInputs on CollectionRequirement {
  view
  scrap
  like
  comment
}
    `;
export const BestPostInfomationFragmentDoc = gql`
    fragment BestPostInfomation on Post {
  score
  views
  commentCount
  likes
  scrapCount
  title
  createdAt
}
    `;
export const TrashedCommentsDocument = gql`
    query TrashedComments($filterBy: CommentTrashFilters, $orderBy: CommentTrashOrder, $pagination: Pagination) {
  trashedComments(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    nodes {
      id
      contentTextForAdmin
      author {
        id
      }
      nickname
      board {
        title
      }
      deletedAt
    }
    totalCount
  }
}
    `;

/**
 * __useTrashedCommentsQuery__
 *
 * To run a query within a React component, call `useTrashedCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrashedCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrashedCommentsQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useTrashedCommentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TrashedCommentsQuery, TrashedCommentsQueryVariables>) {
        return ApolloReactHooks.useQuery<TrashedCommentsQuery, TrashedCommentsQueryVariables>(TrashedCommentsDocument, baseOptions);
      }
export function useTrashedCommentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TrashedCommentsQuery, TrashedCommentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TrashedCommentsQuery, TrashedCommentsQueryVariables>(TrashedCommentsDocument, baseOptions);
        }
export type TrashedCommentsQueryHookResult = ReturnType<typeof useTrashedCommentsQuery>;
export type TrashedCommentsLazyQueryHookResult = ReturnType<typeof useTrashedCommentsLazyQuery>;
export type TrashedCommentsQueryResult = ApolloReactCommon.QueryResult<TrashedCommentsQuery, TrashedCommentsQueryVariables>;
export const TrashedPostsDocument = gql`
    query TrashedPosts($filterBy: PostTrashFilters, $orderBy: PostTrashOrder, $pagination: Pagination) {
  trashedPosts(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    nodes {
      id
      author {
        id
      }
      nickname
      title
      board {
        title
      }
      deletedAt
    }
    totalCount
  }
}
    `;

/**
 * __useTrashedPostsQuery__
 *
 * To run a query within a React component, call `useTrashedPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrashedPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrashedPostsQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useTrashedPostsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TrashedPostsQuery, TrashedPostsQueryVariables>) {
        return ApolloReactHooks.useQuery<TrashedPostsQuery, TrashedPostsQueryVariables>(TrashedPostsDocument, baseOptions);
      }
export function useTrashedPostsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TrashedPostsQuery, TrashedPostsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TrashedPostsQuery, TrashedPostsQueryVariables>(TrashedPostsDocument, baseOptions);
        }
export type TrashedPostsQueryHookResult = ReturnType<typeof useTrashedPostsQuery>;
export type TrashedPostsLazyQueryHookResult = ReturnType<typeof useTrashedPostsLazyQuery>;
export type TrashedPostsQueryResult = ApolloReactCommon.QueryResult<TrashedPostsQuery, TrashedPostsQueryVariables>;
export const PostDeleteReportDetailDocument = gql`
    query PostDeleteReportDetail($postId: ID!, $filterBy: PostAbuseReportLogFilters, $pagination: Pagination) {
  post(id: $postId) {
    id
    nickname
    title
    author {
      id
    }
    reportCount
    reportCountByDescription {
      description
      count
    }
  }
  postAbuseReportLogs(filterBy: $filterBy, pagination: $pagination) {
    nodes {
      id
      createdAt
      reporter {
        id
        nickname
      }
      description
    }
    totalCount
  }
}
    `;

/**
 * __usePostDeleteReportDetailQuery__
 *
 * To run a query within a React component, call `usePostDeleteReportDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostDeleteReportDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostDeleteReportDetailQuery({
 *   variables: {
 *      postId: // value for 'postId'
 *      filterBy: // value for 'filterBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function usePostDeleteReportDetailQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PostDeleteReportDetailQuery, PostDeleteReportDetailQueryVariables>) {
        return ApolloReactHooks.useQuery<PostDeleteReportDetailQuery, PostDeleteReportDetailQueryVariables>(PostDeleteReportDetailDocument, baseOptions);
      }
export function usePostDeleteReportDetailLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PostDeleteReportDetailQuery, PostDeleteReportDetailQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PostDeleteReportDetailQuery, PostDeleteReportDetailQueryVariables>(PostDeleteReportDetailDocument, baseOptions);
        }
export type PostDeleteReportDetailQueryHookResult = ReturnType<typeof usePostDeleteReportDetailQuery>;
export type PostDeleteReportDetailLazyQueryHookResult = ReturnType<typeof usePostDeleteReportDetailLazyQuery>;
export type PostDeleteReportDetailQueryResult = ApolloReactCommon.QueryResult<PostDeleteReportDetailQuery, PostDeleteReportDetailQueryVariables>;
export const CommentDeleteDocument = gql`
    mutation CommentDelete($commentDeleteId: ID!) {
  commentDelete(id: $commentDeleteId) {
    deletedCommentID
    post {
      title
    }
  }
}
    `;
export type CommentDeleteMutationFn = ApolloReactCommon.MutationFunction<CommentDeleteMutation, CommentDeleteMutationVariables>;

/**
 * __useCommentDeleteMutation__
 *
 * To run a mutation, you first call `useCommentDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCommentDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [commentDeleteMutation, { data, loading, error }] = useCommentDeleteMutation({
 *   variables: {
 *      commentDeleteId: // value for 'commentDeleteId'
 *   },
 * });
 */
export function useCommentDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CommentDeleteMutation, CommentDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<CommentDeleteMutation, CommentDeleteMutationVariables>(CommentDeleteDocument, baseOptions);
      }
export type CommentDeleteMutationHookResult = ReturnType<typeof useCommentDeleteMutation>;
export type CommentDeleteMutationResult = ApolloReactCommon.MutationResult<CommentDeleteMutation>;
export type CommentDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<CommentDeleteMutation, CommentDeleteMutationVariables>;
export const ReadCommentAbuseReportLogDocument = gql`
    mutation ReadCommentAbuseReportLog($readCommentAbuseReportLogId: ID!) {
  readCommentAbuseReportLog(id: $readCommentAbuseReportLogId) {
    commentAbuseReportLog {
      id
      comment {
        contentText
      }
    }
  }
}
    `;
export type ReadCommentAbuseReportLogMutationFn = ApolloReactCommon.MutationFunction<ReadCommentAbuseReportLogMutation, ReadCommentAbuseReportLogMutationVariables>;

/**
 * __useReadCommentAbuseReportLogMutation__
 *
 * To run a mutation, you first call `useReadCommentAbuseReportLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadCommentAbuseReportLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readCommentAbuseReportLogMutation, { data, loading, error }] = useReadCommentAbuseReportLogMutation({
 *   variables: {
 *      readCommentAbuseReportLogId: // value for 'readCommentAbuseReportLogId'
 *   },
 * });
 */
export function useReadCommentAbuseReportLogMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReadCommentAbuseReportLogMutation, ReadCommentAbuseReportLogMutationVariables>) {
        return ApolloReactHooks.useMutation<ReadCommentAbuseReportLogMutation, ReadCommentAbuseReportLogMutationVariables>(ReadCommentAbuseReportLogDocument, baseOptions);
      }
export type ReadCommentAbuseReportLogMutationHookResult = ReturnType<typeof useReadCommentAbuseReportLogMutation>;
export type ReadCommentAbuseReportLogMutationResult = ApolloReactCommon.MutationResult<ReadCommentAbuseReportLogMutation>;
export type ReadCommentAbuseReportLogMutationOptions = ApolloReactCommon.BaseMutationOptions<ReadCommentAbuseReportLogMutation, ReadCommentAbuseReportLogMutationVariables>;
export const ReadAllCommentAbuseReportLogDocument = gql`
    mutation ReadAllCommentAbuseReportLog($commentId: ID!) {
  readAllCommentAbuseReportLog(commentID: $commentId) {
    updatedCommentAbuseReportLogs
  }
}
    `;
export type ReadAllCommentAbuseReportLogMutationFn = ApolloReactCommon.MutationFunction<ReadAllCommentAbuseReportLogMutation, ReadAllCommentAbuseReportLogMutationVariables>;

/**
 * __useReadAllCommentAbuseReportLogMutation__
 *
 * To run a mutation, you first call `useReadAllCommentAbuseReportLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadAllCommentAbuseReportLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readAllCommentAbuseReportLogMutation, { data, loading, error }] = useReadAllCommentAbuseReportLogMutation({
 *   variables: {
 *      commentId: // value for 'commentId'
 *   },
 * });
 */
export function useReadAllCommentAbuseReportLogMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReadAllCommentAbuseReportLogMutation, ReadAllCommentAbuseReportLogMutationVariables>) {
        return ApolloReactHooks.useMutation<ReadAllCommentAbuseReportLogMutation, ReadAllCommentAbuseReportLogMutationVariables>(ReadAllCommentAbuseReportLogDocument, baseOptions);
      }
export type ReadAllCommentAbuseReportLogMutationHookResult = ReturnType<typeof useReadAllCommentAbuseReportLogMutation>;
export type ReadAllCommentAbuseReportLogMutationResult = ApolloReactCommon.MutationResult<ReadAllCommentAbuseReportLogMutation>;
export type ReadAllCommentAbuseReportLogMutationOptions = ApolloReactCommon.BaseMutationOptions<ReadAllCommentAbuseReportLogMutation, ReadAllCommentAbuseReportLogMutationVariables>;
export const ReportedCommentsDocument = gql`
    query ReportedComments($filterBy: CommentReportFilters, $orderBy: CommentReportOrder, $pagination: Pagination) {
  reportedComments(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    nodes {
      id
      author {
        id
      }
      nickname
      originContentText
      reportDescription
      lastReportedAt
      reportCount
      ipaddress
    }
    totalCount
  }
}
    `;

/**
 * __useReportedCommentsQuery__
 *
 * To run a query within a React component, call `useReportedCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportedCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportedCommentsQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useReportedCommentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReportedCommentsQuery, ReportedCommentsQueryVariables>) {
        return ApolloReactHooks.useQuery<ReportedCommentsQuery, ReportedCommentsQueryVariables>(ReportedCommentsDocument, baseOptions);
      }
export function useReportedCommentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReportedCommentsQuery, ReportedCommentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReportedCommentsQuery, ReportedCommentsQueryVariables>(ReportedCommentsDocument, baseOptions);
        }
export type ReportedCommentsQueryHookResult = ReturnType<typeof useReportedCommentsQuery>;
export type ReportedCommentsLazyQueryHookResult = ReturnType<typeof useReportedCommentsLazyQuery>;
export type ReportedCommentsQueryResult = ApolloReactCommon.QueryResult<ReportedCommentsQuery, ReportedCommentsQueryVariables>;
export const CommentsDocument = gql`
    query Comments($commentId: ID!, $filterBy: CommentAbuseReportLogFilters, $pagination: Pagination) {
  comment(id: $commentId) {
    originContentText
    post {
      id
    }
    nickname
    author {
      id
    }
    reportCount
    reportCountByDescription {
      description
      count
    }
    deletedAt
  }
  commentAbuseReportLogs(filterBy: $filterBy, pagination: $pagination) {
    nodes {
      id
      createdAt
      reporter {
        nickname
        id
      }
      description
      adminReadAt
    }
    totalCount
  }
}
    `;

/**
 * __useCommentsQuery__
 *
 * To run a query within a React component, call `useCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCommentsQuery({
 *   variables: {
 *      commentId: // value for 'commentId'
 *      filterBy: // value for 'filterBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useCommentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CommentsQuery, CommentsQueryVariables>) {
        return ApolloReactHooks.useQuery<CommentsQuery, CommentsQueryVariables>(CommentsDocument, baseOptions);
      }
export function useCommentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CommentsQuery, CommentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CommentsQuery, CommentsQueryVariables>(CommentsDocument, baseOptions);
        }
export type CommentsQueryHookResult = ReturnType<typeof useCommentsQuery>;
export type CommentsLazyQueryHookResult = ReturnType<typeof useCommentsLazyQuery>;
export type CommentsQueryResult = ApolloReactCommon.QueryResult<CommentsQuery, CommentsQueryVariables>;
export const PostDeleteByReportedDocument = gql`
    mutation PostDeleteByReported($postDeleteId: ID!) {
  postDelete(id: $postDeleteId) {
    deletedPostID
    post {
      id
    }
  }
}
    `;
export type PostDeleteByReportedMutationFn = ApolloReactCommon.MutationFunction<PostDeleteByReportedMutation, PostDeleteByReportedMutationVariables>;

/**
 * __usePostDeleteByReportedMutation__
 *
 * To run a mutation, you first call `usePostDeleteByReportedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostDeleteByReportedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postDeleteByReportedMutation, { data, loading, error }] = usePostDeleteByReportedMutation({
 *   variables: {
 *      postDeleteId: // value for 'postDeleteId'
 *   },
 * });
 */
export function usePostDeleteByReportedMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostDeleteByReportedMutation, PostDeleteByReportedMutationVariables>) {
        return ApolloReactHooks.useMutation<PostDeleteByReportedMutation, PostDeleteByReportedMutationVariables>(PostDeleteByReportedDocument, baseOptions);
      }
export type PostDeleteByReportedMutationHookResult = ReturnType<typeof usePostDeleteByReportedMutation>;
export type PostDeleteByReportedMutationResult = ApolloReactCommon.MutationResult<PostDeleteByReportedMutation>;
export type PostDeleteByReportedMutationOptions = ApolloReactCommon.BaseMutationOptions<PostDeleteByReportedMutation, PostDeleteByReportedMutationVariables>;
export const ReadPostAbuseReportLogDocument = gql`
    mutation ReadPostAbuseReportLog($readPostAbuseReportLogId: ID!) {
  readPostAbuseReportLog(id: $readPostAbuseReportLogId) {
    postAbuseReportLog {
      id
    }
  }
}
    `;
export type ReadPostAbuseReportLogMutationFn = ApolloReactCommon.MutationFunction<ReadPostAbuseReportLogMutation, ReadPostAbuseReportLogMutationVariables>;

/**
 * __useReadPostAbuseReportLogMutation__
 *
 * To run a mutation, you first call `useReadPostAbuseReportLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadPostAbuseReportLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readPostAbuseReportLogMutation, { data, loading, error }] = useReadPostAbuseReportLogMutation({
 *   variables: {
 *      readPostAbuseReportLogId: // value for 'readPostAbuseReportLogId'
 *   },
 * });
 */
export function useReadPostAbuseReportLogMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReadPostAbuseReportLogMutation, ReadPostAbuseReportLogMutationVariables>) {
        return ApolloReactHooks.useMutation<ReadPostAbuseReportLogMutation, ReadPostAbuseReportLogMutationVariables>(ReadPostAbuseReportLogDocument, baseOptions);
      }
export type ReadPostAbuseReportLogMutationHookResult = ReturnType<typeof useReadPostAbuseReportLogMutation>;
export type ReadPostAbuseReportLogMutationResult = ApolloReactCommon.MutationResult<ReadPostAbuseReportLogMutation>;
export type ReadPostAbuseReportLogMutationOptions = ApolloReactCommon.BaseMutationOptions<ReadPostAbuseReportLogMutation, ReadPostAbuseReportLogMutationVariables>;
export const ReadAllPostAbuseReportLogDocument = gql`
    mutation ReadAllPostAbuseReportLog($postId: ID!) {
  readAllPostAbuseReportLog(postID: $postId) {
    updatedPostAbuseReportLogs
  }
}
    `;
export type ReadAllPostAbuseReportLogMutationFn = ApolloReactCommon.MutationFunction<ReadAllPostAbuseReportLogMutation, ReadAllPostAbuseReportLogMutationVariables>;

/**
 * __useReadAllPostAbuseReportLogMutation__
 *
 * To run a mutation, you first call `useReadAllPostAbuseReportLogMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReadAllPostAbuseReportLogMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [readAllPostAbuseReportLogMutation, { data, loading, error }] = useReadAllPostAbuseReportLogMutation({
 *   variables: {
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function useReadAllPostAbuseReportLogMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ReadAllPostAbuseReportLogMutation, ReadAllPostAbuseReportLogMutationVariables>) {
        return ApolloReactHooks.useMutation<ReadAllPostAbuseReportLogMutation, ReadAllPostAbuseReportLogMutationVariables>(ReadAllPostAbuseReportLogDocument, baseOptions);
      }
export type ReadAllPostAbuseReportLogMutationHookResult = ReturnType<typeof useReadAllPostAbuseReportLogMutation>;
export type ReadAllPostAbuseReportLogMutationResult = ApolloReactCommon.MutationResult<ReadAllPostAbuseReportLogMutation>;
export type ReadAllPostAbuseReportLogMutationOptions = ApolloReactCommon.BaseMutationOptions<ReadAllPostAbuseReportLogMutation, ReadAllPostAbuseReportLogMutationVariables>;
export const BanUserDocument = gql`
    mutation BanUser($input: BanLogInput!) {
  banUser(input: $input) {
    id
  }
}
    `;
export type BanUserMutationFn = ApolloReactCommon.MutationFunction<BanUserMutation, BanUserMutationVariables>;

/**
 * __useBanUserMutation__
 *
 * To run a mutation, you first call `useBanUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBanUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [banUserMutation, { data, loading, error }] = useBanUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBanUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BanUserMutation, BanUserMutationVariables>) {
        return ApolloReactHooks.useMutation<BanUserMutation, BanUserMutationVariables>(BanUserDocument, baseOptions);
      }
export type BanUserMutationHookResult = ReturnType<typeof useBanUserMutation>;
export type BanUserMutationResult = ApolloReactCommon.MutationResult<BanUserMutation>;
export type BanUserMutationOptions = ApolloReactCommon.BaseMutationOptions<BanUserMutation, BanUserMutationVariables>;
export const ReportedPostsDocument = gql`
    query ReportedPosts($pagination: Pagination, $orderBy: PostReportOrder, $filterBy: PostReportFilters) {
  reportedPosts(pagination: $pagination, orderBy: $orderBy, filterBy: $filterBy) {
    nodes {
      id
      nickname
      author {
        id
      }
      title
      lastReportedAt
      reportCount
      ipaddress
      hasUnreadReport
      reportDescription
      deletedAt
    }
    totalCount
  }
}
    `;

/**
 * __useReportedPostsQuery__
 *
 * To run a query within a React component, call `useReportedPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportedPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportedPostsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      orderBy: // value for 'orderBy'
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useReportedPostsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReportedPostsQuery, ReportedPostsQueryVariables>) {
        return ApolloReactHooks.useQuery<ReportedPostsQuery, ReportedPostsQueryVariables>(ReportedPostsDocument, baseOptions);
      }
export function useReportedPostsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReportedPostsQuery, ReportedPostsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReportedPostsQuery, ReportedPostsQueryVariables>(ReportedPostsDocument, baseOptions);
        }
export type ReportedPostsQueryHookResult = ReturnType<typeof useReportedPostsQuery>;
export type ReportedPostsLazyQueryHookResult = ReturnType<typeof useReportedPostsLazyQuery>;
export type ReportedPostsQueryResult = ApolloReactCommon.QueryResult<ReportedPostsQuery, ReportedPostsQueryVariables>;
export const PostDocument = gql`
    query Post($filterBy: PostAbuseReportLogFilters, $pagination: Pagination, $postId: ID!) {
  post(id: $postId) {
    id
    title
    nickname
    author {
      id
    }
    reportCount
    deletedAt
    reportCountByDescription {
      description
      count
    }
  }
  postAbuseReportLogs(filterBy: $filterBy, pagination: $pagination) {
    nodes {
      id
      description
      reporter {
        id
        nickname
      }
      adminReadAt
      createdAt
    }
    totalCount
  }
}
    `;

/**
 * __usePostQuery__
 *
 * To run a query within a React component, call `usePostQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      pagination: // value for 'pagination'
 *      postId: // value for 'postId'
 *   },
 * });
 */
export function usePostQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PostQuery, PostQueryVariables>) {
        return ApolloReactHooks.useQuery<PostQuery, PostQueryVariables>(PostDocument, baseOptions);
      }
export function usePostLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PostQuery, PostQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PostQuery, PostQueryVariables>(PostDocument, baseOptions);
        }
export type PostQueryHookResult = ReturnType<typeof usePostQuery>;
export type PostLazyQueryHookResult = ReturnType<typeof usePostLazyQuery>;
export type PostQueryResult = ApolloReactCommon.QueryResult<PostQuery, PostQueryVariables>;
export const UnbanUsersDocument = gql`
    mutation UnbanUsers($logIDs: [ID!]!) {
  unbanUsers(logIDs: $logIDs) {
    unbanLogs {
      id
      user {
        id
        nickname
        username
      }
      description
      startAt
      endAt
      deletedAt
    }
  }
}
    `;
export type UnbanUsersMutationFn = ApolloReactCommon.MutationFunction<UnbanUsersMutation, UnbanUsersMutationVariables>;

/**
 * __useUnbanUsersMutation__
 *
 * To run a mutation, you first call `useUnbanUsersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnbanUsersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unbanUsersMutation, { data, loading, error }] = useUnbanUsersMutation({
 *   variables: {
 *      logIDs: // value for 'logIDs'
 *   },
 * });
 */
export function useUnbanUsersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnbanUsersMutation, UnbanUsersMutationVariables>) {
        return ApolloReactHooks.useMutation<UnbanUsersMutation, UnbanUsersMutationVariables>(UnbanUsersDocument, baseOptions);
      }
export type UnbanUsersMutationHookResult = ReturnType<typeof useUnbanUsersMutation>;
export type UnbanUsersMutationResult = ApolloReactCommon.MutationResult<UnbanUsersMutation>;
export type UnbanUsersMutationOptions = ApolloReactCommon.BaseMutationOptions<UnbanUsersMutation, UnbanUsersMutationVariables>;
export const BanLogsDocument = gql`
    query BanLogs($orderBy: BanLogsOrder, $pagination: Pagination, $filterBy: BanLogsFilter) {
  banLogs(orderBy: $orderBy, pagination: $pagination, filterBy: $filterBy) {
    nodes {
      id
      user {
        id
        nickname
        username
      }
      description
      startAt
      endAt
      deletedAt
    }
    totalCount
  }
}
    `;

/**
 * __useBanLogsQuery__
 *
 * To run a query within a React component, call `useBanLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBanLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBanLogsQuery({
 *   variables: {
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useBanLogsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BanLogsQuery, BanLogsQueryVariables>) {
        return ApolloReactHooks.useQuery<BanLogsQuery, BanLogsQueryVariables>(BanLogsDocument, baseOptions);
      }
export function useBanLogsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BanLogsQuery, BanLogsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BanLogsQuery, BanLogsQueryVariables>(BanLogsDocument, baseOptions);
        }
export type BanLogsQueryHookResult = ReturnType<typeof useBanLogsQuery>;
export type BanLogsLazyQueryHookResult = ReturnType<typeof useBanLogsLazyQuery>;
export type BanLogsQueryResult = ApolloReactCommon.QueryResult<BanLogsQuery, BanLogsQueryVariables>;
export const ConfigUpdateDocument = gql`
    mutation ConfigUpdate($id: ID!, $value: String) {
  configUpdate(id: $id, input: {value: $value}) {
    ...ConfigFragment
  }
}
    ${ConfigFragmentFragmentDoc}`;
export type ConfigUpdateMutationFn = ApolloReactCommon.MutationFunction<ConfigUpdateMutation, ConfigUpdateMutationVariables>;

/**
 * __useConfigUpdateMutation__
 *
 * To run a mutation, you first call `useConfigUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfigUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [configUpdateMutation, { data, loading, error }] = useConfigUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useConfigUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ConfigUpdateMutation, ConfigUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<ConfigUpdateMutation, ConfigUpdateMutationVariables>(ConfigUpdateDocument, baseOptions);
      }
export type ConfigUpdateMutationHookResult = ReturnType<typeof useConfigUpdateMutation>;
export type ConfigUpdateMutationResult = ApolloReactCommon.MutationResult<ConfigUpdateMutation>;
export type ConfigUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<ConfigUpdateMutation, ConfigUpdateMutationVariables>;
export const ConfigResetDocument = gql`
    mutation ConfigReset($id: ID!) {
  configReset(id: $id)
}
    `;
export type ConfigResetMutationFn = ApolloReactCommon.MutationFunction<ConfigResetMutation, ConfigResetMutationVariables>;

/**
 * __useConfigResetMutation__
 *
 * To run a mutation, you first call `useConfigResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfigResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [configResetMutation, { data, loading, error }] = useConfigResetMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConfigResetMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ConfigResetMutation, ConfigResetMutationVariables>) {
        return ApolloReactHooks.useMutation<ConfigResetMutation, ConfigResetMutationVariables>(ConfigResetDocument, baseOptions);
      }
export type ConfigResetMutationHookResult = ReturnType<typeof useConfigResetMutation>;
export type ConfigResetMutationResult = ApolloReactCommon.MutationResult<ConfigResetMutation>;
export type ConfigResetMutationOptions = ApolloReactCommon.BaseMutationOptions<ConfigResetMutation, ConfigResetMutationVariables>;
export const BoardNavigationGetListDocument = gql`
    query BoardNavigationGetList($filterBy: BoardNavigationFiltersInput) {
  data: boardNavigations(filterBy: $filterBy) {
    data: nodes {
      ...BoardNavigationFragment
    }
    total: totalCount
  }
}
    ${BoardNavigationFragmentFragmentDoc}`;

/**
 * __useBoardNavigationGetListQuery__
 *
 * To run a query within a React component, call `useBoardNavigationGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useBoardNavigationGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBoardNavigationGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *   },
 * });
 */
export function useBoardNavigationGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BoardNavigationGetListQuery, BoardNavigationGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<BoardNavigationGetListQuery, BoardNavigationGetListQueryVariables>(BoardNavigationGetListDocument, baseOptions);
      }
export function useBoardNavigationGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BoardNavigationGetListQuery, BoardNavigationGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BoardNavigationGetListQuery, BoardNavigationGetListQueryVariables>(BoardNavigationGetListDocument, baseOptions);
        }
export type BoardNavigationGetListQueryHookResult = ReturnType<typeof useBoardNavigationGetListQuery>;
export type BoardNavigationGetListLazyQueryHookResult = ReturnType<typeof useBoardNavigationGetListLazyQuery>;
export type BoardNavigationGetListQueryResult = ApolloReactCommon.QueryResult<BoardNavigationGetListQuery, BoardNavigationGetListQueryVariables>;
export const BoardNavigationGetDocument = gql`
    query BoardNavigationGet($id: ID!) {
  data: boardNavigation(id: $id) {
    ...BoardNavigationFragment
  }
}
    ${BoardNavigationFragmentFragmentDoc}`;

/**
 * __useBoardNavigationGetQuery__
 *
 * To run a query within a React component, call `useBoardNavigationGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useBoardNavigationGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBoardNavigationGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBoardNavigationGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BoardNavigationGetQuery, BoardNavigationGetQueryVariables>) {
        return ApolloReactHooks.useQuery<BoardNavigationGetQuery, BoardNavigationGetQueryVariables>(BoardNavigationGetDocument, baseOptions);
      }
export function useBoardNavigationGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BoardNavigationGetQuery, BoardNavigationGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BoardNavigationGetQuery, BoardNavigationGetQueryVariables>(BoardNavigationGetDocument, baseOptions);
        }
export type BoardNavigationGetQueryHookResult = ReturnType<typeof useBoardNavigationGetQuery>;
export type BoardNavigationGetLazyQueryHookResult = ReturnType<typeof useBoardNavigationGetLazyQuery>;
export type BoardNavigationGetQueryResult = ApolloReactCommon.QueryResult<BoardNavigationGetQuery, BoardNavigationGetQueryVariables>;
export const BoardNavigationUpdateDocument = gql`
    mutation BoardNavigationUpdate($id: ID!, $input: BoardNavigationUpdateInput!) {
  data: boardNavigationUpdate(id: $id, input: $input) {
    data: boardNavigation {
      ...BoardNavigationFragment
    }
  }
}
    ${BoardNavigationFragmentFragmentDoc}`;
export type BoardNavigationUpdateMutationFn = ApolloReactCommon.MutationFunction<BoardNavigationUpdateMutation, BoardNavigationUpdateMutationVariables>;

/**
 * __useBoardNavigationUpdateMutation__
 *
 * To run a mutation, you first call `useBoardNavigationUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBoardNavigationUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [boardNavigationUpdateMutation, { data, loading, error }] = useBoardNavigationUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBoardNavigationUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<BoardNavigationUpdateMutation, BoardNavigationUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<BoardNavigationUpdateMutation, BoardNavigationUpdateMutationVariables>(BoardNavigationUpdateDocument, baseOptions);
      }
export type BoardNavigationUpdateMutationHookResult = ReturnType<typeof useBoardNavigationUpdateMutation>;
export type BoardNavigationUpdateMutationResult = ApolloReactCommon.MutationResult<BoardNavigationUpdateMutation>;
export type BoardNavigationUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<BoardNavigationUpdateMutation, BoardNavigationUpdateMutationVariables>;
export const BoardGetListDocument = gql`
    query BoardGetList($filterBy: BoardFiltersInput, $pagination: Pagination) {
  data: boards(filterBy: $filterBy, pagination: $pagination) {
    data: nodes {
      ...BoardFragment
    }
    total: totalCount
  }
}
    ${BoardFragmentFragmentDoc}`;

/**
 * __useBoardGetListQuery__
 *
 * To run a query within a React component, call `useBoardGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useBoardGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBoardGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useBoardGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BoardGetListQuery, BoardGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<BoardGetListQuery, BoardGetListQueryVariables>(BoardGetListDocument, baseOptions);
      }
export function useBoardGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BoardGetListQuery, BoardGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BoardGetListQuery, BoardGetListQueryVariables>(BoardGetListDocument, baseOptions);
        }
export type BoardGetListQueryHookResult = ReturnType<typeof useBoardGetListQuery>;
export type BoardGetListLazyQueryHookResult = ReturnType<typeof useBoardGetListLazyQuery>;
export type BoardGetListQueryResult = ApolloReactCommon.QueryResult<BoardGetListQuery, BoardGetListQueryVariables>;
export const BoardGetDocument = gql`
    query BoardGet($id: ID!) {
  data: board(id: $id) {
    ...BoardFragment
  }
}
    ${BoardFragmentFragmentDoc}`;

/**
 * __useBoardGetQuery__
 *
 * To run a query within a React component, call `useBoardGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useBoardGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBoardGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBoardGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BoardGetQuery, BoardGetQueryVariables>) {
        return ApolloReactHooks.useQuery<BoardGetQuery, BoardGetQueryVariables>(BoardGetDocument, baseOptions);
      }
export function useBoardGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BoardGetQuery, BoardGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BoardGetQuery, BoardGetQueryVariables>(BoardGetDocument, baseOptions);
        }
export type BoardGetQueryHookResult = ReturnType<typeof useBoardGetQuery>;
export type BoardGetLazyQueryHookResult = ReturnType<typeof useBoardGetLazyQuery>;
export type BoardGetQueryResult = ApolloReactCommon.QueryResult<BoardGetQuery, BoardGetQueryVariables>;
export const BoardGetManyDocument = gql`
    query BoardGetMany($ids: [ID!]!) {
  data: boardsByID(ids: $ids) {
    ...BoardFragment
  }
}
    ${BoardFragmentFragmentDoc}`;

/**
 * __useBoardGetManyQuery__
 *
 * To run a query within a React component, call `useBoardGetManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useBoardGetManyQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBoardGetManyQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useBoardGetManyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BoardGetManyQuery, BoardGetManyQueryVariables>) {
        return ApolloReactHooks.useQuery<BoardGetManyQuery, BoardGetManyQueryVariables>(BoardGetManyDocument, baseOptions);
      }
export function useBoardGetManyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BoardGetManyQuery, BoardGetManyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BoardGetManyQuery, BoardGetManyQueryVariables>(BoardGetManyDocument, baseOptions);
        }
export type BoardGetManyQueryHookResult = ReturnType<typeof useBoardGetManyQuery>;
export type BoardGetManyLazyQueryHookResult = ReturnType<typeof useBoardGetManyLazyQuery>;
export type BoardGetManyQueryResult = ApolloReactCommon.QueryResult<BoardGetManyQuery, BoardGetManyQueryVariables>;
export const DailyCommentByUserGetListDocument = gql`
    query DailyCommentByUserGetList($filterBy: DailyCommentByUserFilters, $orderBy: DailyCommentByUserOrder, $pagination: Pagination) {
  data: dailyCommentByUsers(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: nodes {
      ...DailyCommentByUserFragment
    }
    total: totalCount
  }
}
    ${DailyCommentByUserFragmentFragmentDoc}`;

/**
 * __useDailyCommentByUserGetListQuery__
 *
 * To run a query within a React component, call `useDailyCommentByUserGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDailyCommentByUserGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDailyCommentByUserGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useDailyCommentByUserGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DailyCommentByUserGetListQuery, DailyCommentByUserGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<DailyCommentByUserGetListQuery, DailyCommentByUserGetListQueryVariables>(DailyCommentByUserGetListDocument, baseOptions);
      }
export function useDailyCommentByUserGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DailyCommentByUserGetListQuery, DailyCommentByUserGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DailyCommentByUserGetListQuery, DailyCommentByUserGetListQueryVariables>(DailyCommentByUserGetListDocument, baseOptions);
        }
export type DailyCommentByUserGetListQueryHookResult = ReturnType<typeof useDailyCommentByUserGetListQuery>;
export type DailyCommentByUserGetListLazyQueryHookResult = ReturnType<typeof useDailyCommentByUserGetListLazyQuery>;
export type DailyCommentByUserGetListQueryResult = ApolloReactCommon.QueryResult<DailyCommentByUserGetListQuery, DailyCommentByUserGetListQueryVariables>;
export const DailyPostByUserGetListDocument = gql`
    query DailyPostByUserGetList($filterBy: DailyPostByUserFilters, $orderBy: DailyPostByUserOrder, $pagination: Pagination) {
  data: dailyPostByUsers(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: nodes {
      ...DailyPostByUserFragment
    }
    total: totalCount
  }
}
    ${DailyPostByUserFragmentFragmentDoc}`;

/**
 * __useDailyPostByUserGetListQuery__
 *
 * To run a query within a React component, call `useDailyPostByUserGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useDailyPostByUserGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDailyPostByUserGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useDailyPostByUserGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DailyPostByUserGetListQuery, DailyPostByUserGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<DailyPostByUserGetListQuery, DailyPostByUserGetListQueryVariables>(DailyPostByUserGetListDocument, baseOptions);
      }
export function useDailyPostByUserGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DailyPostByUserGetListQuery, DailyPostByUserGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DailyPostByUserGetListQuery, DailyPostByUserGetListQueryVariables>(DailyPostByUserGetListDocument, baseOptions);
        }
export type DailyPostByUserGetListQueryHookResult = ReturnType<typeof useDailyPostByUserGetListQuery>;
export type DailyPostByUserGetListLazyQueryHookResult = ReturnType<typeof useDailyPostByUserGetListLazyQuery>;
export type DailyPostByUserGetListQueryResult = ApolloReactCommon.QueryResult<DailyPostByUserGetListQuery, DailyPostByUserGetListQueryVariables>;
export const PostCollectionGetListDocument = gql`
    query PostCollectionGetList($filterBy: PostCollectionFiltersInput, $orderBy: PostCollectionOrder, $pagination: Pagination) {
  data: postCollections(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: nodes {
      ...PostCollectionFragment
    }
    total: totalCount
  }
}
    ${PostCollectionFragmentFragmentDoc}`;

/**
 * __usePostCollectionGetListQuery__
 *
 * To run a query within a React component, call `usePostCollectionGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostCollectionGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostCollectionGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function usePostCollectionGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PostCollectionGetListQuery, PostCollectionGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<PostCollectionGetListQuery, PostCollectionGetListQueryVariables>(PostCollectionGetListDocument, baseOptions);
      }
export function usePostCollectionGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PostCollectionGetListQuery, PostCollectionGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PostCollectionGetListQuery, PostCollectionGetListQueryVariables>(PostCollectionGetListDocument, baseOptions);
        }
export type PostCollectionGetListQueryHookResult = ReturnType<typeof usePostCollectionGetListQuery>;
export type PostCollectionGetListLazyQueryHookResult = ReturnType<typeof usePostCollectionGetListLazyQuery>;
export type PostCollectionGetListQueryResult = ApolloReactCommon.QueryResult<PostCollectionGetListQuery, PostCollectionGetListQueryVariables>;
export const PostCollectionGetDocument = gql`
    query PostCollectionGet($id: ID!) {
  data: postCollection(id: $id) {
    ...PostCollectionFragment
  }
}
    ${PostCollectionFragmentFragmentDoc}`;

/**
 * __usePostCollectionGetQuery__
 *
 * To run a query within a React component, call `usePostCollectionGetQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostCollectionGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostCollectionGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePostCollectionGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PostCollectionGetQuery, PostCollectionGetQueryVariables>) {
        return ApolloReactHooks.useQuery<PostCollectionGetQuery, PostCollectionGetQueryVariables>(PostCollectionGetDocument, baseOptions);
      }
export function usePostCollectionGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PostCollectionGetQuery, PostCollectionGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PostCollectionGetQuery, PostCollectionGetQueryVariables>(PostCollectionGetDocument, baseOptions);
        }
export type PostCollectionGetQueryHookResult = ReturnType<typeof usePostCollectionGetQuery>;
export type PostCollectionGetLazyQueryHookResult = ReturnType<typeof usePostCollectionGetLazyQuery>;
export type PostCollectionGetQueryResult = ApolloReactCommon.QueryResult<PostCollectionGetQuery, PostCollectionGetQueryVariables>;
export const PostCollectionGetManyDocument = gql`
    query PostCollectionGetMany($ids: [ID!]!) {
  data: postCollectionsByID(ids: $ids) {
    ...PostCollectionFragment
  }
}
    ${PostCollectionFragmentFragmentDoc}`;

/**
 * __usePostCollectionGetManyQuery__
 *
 * To run a query within a React component, call `usePostCollectionGetManyQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostCollectionGetManyQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostCollectionGetManyQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function usePostCollectionGetManyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PostCollectionGetManyQuery, PostCollectionGetManyQueryVariables>) {
        return ApolloReactHooks.useQuery<PostCollectionGetManyQuery, PostCollectionGetManyQueryVariables>(PostCollectionGetManyDocument, baseOptions);
      }
export function usePostCollectionGetManyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PostCollectionGetManyQuery, PostCollectionGetManyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PostCollectionGetManyQuery, PostCollectionGetManyQueryVariables>(PostCollectionGetManyDocument, baseOptions);
        }
export type PostCollectionGetManyQueryHookResult = ReturnType<typeof usePostCollectionGetManyQuery>;
export type PostCollectionGetManyLazyQueryHookResult = ReturnType<typeof usePostCollectionGetManyLazyQuery>;
export type PostCollectionGetManyQueryResult = ApolloReactCommon.QueryResult<PostCollectionGetManyQuery, PostCollectionGetManyQueryVariables>;
export const PostCollectionCreateDocument = gql`
    mutation PostCollectionCreate($input: PostCollectionInput!) {
  data: postCollectionCreate(input: $input) {
    data: postCollection {
      ...PostCollectionFragment
    }
  }
}
    ${PostCollectionFragmentFragmentDoc}`;
export type PostCollectionCreateMutationFn = ApolloReactCommon.MutationFunction<PostCollectionCreateMutation, PostCollectionCreateMutationVariables>;

/**
 * __usePostCollectionCreateMutation__
 *
 * To run a mutation, you first call `usePostCollectionCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostCollectionCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postCollectionCreateMutation, { data, loading, error }] = usePostCollectionCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePostCollectionCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostCollectionCreateMutation, PostCollectionCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<PostCollectionCreateMutation, PostCollectionCreateMutationVariables>(PostCollectionCreateDocument, baseOptions);
      }
export type PostCollectionCreateMutationHookResult = ReturnType<typeof usePostCollectionCreateMutation>;
export type PostCollectionCreateMutationResult = ApolloReactCommon.MutationResult<PostCollectionCreateMutation>;
export type PostCollectionCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<PostCollectionCreateMutation, PostCollectionCreateMutationVariables>;
export const PostCollectionUpdateDocument = gql`
    mutation PostCollectionUpdate($id: ID!, $input: PostCollectionInput!) {
  data: postCollectionUpdate(id: $id, input: $input) {
    data: postCollection {
      ...PostCollectionFragment
    }
  }
}
    ${PostCollectionFragmentFragmentDoc}`;
export type PostCollectionUpdateMutationFn = ApolloReactCommon.MutationFunction<PostCollectionUpdateMutation, PostCollectionUpdateMutationVariables>;

/**
 * __usePostCollectionUpdateMutation__
 *
 * To run a mutation, you first call `usePostCollectionUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostCollectionUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postCollectionUpdateMutation, { data, loading, error }] = usePostCollectionUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePostCollectionUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostCollectionUpdateMutation, PostCollectionUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<PostCollectionUpdateMutation, PostCollectionUpdateMutationVariables>(PostCollectionUpdateDocument, baseOptions);
      }
export type PostCollectionUpdateMutationHookResult = ReturnType<typeof usePostCollectionUpdateMutation>;
export type PostCollectionUpdateMutationResult = ApolloReactCommon.MutationResult<PostCollectionUpdateMutation>;
export type PostCollectionUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<PostCollectionUpdateMutation, PostCollectionUpdateMutationVariables>;
export const PostGetListDocument = gql`
    query PostGetList($filterBy: PostFiltersInput, $orderBy: PostOrder, $pagination: Pagination) {
  data: posts(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: nodes {
      ...PostFragment
    }
    total: totalCount
  }
}
    ${PostFragmentFragmentDoc}`;

/**
 * __usePostGetListQuery__
 *
 * To run a query within a React component, call `usePostGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function usePostGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PostGetListQuery, PostGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<PostGetListQuery, PostGetListQueryVariables>(PostGetListDocument, baseOptions);
      }
export function usePostGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PostGetListQuery, PostGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PostGetListQuery, PostGetListQueryVariables>(PostGetListDocument, baseOptions);
        }
export type PostGetListQueryHookResult = ReturnType<typeof usePostGetListQuery>;
export type PostGetListLazyQueryHookResult = ReturnType<typeof usePostGetListLazyQuery>;
export type PostGetListQueryResult = ApolloReactCommon.QueryResult<PostGetListQuery, PostGetListQueryVariables>;
export const PostGetDocument = gql`
    query PostGet($id: ID!) {
  data: post(id: $id) {
    ...PostFragment
  }
}
    ${PostFragmentFragmentDoc}`;

/**
 * __usePostGetQuery__
 *
 * To run a query within a React component, call `usePostGetQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePostGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PostGetQuery, PostGetQueryVariables>) {
        return ApolloReactHooks.useQuery<PostGetQuery, PostGetQueryVariables>(PostGetDocument, baseOptions);
      }
export function usePostGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PostGetQuery, PostGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PostGetQuery, PostGetQueryVariables>(PostGetDocument, baseOptions);
        }
export type PostGetQueryHookResult = ReturnType<typeof usePostGetQuery>;
export type PostGetLazyQueryHookResult = ReturnType<typeof usePostGetLazyQuery>;
export type PostGetQueryResult = ApolloReactCommon.QueryResult<PostGetQuery, PostGetQueryVariables>;
export const PostGetManyDocument = gql`
    query PostGetMany($ids: [ID!]!) {
  data: postsByID(ids: $ids) {
    ...PostFragment
  }
}
    ${PostFragmentFragmentDoc}`;

/**
 * __usePostGetManyQuery__
 *
 * To run a query within a React component, call `usePostGetManyQuery` and pass it any options that fit your needs.
 * When your component renders, `usePostGetManyQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePostGetManyQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function usePostGetManyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PostGetManyQuery, PostGetManyQueryVariables>) {
        return ApolloReactHooks.useQuery<PostGetManyQuery, PostGetManyQueryVariables>(PostGetManyDocument, baseOptions);
      }
export function usePostGetManyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PostGetManyQuery, PostGetManyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PostGetManyQuery, PostGetManyQueryVariables>(PostGetManyDocument, baseOptions);
        }
export type PostGetManyQueryHookResult = ReturnType<typeof usePostGetManyQuery>;
export type PostGetManyLazyQueryHookResult = ReturnType<typeof usePostGetManyLazyQuery>;
export type PostGetManyQueryResult = ApolloReactCommon.QueryResult<PostGetManyQuery, PostGetManyQueryVariables>;
export const PostUpdateDocument = gql`
    mutation PostUpdate($id: ID!, $input: PostUpdateInput!) {
  data: postUpdate(id: $id, input: $input) {
    data: post {
      ...PostFragment
    }
  }
}
    ${PostFragmentFragmentDoc}`;
export type PostUpdateMutationFn = ApolloReactCommon.MutationFunction<PostUpdateMutation, PostUpdateMutationVariables>;

/**
 * __usePostUpdateMutation__
 *
 * To run a mutation, you first call `usePostUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postUpdateMutation, { data, loading, error }] = usePostUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePostUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostUpdateMutation, PostUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<PostUpdateMutation, PostUpdateMutationVariables>(PostUpdateDocument, baseOptions);
      }
export type PostUpdateMutationHookResult = ReturnType<typeof usePostUpdateMutation>;
export type PostUpdateMutationResult = ApolloReactCommon.MutationResult<PostUpdateMutation>;
export type PostUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<PostUpdateMutation, PostUpdateMutationVariables>;
export const PostUpdateManyDocument = gql`
    mutation PostUpdateMany($ids: [ID!]!, $input: PostUpdateManyInput!) {
  data: postUpdateMany(ids: $ids, input: $input) {
    data: posts {
      ...PostFragment
    }
  }
}
    ${PostFragmentFragmentDoc}`;
export type PostUpdateManyMutationFn = ApolloReactCommon.MutationFunction<PostUpdateManyMutation, PostUpdateManyMutationVariables>;

/**
 * __usePostUpdateManyMutation__
 *
 * To run a mutation, you first call `usePostUpdateManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostUpdateManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postUpdateManyMutation, { data, loading, error }] = usePostUpdateManyMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePostUpdateManyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostUpdateManyMutation, PostUpdateManyMutationVariables>) {
        return ApolloReactHooks.useMutation<PostUpdateManyMutation, PostUpdateManyMutationVariables>(PostUpdateManyDocument, baseOptions);
      }
export type PostUpdateManyMutationHookResult = ReturnType<typeof usePostUpdateManyMutation>;
export type PostUpdateManyMutationResult = ApolloReactCommon.MutationResult<PostUpdateManyMutation>;
export type PostUpdateManyMutationOptions = ApolloReactCommon.BaseMutationOptions<PostUpdateManyMutation, PostUpdateManyMutationVariables>;
export const PostDeleteDocument = gql`
    mutation PostDelete($id: ID!) {
  data: postDelete(id: $id) {
    data: post {
      ...PostFragment
    }
  }
}
    ${PostFragmentFragmentDoc}`;
export type PostDeleteMutationFn = ApolloReactCommon.MutationFunction<PostDeleteMutation, PostDeleteMutationVariables>;

/**
 * __usePostDeleteMutation__
 *
 * To run a mutation, you first call `usePostDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postDeleteMutation, { data, loading, error }] = usePostDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePostDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostDeleteMutation, PostDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<PostDeleteMutation, PostDeleteMutationVariables>(PostDeleteDocument, baseOptions);
      }
export type PostDeleteMutationHookResult = ReturnType<typeof usePostDeleteMutation>;
export type PostDeleteMutationResult = ApolloReactCommon.MutationResult<PostDeleteMutation>;
export type PostDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<PostDeleteMutation, PostDeleteMutationVariables>;
export const PostDeleteManyDocument = gql`
    mutation PostDeleteMany($ids: [ID!]!) {
  data: postDeleteMany(ids: $ids) {
    data: posts {
      ...PostFragment
    }
  }
}
    ${PostFragmentFragmentDoc}`;
export type PostDeleteManyMutationFn = ApolloReactCommon.MutationFunction<PostDeleteManyMutation, PostDeleteManyMutationVariables>;

/**
 * __usePostDeleteManyMutation__
 *
 * To run a mutation, you first call `usePostDeleteManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostDeleteManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postDeleteManyMutation, { data, loading, error }] = usePostDeleteManyMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function usePostDeleteManyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostDeleteManyMutation, PostDeleteManyMutationVariables>) {
        return ApolloReactHooks.useMutation<PostDeleteManyMutation, PostDeleteManyMutationVariables>(PostDeleteManyDocument, baseOptions);
      }
export type PostDeleteManyMutationHookResult = ReturnType<typeof usePostDeleteManyMutation>;
export type PostDeleteManyMutationResult = ApolloReactCommon.MutationResult<PostDeleteManyMutation>;
export type PostDeleteManyMutationOptions = ApolloReactCommon.BaseMutationOptions<PostDeleteManyMutation, PostDeleteManyMutationVariables>;
export const TagGetDocument = gql`
    query TagGet($id: ID!) {
  data: tag(id: $id) {
    ...TagFragment
  }
}
    ${TagFragmentFragmentDoc}`;

/**
 * __useTagGetQuery__
 *
 * To run a query within a React component, call `useTagGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTagGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TagGetQuery, TagGetQueryVariables>) {
        return ApolloReactHooks.useQuery<TagGetQuery, TagGetQueryVariables>(TagGetDocument, baseOptions);
      }
export function useTagGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TagGetQuery, TagGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TagGetQuery, TagGetQueryVariables>(TagGetDocument, baseOptions);
        }
export type TagGetQueryHookResult = ReturnType<typeof useTagGetQuery>;
export type TagGetLazyQueryHookResult = ReturnType<typeof useTagGetLazyQuery>;
export type TagGetQueryResult = ApolloReactCommon.QueryResult<TagGetQuery, TagGetQueryVariables>;
export const TagGetListDocument = gql`
    query TagGetList($filterBy: TagFilters, $orderBy: TagOrder, $pagination: Pagination) {
  data: tags(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: nodes {
      ...TagFragment
    }
    total: totalCount
  }
}
    ${TagFragmentFragmentDoc}`;

/**
 * __useTagGetListQuery__
 *
 * To run a query within a React component, call `useTagGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useTagGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TagGetListQuery, TagGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<TagGetListQuery, TagGetListQueryVariables>(TagGetListDocument, baseOptions);
      }
export function useTagGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TagGetListQuery, TagGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TagGetListQuery, TagGetListQueryVariables>(TagGetListDocument, baseOptions);
        }
export type TagGetListQueryHookResult = ReturnType<typeof useTagGetListQuery>;
export type TagGetListLazyQueryHookResult = ReturnType<typeof useTagGetListLazyQuery>;
export type TagGetListQueryResult = ApolloReactCommon.QueryResult<TagGetListQuery, TagGetListQueryVariables>;
export const TagGetManyDocument = gql`
    query TagGetMany($ids: [ID!]!) {
  data: tagsByID(ids: $ids) {
    ...TagFragment
  }
}
    ${TagFragmentFragmentDoc}`;

/**
 * __useTagGetManyQuery__
 *
 * To run a query within a React component, call `useTagGetManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagGetManyQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagGetManyQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useTagGetManyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TagGetManyQuery, TagGetManyQueryVariables>) {
        return ApolloReactHooks.useQuery<TagGetManyQuery, TagGetManyQueryVariables>(TagGetManyDocument, baseOptions);
      }
export function useTagGetManyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TagGetManyQuery, TagGetManyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TagGetManyQuery, TagGetManyQueryVariables>(TagGetManyDocument, baseOptions);
        }
export type TagGetManyQueryHookResult = ReturnType<typeof useTagGetManyQuery>;
export type TagGetManyLazyQueryHookResult = ReturnType<typeof useTagGetManyLazyQuery>;
export type TagGetManyQueryResult = ApolloReactCommon.QueryResult<TagGetManyQuery, TagGetManyQueryVariables>;
export const TagCreateDocument = gql`
    mutation TagCreate($input: TagInput!) {
  data: tagCreate(input: $input) {
    data: tag {
      ...TagFragment
    }
  }
}
    ${TagFragmentFragmentDoc}`;
export type TagCreateMutationFn = ApolloReactCommon.MutationFunction<TagCreateMutation, TagCreateMutationVariables>;

/**
 * __useTagCreateMutation__
 *
 * To run a mutation, you first call `useTagCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTagCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tagCreateMutation, { data, loading, error }] = useTagCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTagCreateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TagCreateMutation, TagCreateMutationVariables>) {
        return ApolloReactHooks.useMutation<TagCreateMutation, TagCreateMutationVariables>(TagCreateDocument, baseOptions);
      }
export type TagCreateMutationHookResult = ReturnType<typeof useTagCreateMutation>;
export type TagCreateMutationResult = ApolloReactCommon.MutationResult<TagCreateMutation>;
export type TagCreateMutationOptions = ApolloReactCommon.BaseMutationOptions<TagCreateMutation, TagCreateMutationVariables>;
export const TagUpdateDocument = gql`
    mutation TagUpdate($id: ID!, $input: TagInput!) {
  data: tagUpdate(id: $id, input: $input) {
    data: tag {
      ...TagFragment
    }
  }
}
    ${TagFragmentFragmentDoc}`;
export type TagUpdateMutationFn = ApolloReactCommon.MutationFunction<TagUpdateMutation, TagUpdateMutationVariables>;

/**
 * __useTagUpdateMutation__
 *
 * To run a mutation, you first call `useTagUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTagUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tagUpdateMutation, { data, loading, error }] = useTagUpdateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTagUpdateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TagUpdateMutation, TagUpdateMutationVariables>) {
        return ApolloReactHooks.useMutation<TagUpdateMutation, TagUpdateMutationVariables>(TagUpdateDocument, baseOptions);
      }
export type TagUpdateMutationHookResult = ReturnType<typeof useTagUpdateMutation>;
export type TagUpdateMutationResult = ApolloReactCommon.MutationResult<TagUpdateMutation>;
export type TagUpdateMutationOptions = ApolloReactCommon.BaseMutationOptions<TagUpdateMutation, TagUpdateMutationVariables>;
export const TagDeleteDocument = gql`
    mutation TagDelete($id: ID!) {
  data: tagDelete(id: $id) {
    data: tag {
      ...TagFragment
    }
  }
}
    ${TagFragmentFragmentDoc}`;
export type TagDeleteMutationFn = ApolloReactCommon.MutationFunction<TagDeleteMutation, TagDeleteMutationVariables>;

/**
 * __useTagDeleteMutation__
 *
 * To run a mutation, you first call `useTagDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTagDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tagDeleteMutation, { data, loading, error }] = useTagDeleteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTagDeleteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TagDeleteMutation, TagDeleteMutationVariables>) {
        return ApolloReactHooks.useMutation<TagDeleteMutation, TagDeleteMutationVariables>(TagDeleteDocument, baseOptions);
      }
export type TagDeleteMutationHookResult = ReturnType<typeof useTagDeleteMutation>;
export type TagDeleteMutationResult = ApolloReactCommon.MutationResult<TagDeleteMutation>;
export type TagDeleteMutationOptions = ApolloReactCommon.BaseMutationOptions<TagDeleteMutation, TagDeleteMutationVariables>;
export const TagDeleteManyDocument = gql`
    mutation TagDeleteMany($ids: [ID!]!) {
  data: tagDeleteMany(ids: $ids) {
    data: tags {
      ...TagFragment
    }
  }
}
    ${TagFragmentFragmentDoc}`;
export type TagDeleteManyMutationFn = ApolloReactCommon.MutationFunction<TagDeleteManyMutation, TagDeleteManyMutationVariables>;

/**
 * __useTagDeleteManyMutation__
 *
 * To run a mutation, you first call `useTagDeleteManyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTagDeleteManyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [tagDeleteManyMutation, { data, loading, error }] = useTagDeleteManyMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useTagDeleteManyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TagDeleteManyMutation, TagDeleteManyMutationVariables>) {
        return ApolloReactHooks.useMutation<TagDeleteManyMutation, TagDeleteManyMutationVariables>(TagDeleteManyDocument, baseOptions);
      }
export type TagDeleteManyMutationHookResult = ReturnType<typeof useTagDeleteManyMutation>;
export type TagDeleteManyMutationResult = ApolloReactCommon.MutationResult<TagDeleteManyMutation>;
export type TagDeleteManyMutationOptions = ApolloReactCommon.BaseMutationOptions<TagDeleteManyMutation, TagDeleteManyMutationVariables>;
export const UserGetListDocument = gql`
    query UserGetList($filterBy: UserFilters, $orderBy: UserOrder, $pagination: Pagination) {
  data: users(filterBy: $filterBy, orderBy: $orderBy, pagination: $pagination) {
    data: nodes {
      ...UserFragment
    }
    total: totalCount
  }
}
    ${UserFragmentFragmentDoc}`;

/**
 * __useUserGetListQuery__
 *
 * To run a query within a React component, call `useUserGetListQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGetListQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGetListQuery({
 *   variables: {
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *      pagination: // value for 'pagination'
 *   },
 * });
 */
export function useUserGetListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserGetListQuery, UserGetListQueryVariables>) {
        return ApolloReactHooks.useQuery<UserGetListQuery, UserGetListQueryVariables>(UserGetListDocument, baseOptions);
      }
export function useUserGetListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserGetListQuery, UserGetListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserGetListQuery, UserGetListQueryVariables>(UserGetListDocument, baseOptions);
        }
export type UserGetListQueryHookResult = ReturnType<typeof useUserGetListQuery>;
export type UserGetListLazyQueryHookResult = ReturnType<typeof useUserGetListLazyQuery>;
export type UserGetListQueryResult = ApolloReactCommon.QueryResult<UserGetListQuery, UserGetListQueryVariables>;
export const UserGetDocument = gql`
    query UserGet($id: ID!) {
  data: user(id: $id) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;

/**
 * __useUserGetQuery__
 *
 * To run a query within a React component, call `useUserGetQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGetQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGetQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserGetQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserGetQuery, UserGetQueryVariables>) {
        return ApolloReactHooks.useQuery<UserGetQuery, UserGetQueryVariables>(UserGetDocument, baseOptions);
      }
export function useUserGetLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserGetQuery, UserGetQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserGetQuery, UserGetQueryVariables>(UserGetDocument, baseOptions);
        }
export type UserGetQueryHookResult = ReturnType<typeof useUserGetQuery>;
export type UserGetLazyQueryHookResult = ReturnType<typeof useUserGetLazyQuery>;
export type UserGetQueryResult = ApolloReactCommon.QueryResult<UserGetQuery, UserGetQueryVariables>;
export const UserGetManyDocument = gql`
    query UserGetMany($ids: [ID!]!) {
  data: usersByID(ids: $ids) {
    ...UserFragment
  }
}
    ${UserFragmentFragmentDoc}`;

/**
 * __useUserGetManyQuery__
 *
 * To run a query within a React component, call `useUserGetManyQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserGetManyQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserGetManyQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useUserGetManyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserGetManyQuery, UserGetManyQueryVariables>) {
        return ApolloReactHooks.useQuery<UserGetManyQuery, UserGetManyQueryVariables>(UserGetManyDocument, baseOptions);
      }
export function useUserGetManyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserGetManyQuery, UserGetManyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserGetManyQuery, UserGetManyQueryVariables>(UserGetManyDocument, baseOptions);
        }
export type UserGetManyQueryHookResult = ReturnType<typeof useUserGetManyQuery>;
export type UserGetManyLazyQueryHookResult = ReturnType<typeof useUserGetManyLazyQuery>;
export type UserGetManyQueryResult = ApolloReactCommon.QueryResult<UserGetManyQuery, UserGetManyQueryVariables>;
export const BestOfBestRequirementDocument = gql`
    query BestOfBestRequirement {
  bestOfBestRequirement {
    id
    ...RequirementInputs
  }
}
    ${RequirementInputsFragmentDoc}`;

/**
 * __useBestOfBestRequirementQuery__
 *
 * To run a query within a React component, call `useBestOfBestRequirementQuery` and pass it any options that fit your needs.
 * When your component renders, `useBestOfBestRequirementQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBestOfBestRequirementQuery({
 *   variables: {
 *   },
 * });
 */
export function useBestOfBestRequirementQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BestOfBestRequirementQuery, BestOfBestRequirementQueryVariables>) {
        return ApolloReactHooks.useQuery<BestOfBestRequirementQuery, BestOfBestRequirementQueryVariables>(BestOfBestRequirementDocument, baseOptions);
      }
export function useBestOfBestRequirementLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BestOfBestRequirementQuery, BestOfBestRequirementQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BestOfBestRequirementQuery, BestOfBestRequirementQueryVariables>(BestOfBestRequirementDocument, baseOptions);
        }
export type BestOfBestRequirementQueryHookResult = ReturnType<typeof useBestOfBestRequirementQuery>;
export type BestOfBestRequirementLazyQueryHookResult = ReturnType<typeof useBestOfBestRequirementLazyQuery>;
export type BestOfBestRequirementQueryResult = ApolloReactCommon.QueryResult<BestOfBestRequirementQuery, BestOfBestRequirementQueryVariables>;
export const BestPostsDocument = gql`
    query BestPosts($pagination: Pagination, $filterBy: PostFiltersInput, $orderBy: PostOrder) {
  bestOfBestPostPreviews(pagination: $pagination, filterBy: $filterBy, orderBy: $orderBy) {
    nodes {
      id
      createdAt
      views
      commentCount
      likes
      score
      scrapCount
      title
    }
  }
}
    `;

/**
 * __useBestPostsQuery__
 *
 * To run a query within a React component, call `useBestPostsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBestPostsQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBestPostsQuery({
 *   variables: {
 *      pagination: // value for 'pagination'
 *      filterBy: // value for 'filterBy'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useBestPostsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BestPostsQuery, BestPostsQueryVariables>) {
        return ApolloReactHooks.useQuery<BestPostsQuery, BestPostsQueryVariables>(BestPostsDocument, baseOptions);
      }
export function useBestPostsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BestPostsQuery, BestPostsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BestPostsQuery, BestPostsQueryVariables>(BestPostsDocument, baseOptions);
        }
export type BestPostsQueryHookResult = ReturnType<typeof useBestPostsQuery>;
export type BestPostsLazyQueryHookResult = ReturnType<typeof useBestPostsLazyQuery>;
export type BestPostsQueryResult = ApolloReactCommon.QueryResult<BestPostsQuery, BestPostsQueryVariables>;
export const UpdateBestOfBestRequirementDocument = gql`
    mutation UpdateBestOfBestRequirement($input: CollectionRequirementUpdateInput!) {
  updateBestOfBestRequirement(input: $input) {
    collectionRequirement {
      comment
      like
      scrap
      view
      id
    }
  }
}
    `;
export type UpdateBestOfBestRequirementMutationFn = ApolloReactCommon.MutationFunction<UpdateBestOfBestRequirementMutation, UpdateBestOfBestRequirementMutationVariables>;

/**
 * __useUpdateBestOfBestRequirementMutation__
 *
 * To run a mutation, you first call `useUpdateBestOfBestRequirementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBestOfBestRequirementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBestOfBestRequirementMutation, { data, loading, error }] = useUpdateBestOfBestRequirementMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateBestOfBestRequirementMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateBestOfBestRequirementMutation, UpdateBestOfBestRequirementMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateBestOfBestRequirementMutation, UpdateBestOfBestRequirementMutationVariables>(UpdateBestOfBestRequirementDocument, baseOptions);
      }
export type UpdateBestOfBestRequirementMutationHookResult = ReturnType<typeof useUpdateBestOfBestRequirementMutation>;
export type UpdateBestOfBestRequirementMutationResult = ApolloReactCommon.MutationResult<UpdateBestOfBestRequirementMutation>;
export type UpdateBestOfBestRequirementMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateBestOfBestRequirementMutation, UpdateBestOfBestRequirementMutationVariables>;
export const PostCollectionRebuildDocument = gql`
    mutation PostCollectionRebuild($id: ID!, $automation: PostCollectionAutomationInput) {
  postCollectionRebuild(id: $id, automation: $automation) {
    postCollection {
      id
    }
  }
}
    `;
export type PostCollectionRebuildMutationFn = ApolloReactCommon.MutationFunction<PostCollectionRebuildMutation, PostCollectionRebuildMutationVariables>;

/**
 * __usePostCollectionRebuildMutation__
 *
 * To run a mutation, you first call `usePostCollectionRebuildMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePostCollectionRebuildMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [postCollectionRebuildMutation, { data, loading, error }] = usePostCollectionRebuildMutation({
 *   variables: {
 *      id: // value for 'id'
 *      automation: // value for 'automation'
 *   },
 * });
 */
export function usePostCollectionRebuildMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PostCollectionRebuildMutation, PostCollectionRebuildMutationVariables>) {
        return ApolloReactHooks.useMutation<PostCollectionRebuildMutation, PostCollectionRebuildMutationVariables>(PostCollectionRebuildDocument, baseOptions);
      }
export type PostCollectionRebuildMutationHookResult = ReturnType<typeof usePostCollectionRebuildMutation>;
export type PostCollectionRebuildMutationResult = ApolloReactCommon.MutationResult<PostCollectionRebuildMutation>;
export type PostCollectionRebuildMutationOptions = ApolloReactCommon.BaseMutationOptions<PostCollectionRebuildMutation, PostCollectionRebuildMutationVariables>;
export const ConfigMessageDocument = gql`
    query ConfigMessage {
  configLoginAlertDescription: config(id: "message.login.alertDescription") {
    ...ConfigFragment
  }
  configGlobalNoticeBaseURL: config(id: "message.globalNotice.baseURL") {
    ...ConfigFragment
  }
  configPostDetailSiteName: config(id: "message.postDetail.siteName") {
    ...ConfigFragment
  }
  configBestBoardNoticeTitle: config(id: "message.bestBoard.notice.title") {
    ...ConfigFragment
  }
}
    ${ConfigFragmentFragmentDoc}`;

/**
 * __useConfigMessageQuery__
 *
 * To run a query within a React component, call `useConfigMessageQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfigMessageQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfigMessageQuery({
 *   variables: {
 *   },
 * });
 */
export function useConfigMessageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ConfigMessageQuery, ConfigMessageQueryVariables>) {
        return ApolloReactHooks.useQuery<ConfigMessageQuery, ConfigMessageQueryVariables>(ConfigMessageDocument, baseOptions);
      }
export function useConfigMessageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ConfigMessageQuery, ConfigMessageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ConfigMessageQuery, ConfigMessageQueryVariables>(ConfigMessageDocument, baseOptions);
        }
export type ConfigMessageQueryHookResult = ReturnType<typeof useConfigMessageQuery>;
export type ConfigMessageLazyQueryHookResult = ReturnType<typeof useConfigMessageLazyQuery>;
export type ConfigMessageQueryResult = ApolloReactCommon.QueryResult<ConfigMessageQuery, ConfigMessageQueryVariables>;
export const ConfigSeoDocument = gql`
    query ConfigSEO {
  configSEODefaultTitle: config(id: "seo.default.title") {
    ...ConfigFragment
  }
  configSEODefaultDescription: config(id: "seo.default.description") {
    ...ConfigFragment
  }
  configSEOOpengraphTitle: config(id: "seo.opengraph.title") {
    ...ConfigFragment
  }
  configSEOOpengraphDescription: config(id: "seo.opengraph.description") {
    ...ConfigFragment
  }
  configSEOOpengraphURL: config(id: "seo.opengraph.URL") {
    ...ConfigFragment
  }
  configSEOOpengraphSiteName: config(id: "seo.opengraph.siteName") {
    ...ConfigFragment
  }
}
    ${ConfigFragmentFragmentDoc}`;

/**
 * __useConfigSeoQuery__
 *
 * To run a query within a React component, call `useConfigSeoQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfigSeoQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfigSeoQuery({
 *   variables: {
 *   },
 * });
 */
export function useConfigSeoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ConfigSeoQuery, ConfigSeoQueryVariables>) {
        return ApolloReactHooks.useQuery<ConfigSeoQuery, ConfigSeoQueryVariables>(ConfigSeoDocument, baseOptions);
      }
export function useConfigSeoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ConfigSeoQuery, ConfigSeoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ConfigSeoQuery, ConfigSeoQueryVariables>(ConfigSeoDocument, baseOptions);
        }
export type ConfigSeoQueryHookResult = ReturnType<typeof useConfigSeoQuery>;
export type ConfigSeoLazyQueryHookResult = ReturnType<typeof useConfigSeoLazyQuery>;
export type ConfigSeoQueryResult = ApolloReactCommon.QueryResult<ConfigSeoQuery, ConfigSeoQueryVariables>;
export const ConfigUiDocument = gql`
    query ConfigUI {
  configHeaderURL: config(id: "config.header") {
    ...ConfigFragment
  }
  configHeaderDefaultHeight: config(id: "config.header.defaultHeight") {
    ...ConfigFragment
  }
  configHeaderExpandedHeight: config(id: "config.header.expandedHeight") {
    ...ConfigFragment
  }
  configFooterURL: config(id: "config.footer") {
    ...ConfigFragment
  }
  configFooterDefaultHeight: config(id: "config.footer.defaultHeight") {
    ...ConfigFragment
  }
}
    ${ConfigFragmentFragmentDoc}`;

/**
 * __useConfigUiQuery__
 *
 * To run a query within a React component, call `useConfigUiQuery` and pass it any options that fit your needs.
 * When your component renders, `useConfigUiQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConfigUiQuery({
 *   variables: {
 *   },
 * });
 */
export function useConfigUiQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ConfigUiQuery, ConfigUiQueryVariables>) {
        return ApolloReactHooks.useQuery<ConfigUiQuery, ConfigUiQueryVariables>(ConfigUiDocument, baseOptions);
      }
export function useConfigUiLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ConfigUiQuery, ConfigUiQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ConfigUiQuery, ConfigUiQueryVariables>(ConfigUiDocument, baseOptions);
        }
export type ConfigUiQueryHookResult = ReturnType<typeof useConfigUiQuery>;
export type ConfigUiLazyQueryHookResult = ReturnType<typeof useConfigUiLazyQuery>;
export type ConfigUiQueryResult = ApolloReactCommon.QueryResult<ConfigUiQuery, ConfigUiQueryVariables>;