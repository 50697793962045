import React from 'react';
import { Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { ColumnUserBanTable } from '../table/column/ColumnUserBan';
import { BanLogsQueryVariables } from 'generated/graphql';
import { Fields, SortDirection } from '../../module/constant/SortOrderBy';
import { useHistory, useLocation } from 'react-router-dom';
import convertPaginiationQueryString from 'app.module/module/convertPaginiationQueryString';

type TProps = {
  dataSource: any;
  onCheckedUser: ({ selectedUser }) => void;
  onRefetch: (variables?: BanLogsQueryVariables | undefined) => void;
  totalCount: any;
};

const TableUserBanTable: React.FC<TProps> = ({
  dataSource,
  onCheckedUser,
  onRefetch,
  totalCount,
}) => {
  const tableColumns: ColumnsType<any> = ColumnUserBanTable();
  const router = useHistory();
  const { search } = useLocation();
  const pagination: any = convertPaginiationQueryString(search);

  const handleClickedUser = (selectedUser) => {
    onCheckedUser(selectedUser);
  };

  const rowSelection = {
    onChange: (_, selectedRows) => {
      handleClickedUser(selectedRows);
    },
  };

  const paginationOptions = {
    pageSize: Number(pagination.pageSize) || 10,
    current: Number(pagination.page) || 1,
    total: totalCount,
    showSizeChanger: true,
    onChange(page, pageSize) {
      router.push(`?page=${page}&pageSize=${pageSize}`);
      onRefetch({
        pagination: {
          page,
          pageSize,
        },
      });
    },
  };

  const handleTableSortingChange = (...restParams: any) => {
    if (restParams[3].action !== 'sort') return;
    const field = Fields[restParams[2].field];
    const direction = SortDirection[restParams[2].order];
    onRefetch({
      pagination: {
        page: Number(pagination.page) || 1,
        pageSize: Number(pagination.pageSize) || 10,
      },
      orderBy: {
        field,
        direction,
      },
    });
  };

  return (
    <Table
      bordered
      dataSource={dataSource}
      columns={tableColumns}
      rowSelection={rowSelection}
      pagination={paginationOptions}
      onChange={handleTableSortingChange}
      size="small"
    />
  );
};

export default TableUserBanTable;
