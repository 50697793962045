import React, { FC } from 'react'
import {
  List,
  Datagrid,
  TextField,
  NumberField,
  ShowButton,
  Filter,
  TextInput,
} from 'react-admin'

const UserFilter: FC = props => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
)

const UserList: FC = props => (
  <List {...props} filters={<UserFilter />}>
    <Datagrid>
      <TextField source="id" label="msg.id" />
      <TextField source="username" label="msg.username" sortable={false} />
      <TextField source="nickname" label="msg.nickname" sortable={false} />
      <NumberField source="posts.totalCount" sortable={false} />
      <ShowButton />
    </Datagrid>
  </List>
)

export default UserList
