import gql from 'graphql-tag'
import { DailyPostByUserGetListDocument } from 'generated/graphql'
import { QueryBuilder } from './base.query'

export function createDailyPostQueryBuilder(): QueryBuilder {
  return {
    getList: {
      query: DailyPostByUserGetListDocument,
    },
  }
}

gql`
  fragment DailyPostByUserFragment on Post {
    id
    ipaddress
    createdAt
    nickname
    author {
      id
      email
      username
      nickname
    }
    board {
      id
      title
    }
  }
`

gql`
  query DailyPostByUserGetList(
    $filterBy: DailyPostByUserFilters
    $orderBy: DailyPostByUserOrder
    $pagination: Pagination
  ) {
    data: dailyPostByUsers(
      filterBy: $filterBy
      orderBy: $orderBy
      pagination: $pagination
    ) {
      data: nodes {
        ...DailyPostByUserFragment
      }
      total: totalCount
    }
  }
`
