import gql from 'graphql-tag'
import {
  TagGetDocument,
  TagGetListDocument,
  TagGetManyDocument,
  TagCreateDocument,
  TagUpdateDocument,
  TagDeleteDocument,
  TagDeleteManyDocument,
} from 'generated/graphql'
import { QueryBuilder } from './base.query'

export function createTagQueryBuilder(): QueryBuilder {
  return {
    getOne: {
      query: TagGetDocument,
    },
    getList: {
      query: TagGetListDocument,
    },
    getMany: {
      query: TagGetManyDocument,
    },
    create: {
      query: TagCreateDocument,
    },
    update: {
      query: TagUpdateDocument,
    },
    delete: {
      query: TagDeleteDocument,
    },
    deleteMany: {
      query: TagDeleteManyDocument,
    },
  }
}

gql`
  fragment TagFragment on Tag {
    id
    name
    createdAt
    updatedAt
  }
`

gql`
  query TagGet($id: ID!) {
    data: tag(id: $id) {
      ...TagFragment
    }
  }
`

gql`
  query TagGetList(
    $filterBy: TagFilters
    $orderBy: TagOrder
    $pagination: Pagination
  ) {
    data: tags(
      filterBy: $filterBy
      orderBy: $orderBy
      pagination: $pagination
    ) {
      data: nodes {
        ...TagFragment
      }
      total: totalCount
    }
  }
`

gql`
  query TagGetMany($ids: [ID!]!) {
    data: tagsByID(ids: $ids) {
      ...TagFragment
    }
  }
`

gql`
  mutation TagCreate($input: TagInput!) {
    data: tagCreate(input: $input) {
      data: tag {
        ...TagFragment
      }
    }
  }
`

gql`
  mutation TagUpdate($id: ID!, $input: TagInput!) {
    data: tagUpdate(id: $id, input: $input) {
      data: tag {
        ...TagFragment
      }
    }
  }
`

gql`
  mutation TagDelete($id: ID!) {
    data: tagDelete(id: $id) {
      data: tag {
        ...TagFragment
      }
    }
  }
`

gql`
  mutation TagDeleteMany($ids: [ID!]!) {
    data: tagDeleteMany(ids: $ids) {
      data: tags {
        ...TagFragment
      }
    }
  }
`
