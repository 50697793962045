import React from 'react'
import DropdownReportUser from 'app.feature/board/report/component/dropdown/DropdownReportUser'
import { Link, useLocation } from 'react-router-dom'

const ColumnsReportComment = () => {
  const { search } = useLocation()

  return [
    {
      title: '아이디',
      key: 'id',
      dataIndex: 'id',
      render: (_, record) => (
        <DropdownReportUser id={record.id} nickname={record.nickname} />
      ),
    },
    {
      title: '닉네임',
      dataIndex: 'nickname',
      key: 'nickname',
    },
    {
      title: '내용',
      dataIndex: 'originContentText',
      key: 'originContentText',
      render: (originContentText, record) => {
        return (
          <div style={{ width: '500px' }}>
            <Link to={`/reported-comment/detail/${record.key}${search}`}>
              {originContentText}
            </Link>
          </div>
        )
      },
    },
    {
      title: '최근 신고 사유',
      dataIndex: 'reportDescription',
      key: 'reportDescription',
    },
    {
      title: '신고일시',
      dataIndex: 'lastReportedAt',
      key: 'lastReportedAt',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: '누적 신고수',
      dataIndex: 'reportCount',
      key: 'reportCount',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'IP 주소',
      dataIndex: 'ipaddress',
      key: 'ipaddress',
    },
  ]
}

export default ColumnsReportComment
