import React from 'react'
import { Table } from 'antd'
import { format } from 'date-fns'
import {
  CommentTrashOrderField,
  TrashedCommentsQueryVariables,
} from 'generated/graphql'
import { useHistory, useLocation } from 'react-router-dom'
import convertPaginiationQueryString from 'app.module/module/convertPaginiationQueryString'
import { SortDirection } from 'app.feature/board/delete/module/constant/sortOrderBy'
import ColumnDeletedComment from '../column/ColumnDeletedComment'

type TProps = {
  data: any
  total: number | undefined
  onRefetch: (variables?: TrashedCommentsQueryVariables | undefined) => void
}

const TableDeletedComment: React.FC<TProps> = ({ data, total, onRefetch }) => {
  const dataSource = data?.map(item => {
    return {
      key: item?.id,
      id: item?.author?.id,
      nickname: item?.nickname,
      contentTextForAdmin: item?.contentTextForAdmin,
      boardTitle: item?.board?.title,
      deletedAt: format(new Date(item?.deletedAt), 'yyyy-MM-dd HH:mm'),
    }
  })

  const tableColumns: any = ColumnDeletedComment()
  const router = useHistory()
  const { search } = useLocation()
  const pagination: any = convertPaginiationQueryString(search)

  const paginationOptions = {
    pageSize: Number(pagination.pageSize || 10),
    current: Number(pagination.page) || 1,
    total,
    onChange(page, pageSize) {
      router.push(`?page=${page}&pageSize=${pageSize}`)
      onRefetch({
        pagination: {
          page,
          pageSize,
        },
      })
    },
  }

  const handleChangeTableSorting = (...restParams: any) => {
    if (restParams[3].action !== 'sort') return
    const direction = SortDirection[restParams[2].order]

    onRefetch({
      pagination: {
        page: Number(pagination.page) || 1,
        pageSize: Number(pagination.pageSize) || 10,
      },
      orderBy: {
        field: CommentTrashOrderField['DELETED_AT'],
        direction,
      },
    })
  }

  return (
    <Table
      bordered
      size="small"
      dataSource={dataSource}
      columns={tableColumns}
      onChange={handleChangeTableSorting}
      pagination={paginationOptions}
      locale={{ emptyText: '데이터가 없습니다.' }}
    />
  )
}

export default TableDeletedComment
