import React from 'react';
import { Route } from 'react-router-dom';
import ConfigSEOScreen from './screens/ConfigSEOScreen';
import ConfigMessageScreen from './screens/ConfigMessageScreen';
import ConfigUIScreen from './screens/ConfigUIScreen';
import ScreenBestPostsManagement from 'resources/best-posts/screen/ScreenBestPostsManagement';
import ScreenUsersBanManagement from 'app.feature/user/ban/screen/ScreenUsersBanManagement';
import ScreenReportPost from 'app.feature/board/report/rt.post/screen/ScreenReportPost';
import ScreenDetailReportPost from 'app.feature/board/report/rt.post/screen/ScreenDetailReportPost';
import ScreenReportComment from 'app.feature/board/report/rt.comment/screen/ScreenReportComment';
import ScreenDetailReportComment from 'app.feature/board/report/rt.comment/screen/ScreenDetailReportComment';
import ScreenDeletedPost from 'app.feature/board/delete/dt.post/screen/ScreenDeletedPost';
import ScreenDetailDeletedPost from 'app.feature/board/delete/dt.post/screen/ScreenDetailDeletedPost';
import ScreenDeletedComment from 'app.feature/board/delete/dt.comment/screen/ScreenDeletedComment';

export default [
  <Route exact path="/config/seo" component={ConfigSEOScreen} />,
  <Route exact path="/config/message" component={ConfigMessageScreen} />,
  <Route exact path="/config/ui" component={ConfigUIScreen} />,
  <Route exact path="/best-posts" component={ScreenBestPostsManagement} />,
  <Route exact path="/user-ban" component={ScreenUsersBanManagement} />,
  <Route exact path="/reported-post" component={ScreenReportPost} />,
  <Route path="/reported-post/detail/:id" component={ScreenDetailReportPost} />,
  <Route exact path="/reported-comment" component={ScreenReportComment} />,
  <Route exact path="/deleted-post" component={ScreenDeletedPost} />,
  <Route path="/deleted-post/detail/:id" component={ScreenDetailDeletedPost} />,
  <Route exact path="/deleted-comment" component={ScreenDeletedComment} />,
  <Route
    path="/reported-comment/detail/:id"
    component={ScreenDetailReportComment}
  />,
];
