import React from 'react'
import { Table } from 'antd'
import { format } from 'date-fns'
import { useHistory, useLocation } from 'react-router-dom'
import convertPaginiationQueryString from 'app.module/module/convertPaginiationQueryString'
import ColumnDeletedPost from '../column/ColumnDeletedPost'
import { SortDirection } from 'app.feature/board/delete/module/constant/sortOrderBy'

type TProps = {
  data: any
  total: number | undefined
  onDirectionChange: (field, direction) => void
}

const TableDeletedPost: React.FC<TProps> = ({
  data,
  total,
  onDirectionChange,
}) => {
  const dataSource = data?.map(item => {
    return {
      key: item?.id,
      id: item?.author?.id,
      nickname: item?.nickname,
      title: item?.title,
      boardTitle: item?.board?.title,
      deletedAt: format(new Date(item?.deletedAt), 'yyyy-MM-dd HH:mm'),
    }
  })

  const tableColumns: any = ColumnDeletedPost()
  const router = useHistory()
  const { search } = useLocation()
  const pagination: any = convertPaginiationQueryString(search)

  const paginationOptions = {
    pageSize: Number(pagination.pageSize || 10),
    current: Number(pagination.page) || 1,
    total,
    onChange(page, pageSize) {
      router.push(`?page=${page}&pageSize=${pageSize}`)
    },
  }

  const onChangeTabSorting = (...restParams: any) => {
    if (restParams[3].action !== 'sort') return
    const direction = SortDirection[restParams[2].order]
    onDirectionChange('DELETED_AT', direction)
  }

  return (
    <Table
      bordered
      size="small"
      dataSource={dataSource}
      columns={tableColumns}
      onChange={onChangeTabSorting}
      pagination={paginationOptions}
      locale={{ emptyText: '데이터가 없습니다.' }}
    />
  )
}

export default TableDeletedPost
