import React, { FunctionComponent } from 'react'
import gql from 'graphql-tag'
import { useConfigMessageQuery } from '../generated/graphql'
import ConfigEditor from '../components/ConfigEditor'
import { makeStyles } from '@material-ui/core/styles'

const ConfigSEOScreen: FunctionComponent = () => {
  const { data, loading } = useConfigMessageQuery()
  const useStyles = makeStyles({
    root: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
  })

  const classes = useStyles()
  if (loading) {
    return <div>Loading...</div>
  }

  if (!data) {
    return <div>Something went wrong :(</div>
  }

  return (
    <div className={classes.root}>
      <h1>Message 설정</h1>
      <ConfigEditor config={data.configLoginAlertDescription!} />
      <ConfigEditor config={data.configGlobalNoticeBaseURL!} />
      <ConfigEditor config={data.configPostDetailSiteName!} />
      <ConfigEditor config={data.configBestBoardNoticeTitle!} />
    </div>
  )
}

export default ConfigSEOScreen

gql`
  query ConfigMessage {
    configLoginAlertDescription: config(id: "message.login.alertDescription") {
      ...ConfigFragment
    }
    configGlobalNoticeBaseURL: config(id: "message.globalNotice.baseURL") {
      ...ConfigFragment
    }
    configPostDetailSiteName: config(id: "message.postDetail.siteName") {
      ...ConfigFragment
    }
    configBestBoardNoticeTitle: config(id: "message.bestBoard.notice.title") {
      ...ConfigFragment
    }
  }
`
