import { Button, InputProps } from 'antd'
import { RequirementInputsFragment } from 'generated/graphql'
import React, { FC } from 'react'
import styled from 'styled-components'
import FormInputs from './FormInputs'

interface RequirementInputsProps {
  initialValue: RequirementInputsFragment
  disabled: boolean
  onInputChange: () => void
  onRefetch: () => void
}
const RequirementInputs: FC<RequirementInputsProps> = ({
  initialValue,
  disabled,
  onInputChange,
  onRefetch,
}) => {
  return (
    <StyledWrapper>
      <FormInputs
        disabled={disabled}
        onChange={onInputChange}
        initialValue={initialValue.view}
        label="조회 수"
        name="view"
      />
      <FormInputs
        disabled={disabled}
        onChange={onInputChange}
        initialValue={initialValue.comment}
        label="댓글 수"
        name="comment"
      />
      <FormInputs
        disabled={disabled}
        onChange={onInputChange}
        initialValue={initialValue.like}
        label="추천 수"
        name="like"
      />
      <FormInputs
        disabled={disabled}
        onChange={onInputChange}
        initialValue={initialValue.scrap}
        label="스크랩 수"
        name="scrap"
      />
      <Button
        className="refetch-button"
        disabled={disabled}
        onClick={onRefetch}
      >
        조회
      </Button>
    </StyledWrapper>
  )
}

export default RequirementInputs

const StyledWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  .refetch-button {
    margin-left: 10px;
    margin-top: 4px;
  }
`
