import React, {
  FunctionComponent,
  ReactElement,
  Fragment,
  useState,
} from 'react'
import {
  List,
  MenuItem,
  ListItemIcon,
  Typography,
  Divider,
  Collapse,
  Tooltip,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ExpandMore } from '@material-ui/icons'

import { useTranslate } from 'react-admin'

const useStyles = makeStyles(theme => ({
  icon: { minWidth: theme.spacing(5) },
  sidebarIsOpen: {
    paddingLeft: 25,
    transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  },
  sidebarIsClosed: {
    paddingLeft: 0,
    transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  },
}))

interface SubMenuProps {
  sidebarIsOpen: boolean
  name: string
  icon: ReactElement
  dense: boolean
}

const SubMenu: FunctionComponent<SubMenuProps> = ({
  sidebarIsOpen,
  name,
  icon,
  children,
  dense,
}) => {
  const translate = useTranslate()
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen)
  }

  const header = (
    <MenuItem dense={dense} button onClick={handleToggle}>
      <ListItemIcon className={classes.icon}>
        {open ? <ExpandMore /> : icon}
      </ListItemIcon>
      <Typography variant="inherit" color="textSecondary">
        {translate(name)}
      </Typography>
    </MenuItem>
  )

  return (
    <Fragment>
      {sidebarIsOpen || open ? (
        header
      ) : (
        <Tooltip title={translate(name)} placement="right">
          {header}
        </Tooltip>
      )}
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List
          dense={dense}
          component="div"
          disablePadding
          className={
            sidebarIsOpen ? classes.sidebarIsOpen : classes.sidebarIsClosed
          }
        >
          {children}
        </List>
        <Divider />
      </Collapse>
    </Fragment>
  )
}

export default SubMenu
