import React from 'react'
import { useParams } from 'react-router-dom'
import { message, Skeleton } from 'antd'
import { usePostQuery } from 'generated/graphql'
import { AdminMenuTitle } from 'components/AdminMenuTitle'
import TableDetailReportPost from '../component/table/TableDetailReportPost'
import DescriptonReportPost from '../component/description/DescriptonReportPost'

const ScreenDetailReportPost = () => {
  const params = useParams()
  const { data, loading, error, refetch } = usePostQuery({
    variables: {
      postId: params['id'],
      filterBy: {
        postID: params['id'],
      },
      pagination: {
        page: 1,
        pageSize: 10,
      },
    },
  })

  if (error) {
    message.error('에러가 발생했습니다.')
    return <div>에러가 발생했습니다.</div>
  }

  return (
    <>
      <AdminMenuTitle
        title="신고 게시글 관리"
        description="신고 게시글을 관리합니다"
      />
      {loading ? (
        <Skeleton />
      ) : (
        <>
          <DescriptonReportPost data={data?.post} />
          <TableDetailReportPost
            totalCount={data?.postAbuseReportLogs?.totalCount}
            deletedAt={data?.post?.deletedAt}
            data={data?.postAbuseReportLogs?.nodes}
            onRefetch={refetch}
          />
        </>
      )}
    </>
  )
}

export default ScreenDetailReportPost
