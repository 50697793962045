import React, { FunctionComponent, useMemo } from 'react'
import { IntlProvider, useIntl } from 'react-intl'
import { ApolloProvider } from '@apollo/react-hooks'
import { Admin, Resource } from 'react-admin'
import { apolloClient, graphqlClient } from './graphql'
import { createAuthProvider } from './auth'
import querieBuilders from './queries'
import { createDataProvider } from './data-provider'
import { createI18nProvider, messages } from './i18n'
import { Layout } from './components/layout'
import Dashboard from './components/Dashboard'
import * as resources from './resources'
import customRoutes from './routes'
import { GlobalStyle } from 'app.styled'

const dataProvider = createDataProvider(graphqlClient, querieBuilders)
const authProvider = createAuthProvider()

const AdminInstallation: FunctionComponent = () => {
  const intl = useIntl()
  const i18nProvider = useMemo(() => createI18nProvider(intl), [intl])

  return (
    <Admin
      layout={Layout}
      customRoutes={customRoutes}
      dashboard={Dashboard}
      dataProvider={dataProvider}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
    >
      <Resource
        name="boards"
        options={{ label: '게시판' }}
        list={resources.BoardList}
        show={resources.BoardShow}
      />
      <Resource
        name="posts"
        options={{ label: '게시글' }}
        list={resources.PostList}
        edit={resources.PostEdit}
      />
      <Resource
        name="post-collections"
        options={{ label: '게시글 모음' }}
        list={resources.PostCollectionList}
        create={resources.PostCollectionCreate}
        edit={resources.PostCollectionEdit}
      />
      <Resource
        name="tags"
        options={{ label: '태그 관리' }}
        list={resources.TagList}
        edit={resources.TagEdit}
        create={resources.TagCreate}
      />
      <Resource
        name="users"
        list={resources.UserList}
        show={resources.UserShow}
      />
      <Resource name="daily-posts" list={resources.DailyPostByUserList} />
      <Resource name="daily-comments" list={resources.DailyCommentByUserList} />
      <Resource
        name="board-navigations"
        list={resources.BoardNavigationList}
        edit={resources.BoardNavigationEdit}
      />
    </Admin>
  )
}

const App = () => {
  return (
    <IntlProvider locale="ko" messages={messages}>
      <ApolloProvider client={apolloClient}>
        <GlobalStyle />
        <AdminInstallation />
      </ApolloProvider>
    </IntlProvider>
  )
}

export default App
