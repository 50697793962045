import React, { FC } from 'react'
import { List, Datagrid, TextField, ShowButton } from 'react-admin'

// List

const BoardList: FC = props => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" label="ID" />
      <TextField source="title" label="제목" sortable={false} />
      <ShowButton />
    </Datagrid>
  </List>
)

export default BoardList
