import React from 'react'
import styled from 'styled-components'
import { Descriptions, Tag, Typography } from 'antd'
import { CLIENT_BASE_URL } from 'config'
const { Title, Paragraph } = Typography
const { Item } = Descriptions

type TProps = {
  data: any
}

const DescriptionDeletedPost: React.FC<TProps> = ({ data }) => {
  const url = `${CLIENT_BASE_URL}/community/${data?.id || ''}`

  return (
    <StyledWrapper>
      <Title level={4}>
        <Paragraph>
          <blockquote>게시글 상세 정보</blockquote>
        </Paragraph>
      </Title>
      <Descriptions
        bordered
        size="small"
        className="description"
        column={1}
        labelStyle={{ width: 200 }}
        contentStyle={{ backgroundColor: 'white' }}
      >
        <Item label="게시글 제목">{data?.title || ''}</Item>
        <Item label="게시글 URL">
          <a href={url} target="_blank">
            {url}
          </a>
        </Item>
        <Item label="작성자">{`닉네임 : ${data?.nickname || ''} / ID : ${data
          ?.author?.id || ''}`}</Item>
        {data?.reportCount && (
          <Item label="누적신고수">
            {data?.reportCount ? data?.reportCount : ''}
          </Item>
        )}
        {data?.reportCountByDescription.length > 0 && (
          <Item label="신고사유">
            {data?.reportCountByDescription
              ? data?.reportCountByDescription.map((data, index) => {
                  return (
                    <Tag color="default" key={index}>
                      {data.description ?? '신고 사유 없음'} :{' '}
                      {data?.count || ''}
                    </Tag>
                  )
                })
              : '신고 사유가 없습니다.'}
          </Item>
        )}
      </Descriptions>
    </StyledWrapper>
  )
}

export default DescriptionDeletedPost

const StyledWrapper = styled.div`
  .description,
  .collapse {
    margin-top: 15px;
  }
`
