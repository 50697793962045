import {
  CommentReportOrderField,
  ReportedCommentsQueryVariables,
  OrderDirection,
} from 'generated/graphql'

const generateVariablesReportComment: () =>
  | ReportedCommentsQueryVariables
  | undefined = () => {
  return {
    pagination: {
      page: 1,
      pageSize: 10,
    },
    orderBy: {
      field: CommentReportOrderField['LAST_REPORTED_AT'],
      direction: OrderDirection['DESC'],
    },
  }
}

export default generateVariablesReportComment
