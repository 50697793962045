import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { AdminMenuTitle } from 'components/AdminMenuTitle';
import TableDetailDeletedPost from '../component/table/TableDetailDeletedPost';
import DescriptionDeletedPost from '../component/description/DescriptionDeletedPost';
import { usePostDeleteReportDetailQuery } from 'generated/graphql';
import { message, Skeleton } from 'antd';

const ScreenDetailDeletedPost = () => {
  const params = useParams();

  const { data, loading, error, refetch } = usePostDeleteReportDetailQuery({
    variables: {
      postId: params['id'],
      filterBy: {
        postID: params['id'],
      },
      pagination: {
        page: 1,
        pageSize: 10,
      },
    },
  });

  if (error) {
    message.error('에러가 발생했습니다.');
    return <div>에러가 발생했습니다.</div>;
  }

  return (
    <>
      <AdminMenuTitle
        title="삭제 게시글 상세"
        description="삭제 게시글을 관리합니다"
      />
      {loading ? (
        <Skeleton />
      ) : (
        <>
          <DescriptionDeletedPost data={data?.post} />
          <TableDetailDeletedPost
            total={data?.postAbuseReportLogs?.totalCount}
            data={data?.postAbuseReportLogs?.nodes}
            onRefetch={refetch}
          />
        </>
      )}
    </>
  );
};

export default ScreenDetailDeletedPost;
