import gql from 'graphql-tag';
import React, { FC } from 'react';
import { Table } from 'antd';
import { useHistory, useLocation } from 'react-router';
import { Fields, SortDirection } from '../modules/constants/sortOrderBy';
import { format } from 'date-fns';
import { ColumnsType } from 'antd/lib/table';
import { BestPostsQueryVariables } from 'generated/graphql';
import convertPaginiationQueryString from 'app.module/module/convertPaginiationQueryString';

interface BestPostTableProps {
  dataSource: any;
  onRefetch: (variables?: BestPostsQueryVariables | undefined) => void;
}

gql`
  fragment BestPostInfomation on Post {
    score
    views
    commentCount
    likes
    scrapCount
    title
    createdAt
  }
`;

const tableColumns: ColumnsType<any> = [
  {
    title: '점수',
    dataIndex: 'score',
    width: '8%',
    align: 'center',
    render: (value) => <div>{value}</div>,
    sorter: true,
    sortDirections: ['ascend', 'descend', 'ascend'],
  },
  {
    title: '조회 수',
    dataIndex: 'views',
    width: '8%',
    align: 'center',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'],
    render: (value) => <div>{value}</div>,
  },
  {
    title: '댓글 수',
    dataIndex: 'commentCount',
    width: '8%',
    align: 'center',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'],
    render: (value) => <div>{value}</div>,
  },
  {
    title: '추천 수',
    dataIndex: 'likes',
    width: '8%',
    align: 'center',
    sorter: true,
    sortDirections: ['descend', 'ascend', 'descend'],
    render: (value) => <div>{value}</div>,
  },
  {
    title: '스크랩 수',
    dataIndex: 'scrapCount',
    width: '8%',
    align: 'center',
    sortDirections: ['descend', 'ascend', 'descend'],
    sorter: true,
    render: (value) => <div>{value}</div>,
  },
  {
    title: '제목 ',
    width: '40%',
    dataIndex: 'title',
    align: 'center',

    render: (value) => <div>{value}</div>,
  },
  {
    title: '등록일시',
    width: '10%',
    dataIndex: 'createdAt',
    align: 'center',
    render: (value) => <div>{format(new Date(value), 'yyyy-MM-dd HH:mm')}</div>,
  },
];

const BestPostTable: FC<BestPostTableProps> = ({ dataSource, onRefetch }) => {
  const router = useHistory();
  const { search } = useLocation();
  const pagination: any = convertPaginiationQueryString(search);

  const handlePageChange = (page: number, pageSize: number): void => {
    router.push(`?page=${page}&pageSize=${pageSize}`);
  };

  const handleOrderChange = (...restParams: any) => {
    const action = restParams[3].action;
    if (action !== 'sort') return;

    const field = Fields[restParams[2].field];
    const direction = SortDirection[restParams[2].order];

    onRefetch({
      orderBy: {
        field,
        direction,
      },
    });
  };

  return (
    <Table
      bordered
      dataSource={dataSource}
      columns={tableColumns}
      rowKey={(row) => row.id}
      pagination={{
        showSizeChanger: true,
        pageSize: Number(pagination.pageSize) || 10,
        current: Number(pagination.page) || 1,
        onChange: handlePageChange,
      }}
      onChange={handleOrderChange}
    />
  );
};

export default BestPostTable;
