import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import DropdownReportUser from 'app.feature/board/report/component/dropdown/DropdownReportUser'

const ColumnsReportPost = () => {
  const { search } = useLocation()

  return [
    {
      title: '아이디',
      key: 'id',
      dataIndex: 'id',
      render: (_, record) => (
        <DropdownReportUser id={record.id} nickname={record.nickname} />
      ),
    },
    {
      title: '닉네임',
      dataIndex: 'nickname',
      key: 'nickname',
    },
    {
      title: '제목',
      dataIndex: 'title',
      key: 'title',
      render: (title, record) => {
        return (
          <Link to={`/reported-post/detail/${record.key}${search}`}>
            {title}
          </Link>
        )
      },
    },
    {
      title: '최근 신고 사유',
      dataIndex: 'reportDescription',
      key: 'reportDescription',
    },
    {
      title: '신고일시',
      dataIndex: 'lastReportedAt',
      key: 'lastReportedAt',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: '누적 신고수',
      dataIndex: 'reportCount',
      key: 'reportCount',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
    {
      title: 'IP 주소',
      dataIndex: 'ipaddress',
      key: 'ipaddress',
    },
  ]
}

export default ColumnsReportPost
