import { Form, message } from 'antd'
import {
  BestPostsQueryVariables,
  RequirementInputsFragment,
  useUpdateBestOfBestRequirementMutation,
} from 'generated/graphql'
import gql from 'graphql-tag'
import React, { FC, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import getQueryVariableFrom from '../modules/utils/getQueryVariableFrom'
import setFormFieldsTo from '../modules/utils/setFormFieldsTo'
import RequirementInputs from './RequirementInputs'
import SubmitButtons from './SubmitButtons'
import SubmitModal from './SubmitModal'

gql`
  fragment RequirementInputs on CollectionRequirement {
    view
    scrap
    like
    comment
  }
`

interface BestPostRequirementsProps {
  initialValue: RequirementInputsFragment
  onRefetch: (variables?: BestPostsQueryVariables | undefined) => void
}

export type formDataType = Omit<RequirementInputsFragment, '__typename'>

const BestPostRequirement: FC<BestPostRequirementsProps> = ({
  initialValue,
  onRefetch,
}) => {
  const history = useHistory()
  const [isChanging, setIsChange] = useState(false)
  const [enableSubmit, setEnableSubmit] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [requirementsForm] = Form.useForm<formDataType>()

  const [mutation, { loading }] = useUpdateBestOfBestRequirementMutation({
    onError: () => {
      message.error('조건 변경에 실패하였습니다.', 1)
      handleBackToList()
      setIsModalVisible(false)
    },
    onCompleted: () => {
      message.success('성공적으로 반영되었습니다', 1)
      onRefetch(getQueryVariableFrom(requirementsForm))
      setIsModalVisible(false)
      handleChangeEnd()
    },
  })

  const handleChangeStart = () => setIsChange(true)
  const handleChangeEnd = () => {
    resetPagination()
    setIsChange(false)
    setEnableSubmit(false)
  }

  const resetPagination = () => {
    history.replace({
      search: '',
    })
  }

  const handleBackToList = () => {
    setFormFieldsTo(requirementsForm, initialValue)
    onRefetch(getQueryVariableFrom(requirementsForm))
    handleChangeEnd()
  }

  const handleRefetchTableData = async () => {
    await requirementsForm.validateFields()
    onRefetch(getQueryVariableFrom(requirementsForm))
    resetPagination()
    setEnableSubmit(true)
  }

  const handleOk = () => {
    const inputValues = requirementsForm.getFieldsValue()
    mutation({
      variables: {
        input: {
          view: Number(inputValues.view),
          scrap: Number(inputValues.scrap),
          like: Number(inputValues.like),
          comment: Number(inputValues.comment),
        },
      },
    })
  }

  const handleFormSubmit = () => {
    if (!enableSubmit) return
    setIsModalVisible(true)
  }

  return (
    <StyledWrapper>
      <SubmitModal
        visible={isModalVisible}
        onOk={handleOk}
        loading={loading}
        onCancel={() => setIsModalVisible(false)}
      />
      <Form form={requirementsForm} className="requirement-form">
        <RequirementInputs
          initialValue={initialValue}
          onInputChange={() => setEnableSubmit(false)}
          disabled={!isChanging}
          onRefetch={handleRefetchTableData}
        />
        <SubmitButtons
          enableSubmit={enableSubmit}
          isChanging={isChanging}
          onChangeStart={handleChangeStart}
          onBackToList={handleBackToList}
          onChangeRequirement={handleFormSubmit}
        />
      </Form>
    </StyledWrapper>
  )
}

export default BestPostRequirement

const StyledWrapper = styled.div`
  .requirement-form {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
`
