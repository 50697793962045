import gql from 'graphql-tag'
import { DailyCommentByUserGetListDocument } from 'generated/graphql'
import { QueryBuilder } from './base.query'

export function createDailyCommentQueryBuilder(): QueryBuilder {
  return {
    getList: {
      query: DailyCommentByUserGetListDocument,
    },
  }
}

gql`
  fragment DailyCommentByUserFragment on Comment {
    id
    ipaddress
    createdAt
    nickname
    author {
      id
      email
      username
      nickname
    }
    board {
      id
      title
    }
  }
`

gql`
  query DailyCommentByUserGetList(
    $filterBy: DailyCommentByUserFilters
    $orderBy: DailyCommentByUserOrder
    $pagination: Pagination
  ) {
    data: dailyCommentByUsers(
      filterBy: $filterBy
      orderBy: $orderBy
      pagination: $pagination
    ) {
      data: nodes {
        ...DailyCommentByUserFragment
      }
      total: totalCount
    }
  }
`
