import React, { useState } from 'react'
import { Button, Form } from 'antd'
import { Rule } from 'antd/lib/form'
import styled from 'styled-components'
import SearchUserConditionInput from './input/SearchUserConditionInput'
import SearchUserConditionSelect from './select/SearchUserConditionSelect'

type TProps = {
  onReleaseUser: () => void
  disabledButton: boolean
  onRefetch: (searchType: string, inputValue: string) => void
}

const FormUsersBan: React.FC<TProps> = ({
  onReleaseUser,
  disabledButton,
  onRefetch,
}) => {
  const [form] = Form.useForm()
  const [activeValidation, setActiveValidation] = useState(true)

  const handleFormSubmit = (values: any) => {
    let selectVal =
      values['selectCondition'] === undefined ? 'id' : values['selectCondition']
    const inputVal = values['inputCondition'] ?? ''
    const key = selectVal === 'id' ? 'userID' : 'nickname'
    onRefetch(key, inputVal)
  }

  const inputConditionRules: Rule[] | undefined = activeValidation
    ? [
        {
          pattern: new RegExp(/^[0-9]+$/),
          message: '아이디는 숫자 형식으로만 입력해주세요.',
        },
      ]
    : []

  const handleConditionSelect = (value: string | any) => {
    setActiveValidation(value === 'id')
    form.setFieldsValue({ inputCondition: '' })
  }

  return (
    <StyledWrapper>
      <Form form={form} onFinish={handleFormSubmit}>
        <div className="container">
          <div className="sort-div">
            <Form.Item className="form-item" name="selectCondition">
              <SearchUserConditionSelect onChange={handleConditionSelect} />
            </Form.Item>
            <Form.Item name="inputCondition" rules={inputConditionRules}>
              <SearchUserConditionInput />
            </Form.Item>
          </div>
          <div className="sort-button">
            <Button
              type="primary"
              disabled={disabledButton}
              onClick={() => onReleaseUser()}
            >
              활동 정지 해제
            </Button>
          </div>
        </div>
      </Form>
    </StyledWrapper>
  )
}

export default FormUsersBan

const StyledWrapper = styled.div`
  .container {
    padding-top: 15px;
    display: flex;
    justify-content: space-between;
  }
  .sort-div {
    display: flex;
  }
  .form-item {
    width: 80px;
    margin-right: 5px;
  }
`
