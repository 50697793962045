import React from 'react'
import styled from 'styled-components'
import { Button, Form, message, Modal } from 'antd'
import { useBanUserMutation } from 'generated/graphql'
import SelectBanUserPeriod from './select/SelectBanUserPeriod'
import RadioBanUserDescription from './radio/RadioBanUserDescription'
import getDateRange from '../../module/getDateRange'
import { banUserDescription } from '../../module/banUserDescription'
const { Item } = Form

type TProps = {
  userID: string
  nickname: string
  visible: boolean
  onOk: () => void
  onCancel: () => void
}

const ModalBanUser: React.FC<TProps> = ({
  userID,
  nickname,
  visible,
  onOk,
  onCancel,
}) => {
  const [form] = Form.useForm()

  const [banUserMutation] = useBanUserMutation({
    onError: () => {
      message.error('이미 활동 정지된 회원입니다.')
    },
    onCompleted: () => {
      message.success('해당 유저가 활동 정지 처리되었습니다.')
    },
  })

  const handleSubmit = async ({
    banPeriod,
    banDescription,
    banDesciptionInput,
  }) => {
    const [startAt, endAt] = getDateRange(banPeriod)
    await banUserMutation({
      variables: {
        input: {
          userID,
          description:
            banDescription === '기타' ? banDesciptionInput : banDescription,
          startAt,
          endAt,
        },
      },
    })
    form.setFieldsValue({ banDescription: '' })
    onOk()
  }

  const handleModalOk = () => {
    form.submit()
  }

  return (
    <Modal
      centered
      className="custom-modal"
      title="활동 정지"
      visible={visible}
      onOk={onOk}
      onCancel={onCancel}
      footer={
        <StyledWrapper>
          <div className="footer">
            <div className="title">
              <h3>대상 회원을 활동정지 하시겠습니까?</h3>
            </div>
            <div className="button-list">
              <Button
                key="submit"
                type="primary"
                htmlType="submit"
                onClick={handleModalOk}
              >
                활동정지
              </Button>
              <Button onClick={onCancel}>취소</Button>
            </div>
          </div>
        </StyledWrapper>
      }
    >
      <StyledWrapper>
        <Form form={form} onFinish={(values: any) => handleSubmit(values)}>
          <h3>활동 정지 대상 회원</h3>
          <p className="user-info">
            닉네임 : {nickname} / ID : {userID}
          </p>
          <h3>활동 정지 사유</h3>
          <Item
            name="banDescription"
            initialValue={banUserDescription['illegalAdvertising']}
          >
            <RadioBanUserDescription />
          </Item>
          <h3>활동 정지 기간</h3>
          <Item name="banPeriod" initialValue="7">
            <SelectBanUserPeriod />
          </Item>
        </Form>
      </StyledWrapper>
    </Modal>
  )
}

export default ModalBanUser

const StyledWrapper = styled.div`
  .user-info,
  .radio {
    margin-bottom: 20px;
  }
  .select {
    width: 100%;
  }
  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 16px;
  }
`
