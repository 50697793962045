import {
  OrderDirection,
  PostTrashOrderField,
  TrashedPostsQueryVariables,
} from 'generated/graphql'

const generateVariableDeletedPost: () =>
  | TrashedPostsQueryVariables
  | undefined = () => {
  return {
    orderBy: {
      field: PostTrashOrderField['DELETED_AT'],
      direction: OrderDirection['DESC'],
    },
    pagination: {
      page: 1,
      pageSize: 10,
    },
  }
}

export default generateVariableDeletedPost
