import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { FC, ReactNode } from 'react';

interface MenuTitleProps {
  title: string;
  description?: string;
  icon?: ReactNode;
}

const AdminMenuTitle: FC<MenuTitleProps> = ({ title, icon, description }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.title}>
        {icon && <div className={classes.icon}>{icon}</div>}
        <Typography className={classes.typography} variant="h5" align="left">
          {title}
        </Typography>
      </div>
      <p className={classes.description}>{description}</p>
    </div>
  );
};

export default AdminMenuTitle;

const useStyles = makeStyles({
  root: {
    padding: '20px 0 0',
    borderBottom: '1px solid lightgray',
  },
  title: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: 10,
  },
  icon: {
    marginRight: 10,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  typography: {
    fontWeight: 'bold',
  },
  description: {
    color: 'gray',
  },
});
