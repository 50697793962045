import { Select } from 'antd'
import React from 'react'

const userBanSelectOption = [
  {
    value: 'id',
    text: '아이디',
  },
  {
    value: 'nickname',
    text: '닉네임',
  },
]

type TProps = {
  props?: object | any
  onChange?: (value: any) => void
}

const SearchUserConditionSelect: React.FC<TProps> = props => {
  return (
    <Select {...props} defaultValue="id">
      {userBanSelectOption.map(option => {
        return (
          <Select.Option key={option.value} value={option.value}>
            {option.text}
          </Select.Option>
        )
      })}
    </Select>
  )
}

export default SearchUserConditionSelect
