import React, { FC, useCallback } from 'react'
import { Create, SimpleForm, TextInput } from 'react-admin'

const PostCollectionCreate: FC = props => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="title" />
        {/* <TextInput source="teaser" options={{ multiLine: true }} /> */}
        {/* <RichTextInput source="body" /> */}
        {/* <DateInput label="Publication date" source="published_at" defaultValue={new Date()} /> */}
      </SimpleForm>
    </Create>
  )
}

export default PostCollectionCreate
