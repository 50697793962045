import React from 'react'
import { Modal } from 'antd'

type TProps = {
  visible: boolean
  onCancel: () => void
  onOk: () => void
}

const ModalConfirmReleaseUser: React.FC<TProps> = ({
  visible,
  onCancel,
  onOk,
}) => {
  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      onOk={onOk}
      okText="확인"
      cancelText="취소"
    >
      해당 유저를 활동 정지 해제 하시겠습니까?
    </Modal>
  )
}

export default ModalConfirmReleaseUser
