import gql from 'graphql-tag'
import {
  BoardNavigationGetListDocument,
  BoardNavigationGetDocument,
  BoardNavigationUpdateDocument,
} from 'generated/graphql'
import { QueryBuilder } from './base.query'

export function createBoardNavigationQueryBuilder(): QueryBuilder {
  return {
    getList: {
      query: BoardNavigationGetListDocument,
    },
    getOne: {
      query: BoardNavigationGetDocument,
    },
    update: {
      query: BoardNavigationUpdateDocument,
    },
  }
}

gql`
  fragment BoardNavigationFragment on BoardNavigation {
    id
    name
    board {
      ...BoardFragment
    }
  }
`

gql`
  query BoardNavigationGetList($filterBy: BoardNavigationFiltersInput) {
    data: boardNavigations(filterBy: $filterBy) {
      data: nodes {
        ...BoardNavigationFragment
      }
      total: totalCount
    }
  }
`

gql`
  query BoardNavigationGet($id: ID!) {
    data: boardNavigation(id: $id) {
      ...BoardNavigationFragment
    }
  }
`

gql`
  mutation BoardNavigationUpdate(
    $id: ID!
    $input: BoardNavigationUpdateInput!
  ) {
    data: boardNavigationUpdate(id: $id, input: $input) {
      data: boardNavigation {
        ...BoardNavigationFragment
      }
    }
  }
`
