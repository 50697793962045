import { Rule } from 'antd/lib/form'

const requirementInputRules: Rule[] = [
  { required: true, message: '조건을 모두 입력해주세요' },
  {
    pattern: new RegExp(/^[-+]?[0-9]*\.?[0-9]+$/),
    message: '숫자만 입력해주세요',
  },
  {
    validator: (_, value: string) => {
      if (Number(value) > 100) {
        return Promise.reject(new Error('100이하의 숫자를 입력해주세요.'))
      }
      if (Number(value) < 0) {
        return Promise.reject(new Error('0이상의 숫자를 입력해주세요.'))
      }
      return Promise.resolve()
    },
  },
]

export default requirementInputRules
