import React from 'react'
import { message, Skeleton } from 'antd'
import { useHistory } from 'react-router-dom'
import { useTrashedCommentsQuery } from 'generated/graphql'
import { AdminMenuTitle } from 'components/AdminMenuTitle'
import FormDateRangePickerWithSelectInput from 'app.feature/board/report/component/form/FormDateRangePickerWithSelectInput'
import TableDeletedComment from '../component/table/TableDeletedComment'
import useAuthenticationCheck from 'resources/best-posts/hooks/useAuthenticationCheck'
import generateVariableDeletedComment from '../gql/variable/generateVariableDeletedComment'

const ScreenDeletedComment = () => {
  useAuthenticationCheck()
  const router = useHistory()

  const { data, loading, error, refetch } = useTrashedCommentsQuery({
    variables: {
      ...generateVariableDeletedComment(),
    },
  })

  if (error) {
    message.error('에러가 발생했습니다.')
    return <div>에러가 발생했습니다.</div>
  }

  const handleRefetchFormSubmit = async ({
    startDate,
    endDate,
    selectCondition,
    inputCondition,
  }) => {
    await refetch({
      ...generateVariableDeletedComment(),
      filterBy: {
        startDate,
        endDate,
        [selectCondition]: inputCondition,
      },
    })
    router.push(`?page=1&pageSize=10`)
  }

  return (
    <>
      <AdminMenuTitle
        title="삭제된 댓글"
        description="삭제된 댓글을 관리합니다"
      />
      <FormDateRangePickerWithSelectInput
        type="contentText"
        onFormSubmit={handleRefetchFormSubmit}
      />
      {loading ? (
        <Skeleton />
      ) : (
        <TableDeletedComment
          data={data?.trashedComments?.nodes}
          total={data?.trashedComments?.totalCount}
          onRefetch={refetch}
        />
      )}
    </>
  )
}

export default ScreenDeletedComment
