import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import { onError } from 'apollo-link-error'
import { ApolloLink, split } from 'apollo-link'
import { GraphQLClient } from './data-provider'
import { API_BASE_URL, MAIN_API_BASE_URL } from 'config'

const communityLink = new HttpLink({
  uri: `${API_BASE_URL}/graphql`,
  credentials: 'include',
})

const mainLink = new HttpLink({
  uri: `${MAIN_API_BASE_URL}/graphql`,
  credentials: 'include',
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
      )
    })
  }
  if (networkError) {
    console.log(`[Network error]: ${networkError}`)
  }
})

const link = split(
  operation => {
    const context = operation?.getContext?.() || {}
    return context.clientName === 'main'
  },
  mainLink,
  communityLink,
)

export const apolloClient = new ApolloClient({
  connectToDevTools: true,
  link: ApolloLink.from([errorLink, link]),
  cache: new InMemoryCache().restore({}),
})

export const graphqlClient: GraphQLClient = {
  query: async (query: any, variables: any) => {
    const result = await apolloClient.query({
      query,
      fetchPolicy: 'network-only',
      variables,
    })

    if (result.errors && result.errors.length > 0) {
      throw result.errors
    }

    return result
  },
  mutate: async (mutation: any, variables: any) => {
    const result = await apolloClient.mutate({
      mutation,
      variables,
    })

    if (result.errors && result.errors.length > 0) {
      throw result.errors
    }

    return result
  },
}
