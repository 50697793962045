import React, { FC } from 'react'
import { Modal } from 'antd'

interface SubmitModalProps {
  visible: boolean
  onOk: () => void
  onCancel: () => void
  loading: boolean
}

const SubmitModal: FC<SubmitModalProps> = ({
  visible,
  onOk,
  onCancel,
  loading,
}) => {
  return (
    <>
      <Modal
        title="명예의 전당 조건 변경"
        visible={visible}
        onOk={onOk}
        onCancel={onCancel}
        confirmLoading={loading}
        okText="확인"
        cancelText="취소"
        destroyOnClose
      >
        현재조건으로 명예의 전당에 반영하시겠습니까?
      </Modal>
    </>
  )
}

export default SubmitModal
