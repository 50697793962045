import React, {
  FunctionComponent,
  useState,
  ChangeEvent,
  SyntheticEvent,
} from 'react'
import gql from 'graphql-tag'

import {
  Card,
  CardActions,
  CardContent,
  Button,
  Snackbar,
  Input,
} from '@material-ui/core'

import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core/styles'
import { red, blue } from '@material-ui/core/colors'
import {
  useConfigUpdateMutation,
  useConfigResetMutation,
  ConfigFragmentFragment as ConfigFragment,
} from '../generated/graphql'

interface SnackbarMessage {
  message: string
  key: number
}

const useStyles = makeStyles({
  card: {
    padding: '10px 10px 5px 10px',
    margin: '20px 0',
  },
  cardActions: {
    float: 'right',
  },
  cardContent: {
    fontSize: '18px',
    margin: '10px',
    alignItems: 'center',
  },
  title: {
    marginBottom: '10px',
    fontSize: '24px',
  },
  settingInput: {
    padding: '5px',
    border: '1px solid #ccc',
    borderRadius: '3px',
    boxSizing: 'content-box',
    color: '#2C3E50',
    fontSize: '13px',
  },
  contentKey: {
    fontWeight: 'bold',
    marginBottom: '5px',
    fontSize: '20px',
  },
})

interface ConfigEditorProps {
  config: ConfigFragment
}

const UndoAction = props => {
  const { handleClose } = props
  return (
    <>
      <Button color="secondary" size="small" onClick={handleClose}>
        닫기
      </Button>
    </>
  )
}

const ConfigEditor: FunctionComponent<ConfigEditorProps> = ({ config }) => {
  const classes = useStyles()

  const [value, setValue] = useState(config.value)
  const [open, setOpen] = React.useState(false)
  const [message, setmessage] = useState('')
  const [
    configUpdateMutate,
    { loading: updateLoading },
  ] = useConfigUpdateMutation()
  const [
    configResetMutate,
    { loading: resetLoading },
  ] = useConfigResetMutation()

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.value)
  }

  function callMessage(message: string) {
    setmessage(message)
    setOpen(true)
  }

  const handleUpdateButtonClick = async () => {
    try {
      await configUpdateMutate({
        variables: { id: config.id, value },
      })
      callMessage('업데이트 되었습니다.')
    } catch (e) {
      callMessage('오류가 발생했습니다.')
    }
  }

  const handleResetButtonClick = async () => {
    try {
      await configResetMutate({
        variables: { id: config.id },
      })
      callMessage('리셋 되었습니다.')
      setValue(config.defaultValue)
    } catch (e) {
      callMessage('오류가 발생했습니다.')
    }
  }

  const handleClose = (event: SyntheticEvent | MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setOpen(false)
  }

  const theme = createMuiTheme({ palette: { primary: blue, secondary: red } })

  return (
    <>
      <div className={classes.title}>{config.name}</div>
      <Card className={classes.card}>
        <CardContent>
          <div className={classes.cardContent}>
            <div className={classes.contentKey}> 설명 </div>
            <div>{config.description}</div>
          </div>

          <div className={classes.cardContent}>
            <div className={classes.contentKey}> 기본 값 </div>
            <span>{config.defaultValue ? config.defaultValue : '없음'}</span>
          </div>
          <div className={classes.cardContent}>
            <div className={classes.contentKey}> 설정 값 </div>
            <Input
              fullWidth={true}
              value={value || ''}
              onChange={handleChange}
            />
          </div>
        </CardContent>
        <CardActions className={classes.cardActions}>
          <ThemeProvider theme={theme}>
            <Button
              variant="outlined"
              disabled={updateLoading}
              onClick={handleUpdateButtonClick}
              color="primary"
            >
              Update
            </Button>
            <Button
              variant="outlined"
              disabled={resetLoading}
              onClick={handleResetButtonClick}
              color="secondary"
            >
              Reset
            </Button>
          </ThemeProvider>
        </CardActions>
      </Card>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        message={message}
        onClose={handleClose}
        action={<UndoAction handleClose={handleClose} />}
      />
    </>
  )
}

export default ConfigEditor

gql`
  fragment ConfigFragment on Config {
    id
    name
    description
    value
    defaultValue
  }
`

gql`
  mutation ConfigUpdate($id: ID!, $value: String) {
    configUpdate(id: $id, input: { value: $value }) {
      ...ConfigFragment
    }
  }

  mutation ConfigReset($id: ID!) {
    configReset(id: $id)
  }
`
