import React, { FunctionComponent } from 'react'
import gql from 'graphql-tag'
import { useConfigUiQuery } from '../generated/graphql'
import ConfigEditor from '../components/ConfigEditor'
import { makeStyles } from '@material-ui/core/styles'

const ConfigUIScreen: FunctionComponent = () => {
  const { data, loading } = useConfigUiQuery()
  const useStyles = makeStyles({
    root: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
  })

  const classes = useStyles()
  if (loading) {
    return <div>Loading...</div>
  }

  if (!data) {
    return <div>Something went wrong :(</div>
  }

  return (
    <div className={classes.root}>
      <h1>UI 설정</h1>
      <ConfigEditor config={data.configHeaderURL!} />
      <ConfigEditor config={data.configHeaderDefaultHeight!} />
      <ConfigEditor config={data.configHeaderExpandedHeight!} />
      <ConfigEditor config={data.configFooterURL!} />
      <ConfigEditor config={data.configFooterDefaultHeight!} />
    </div>
  )
}

export default ConfigUIScreen

gql`
  query ConfigUI {
    configHeaderURL: config(id: "config.header") {
      ...ConfigFragment
    }
    configHeaderDefaultHeight: config(id: "config.header.defaultHeight") {
      ...ConfigFragment
    }
    configHeaderExpandedHeight: config(id: "config.header.expandedHeight") {
      ...ConfigFragment
    }
    configFooterURL: config(id: "config.footer") {
      ...ConfigFragment
    }
    configFooterDefaultHeight: config(id: "config.footer.defaultHeight") {
      ...ConfigFragment
    }
  }
`
