import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import DropdownUserManagement from 'app.feature/user/ban/component/dropdown/DropdownUserManagement'

const ColumnDeletedPost = () => {
  const { search } = useLocation()

  return [
    {
      title: '아이디',
      key: 'id',
      dataIndex: 'id',
      render: id => <DropdownUserManagement id={id} />,
    },
    {
      title: '닉네임',
      key: 'nickname',
      dataIndex: 'nickname',
    },
    {
      title: '제목',
      key: 'title',
      dataIndex: 'title',
      render: (title, record) => (
        <Link to={`/deleted-post/detail/${record.key}${search}`}>{title}</Link>
      ),
    },
    {
      title: '게시판',
      key: 'boardTitle',
      dataIndex: 'boardTitle',
    },
    {
      title: '삭제일',
      key: 'deletedAt',
      dataIndex: 'deletedAt',
      sorter: true,
      sortDirections: ['ascend', 'descend', 'ascend'],
    },
  ]
}

export default ColumnDeletedPost
