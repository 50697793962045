export const Fields = {
  views: 'VIEWS',
  scrapCount: 'SCRAP_COUNT',
  likes: 'LIKES',
  score: 'SCORE',
  commentCount: 'COMMENT_COUNT',
} as const

export const SortDirection = {
  descend: 'DESC',
  ascend: 'ASC',
} as const
