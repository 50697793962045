import React, { useState } from 'react';
import { Dropdown, Menu } from 'antd';
import { CLIENT_BASE_URL } from '../../../../../config';
import ModalBanUser from '../modal/ModalBanUser';

type TProps = {
  id: string;
  nickname: string;
};

const DropdownReportUser: React.FC<TProps> = ({ id, nickname }) => {
  const menuHref = `${CLIENT_BASE_URL}/user-info/${id}`;
  const [isModalVisible, setIsModalVisible] = useState(false);

  const reportUserMenu = (
    <Menu
      items={[
        {
          key: 'postTracking',
          label: (
            <a href={`${menuHref}/posts/all`} target="_blank">
              작성글 추적
            </a>
          ),
        },
        {
          key: 'commentTracking',
          label: (
            <a href={`${menuHref}/comments`} target="_blank">
              작성댓글 추적
            </a>
          ),
        },
        {
          key: 'banUser',
          label: <span>활동 정지</span>,
          onClick: () => setIsModalVisible(true),
        },
      ]}
    />
  );

  return (
    <>
      <ModalBanUser
        userID={id}
        nickname={nickname}
        visible={isModalVisible}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => setIsModalVisible(false)}
      />
      <Dropdown overlay={reportUserMenu}>
        <a onClick={(event) => event.preventDefault()}>{id}</a>
      </Dropdown>
    </>
  );
};

export default DropdownReportUser;
