import React, { FunctionComponent } from 'react'
import gql from 'graphql-tag'
import { useConfigSeoQuery } from '../generated/graphql'
import ConfigEditor from '../components/ConfigEditor'
import { makeStyles } from '@material-ui/core/styles'

const ConfigSEOScreen: FunctionComponent = () => {
  const { data, loading } = useConfigSeoQuery()
  const useStyles = makeStyles({
    root: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
  })
  const classes = useStyles()

  if (loading) {
    return <div>Loading...</div>
  }

  if (!data) {
    return <div>Something went wrong :(</div>
  }

  return (
    <div className={classes.root}>
      <h1>SEO 설정</h1>
      <ConfigEditor config={data.configSEODefaultTitle!} />
      <ConfigEditor config={data.configSEODefaultDescription!} />
      <ConfigEditor config={data.configSEOOpengraphTitle!} />
      <ConfigEditor config={data.configSEOOpengraphDescription!} />
      <ConfigEditor config={data.configSEOOpengraphURL!} />
      <ConfigEditor config={data.configSEOOpengraphSiteName!} />
    </div>
  )
}

export default ConfigSEOScreen

gql`
  query ConfigSEO {
    configSEODefaultTitle: config(id: "seo.default.title") {
      ...ConfigFragment
    }
    configSEODefaultDescription: config(id: "seo.default.description") {
      ...ConfigFragment
    }
    configSEOOpengraphTitle: config(id: "seo.opengraph.title") {
      ...ConfigFragment
    }
    configSEOOpengraphDescription: config(id: "seo.opengraph.description") {
      ...ConfigFragment
    }
    configSEOOpengraphURL: config(id: "seo.opengraph.URL") {
      ...ConfigFragment
    }
    configSEOOpengraphSiteName: config(id: "seo.opengraph.siteName") {
      ...ConfigFragment
    }
  }
`
