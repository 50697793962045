export default {
  // "ra.action.add_filter": "Add filter"
  'ra.action.add_filter': '필터 추가',
  // "ra.action.add": "Add"
  'ra.action.add': '추가',
  // "ra.action.back": "Go Back"
  'ra.action.back': '돌아가기',
  // "ra.action.bulk_actions": "1 item selected |||| %{smart_count} items selected"
  'ra.action.bulk_actions': '{smart_count} 개 항목 선택됨',
  // "ra.action.cancel": "Cancel"
  'ra.action.cancel': '취소',
  // "ra.action.clear_input_value": "Clear value"
  'ra.action.clear_input_value': '초기화',
  // "ra.action.clone": "Clone"
  'ra.action.clone': '복사',
  // "ra.action.confirm": "Confirm"
  'ra.action.confirm': '확인',
  // "ra.action.create": "Create"
  'ra.action.create': '생성',
  // "ra.action.delete": "Delete"
  'ra.action.delete': '삭제',
  // "ra.action.edit": "Edit"
  'ra.action.edit': '수정',
  // "ra.action.export": "Export"
  'ra.action.export': '내보내기',
  // "ra.action.list": "List"
  'ra.action.list': '목록',
  // "ra.action.refresh": "Refresh"
  'ra.action.refresh': '새로고침',
  // "ra.action.remove_filter": "Remove this filter"
  'ra.action.remove_filter': '이 필터 삭제',
  // "ra.action.remove": "Remove"
  'ra.action.remove': '삭제',
  // "ra.action.save": "Save"
  'ra.action.save': '저장',
  // "ra.action.search": "Search"
  'ra.action.search': '검색',
  // "ra.action.show": "Show"
  'ra.action.show': '보기',
  // "ra.action.sort": "Sort"
  'ra.action.sort': '정렬',
  // "ra.action.undo": "Undo"
  'ra.action.undo': '실행 취소',
  // "ra.action.expand": "Expand"
  'ra.action.expand': '열기',
  // "ra.action.close": "Close"
  'ra.action.close': '닫기',
  // "ra.boolean.true": "Yes"
  'ra.boolean.true': '예',
  // "ra.boolean.false": "No"
  'ra.boolean.false': '아니오',
  // "ra.boolean.null": ""
  'ra.boolean.null': '',
  // "ra.page.create": "Create %{name}"
  'ra.page.create': 'Create {name} 생성하기',
  // "ra.page.dashboard": "Dashboard"
  'ra.page.dashboard': '대시보드',
  // "ra.page.edit": "%{name} #%{id}"
  'ra.page.edit': '%{name} %{id}',
  // "ra.page.error": "Something went wrong"
  // "ra.page.list": "%{name}"
  'ra.page.list': '{name}',
  // "ra.page.loading": "Loading"
  'ra.page.loading': '로드 중',
  // "ra.page.not_found": "Not Found"
  // "ra.page.show": "%{name} #%{id}"
  'ra.page.show': '{name} #{id}',
  // "ra.input.file.upload_several": "Drop some files to upload, or click to select one."
  // "ra.input.file.upload_single": "Drop a file to upload, or click to select it."
  // "ra.input.image.upload_several": "Drop some pictures to upload, or click to select one."
  // "ra.input.image.upload_single": "Drop a picture to upload, or click to select it."
  // "ra.input.references.all_missing": "Unable to find references data."
  // "ra.input.references.many_missing": "At least one of the associated references no longer appears to be available."
  // "ra.input.references.single_missing": "Associated reference no longer appears to be available."
  // "ra.message.about": "About"
  // "ra.message.are_you_sure": "Are you sure?"
  // "ra.message.bulk_delete_content": "Are you sure you want to delete this %{name}? |||| Are you sure you want to delete these %{smart_count} items?"
  // "ra.message.bulk_delete_title": "Delete %{name} |||| Delete %{smart_count} %{name}"
  // "ra.message.delete_content": "Are you sure you want to delete this item?"
  // "ra.message.delete_title": "Delete %{name} #%{id}"
  // "ra.message.details": "Details"
  // "ra.message.error": "A client error occurred and your request couldn't be completed."
  // "ra.message.invalid_form": "The form is not valid. Please check for errors"
  // "ra.message.loading": "The page is loading, just a moment please"
  'ra.message.loading': '페이지 로드 중입니다. 잠시만 기다려주세요',
  // "ra.message.no": "No"
  'ra.message.no': '아니오',
  // "ra.message.not_found": "Either you typed a wrong URL, or you followed a bad link."
  // "ra.message.yes": "Yes"
  'ra.message.yes': '예',
  // "ra.navigation.no_results": "No results found"
  'ra.navigation.no_results': '결과를 찾을 수 없습니다',
  // "ra.navigation.no_more_results": "The page number %{page} is out of boundaries. Try the previous page."
  // "ra.navigation.page_out_of_boundaries": "Page number %{page} out of boundaries"
  // "ra.navigation.page_out_from_end": "Cannot go after last page"
  // "ra.navigation.page_out_from_begin": "Cannot go before page 1"
  // "ra.navigation.page_range_info": "%{offsetBegin}-%{offsetEnd} of %{total}"
  'ra.navigation.page_range_info': '전체 {total} 중 {offsetBegin}-{offsetEnd}',
  // "ra.navigation.page_rows_per_page": "Rows per page:"
  'ra.navigation.page_rows_per_page': '페이지 당:',
  // "ra.navigation.next": "Next"
  'ra.navigation.next': '다음',
  // "ra.navigation.prev": "Prev"
  'ra.navigation.prev': '이전',
  // "ra.auth.auth_check_error": "Please login to continue"
  'ra.auth.auth_check_error': '로그인 후 계속 진행할 수 있습니다',
  // "ra.auth.user_menu": "Profile"
  'ra.auth.user_menu': '프로필',
  // "ra.auth.username": "Username"
  'ra.auth.username': '아이디',
  // "ra.auth.password": "Password"
  'ra.auth.password': '비밀번호',
  // "ra.auth.sign_in": "Sign in"
  'ra.auth.sign_in': '로그인',
  // "ra.auth.sign_in_error": "Authentication failed, please retry"
  'ra.auth.sign_in_error': '인증이 실패했습니다. 다시 시도해보세요',
  // "ra.auth.logout": "Logout"
  'ra.auth.logout': '로그아웃',
  // "ra.notification.updated": "Element updated |||| %{smart_count} elements updated"
  'ra.notification.updated': '{smart_count} 개 항목이 수정되었습니다',
  // "ra.notification.created": "Element created"
  'ra.notification.created': '항목이 생성되었습니다',
  // "ra.notification.deleted": "Element deleted |||| %{smart_count} elements deleted"
  'ra.notification.deleted': '{smart_count} 개 항목이 삭제되었습니다',
  // "ra.notification.bad_item": "Incorrect element"
  // "ra.notification.item_doesnt_exist": "Element does not exist"
  // "ra.notification.http_error": "Server communication error"
  // "ra.notification.data_provider_error": "dataProvider error. Check the console for details."
  // "ra.notification.i18n_error": "Cannot load the translations for the specified language"
  // "ra.notification.canceled": "Action cancelled"
  'ra.notification.canceled': '실행이 취소되었습니다',
  // "ra.notification.logged_out": "Your session has ended, please reconnect."
  // "ra.validation.required": "Required"
  // "ra.validation.minLength": "Must be %{min} characters at least"
  // "ra.validation.maxLength": "Must be %{max} characters or less"
  // "ra.validation.minValue": "Must be at least %{min}"
  // "ra.validation.maxValue": "Must be %{max} or less"
  // "ra.validation.number": "Must be a number"
  // "ra.validation.email": "Must be a valid email"
  // "ra.validation.oneOf": "Must be one of: %{options}"
  // "ra.validation.regex": "Must match a specific format (regexp): %{pattern}"
}
