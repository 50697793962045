import { Dropdown, Menu } from 'antd';
import React from 'react';
import { CLIENT_BASE_URL } from 'config';
import { Link } from 'react-router-dom';

type TProps = {
  id: string;
};

const DropdownUserManagement: React.FC<TProps> = ({ id }) => {
  const menuHref = `${CLIENT_BASE_URL}/user-info/${id}`;
  const userManagementMenu = (
    <Menu
      items={[
        {
          key: 'postTracking',
          label: (
            <a href={`${menuHref}/posts/all`} target="_blank">
              작성글 추적
            </a>
          ),
        },
        {
          key: 'commentTracking',
          label: (
            <a href={`${menuHref}/comments`} target="_blank">
              작성댓글 추적
            </a>
          ),
        },
      ]}
    />
  );

  return (
    <Dropdown overlay={userManagementMenu}>
      <a onClick={(event) => event.preventDefault()}>{id}</a>
    </Dropdown>
  );
};

export default DropdownUserManagement;
