import React from 'react';
import { Button } from 'antd';

const ColumnsDetailReportPost = (handleReadReport: any) => {
  return [
    {
      title: '신고 일시',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (_, record) => (
        <span className={record.adminReadAt ? 'read-report-column' : ''}>
          {record['createdAt']}
        </span>
      ),
    },
    {
      title: '신고자',
      dataIndex: ['reporterNickname', 'reporterId'],
      key: 'reporterId',
      render: (_, record) => (
        <span className={record.adminReadAt ? 'read-report-column' : ''}>
          닉네임 : {record['reporterNickname']} / ID : {record['reporterId']}
        </span>
      ),
    },
    {
      title: '신고사유',
      dataIndex: 'description',
      key: 'description',
      render: (_, record) => (
        <span className={record.adminReadAt ? 'read-report-column' : ''}>
          {record['description']}
        </span>
      ),
    },
    {
      title: '',
      dataIndex: '',
      render: (_, record: any) => (
        <Button
          size="small"
          disabled={record.adminReadAt !== null}
          onClick={() => handleReadReport(record.key)}
        >
          신고확인
        </Button>
      ),
    },
  ].filter((col) => col.dataIndex !== 'key');
};

export default ColumnsDetailReportPost;
