import { IntlShape } from 'react-intl'
import englishMessages from 'ra-language-english'
import get from 'lodash/get'
import raMessagess from './ra-messages'

export const messages: Record<string, string> = {
  ...raMessagess,

  'resources.boards.name': '게시판',

  'resources.posts.name': '게시글',
  'resources.posts.fields.boardTitle': '',
  'resources.posts.fields.postCollectionIDs': '게시글 모음 ID',
  'resources.posts.fields.tagIDs': '태그',

  'resources.post-collections.name': '게시글 모음',

  'resources.users.name': '유저',
  'resources.users.fields.posts.totalCount': '게시글 수',

  'resources.daily-posts.name': '날짜별 게시글',
  'resources.daily-comments.name': '날짜별 댓글',
  'resources.board-navigations.name': '게시판 네비게이션',

  'resources.tags.name': '태그 관리',
  'resources.tags.fields.id': 'ID',
  'resources.tags.fields.name': '태그 이름',
  'resources.tags.fields.createdAt': '생성일',
  'resources.tags.fields.updatedAt': '수정일',

  'msg.dashboard': '대시보드',
  'msg.id': 'ID',
  'msg.username': '이름',
  'msg.nickname': '닉네임',
  'msg.post-admin': '게시글 관리',
  'msg.summary': '요약',
  'msg.search': '검색',

  'msg.global-notice.set': '전체 공지 설정',
  'msg.global-notice.unset': '전체 공지 해제',

  'msg.config.seo': 'SEO',
  'msg.config.message': '메시지',
  'msg.config.ui': 'UI',
}

export function createI18nProvider(intl: IntlShape) {
  const hasWarned = new Set<string>()

  return {
    translate(messageID: string, params: any) {
      if (!messageID) {
        console.warn(`invalid messageID: "${messageID}"`)
        return null
      }

      const result = intl.formatMessage({ id: messageID }, params)

      if (result === messageID && !hasWarned.has(messageID)) {
        hasWarned.add(messageID)

        const recommendation = get(englishMessages, messageID)
        if (recommendation) {
          console.warn(
            `i18n recommendation: '${messageID}' = '${recommendation}'`,
          )
        }
      }

      return result
    },

    changeLocale(...args: any[]) {
      throw new Error('not implemented')
    },

    getLocale(...args: any[]) {
      return intl.locale
    },
  }
}
