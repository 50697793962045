import React, { FC } from 'react'
import {
  TextField,
  Edit,
  SimpleForm,
  ReferenceInput,
  SelectInput,
} from 'react-admin'

interface BoardNavigationTitleProps {
  record?: any
}

const BoardNavigationTitle: FC<BoardNavigationTitleProps> = ({ record }) => {
  return <span>BoardNavigation {record ? `"${record.name}"` : ''}</span>
}

const BoardNavigationEdit: FC = props => {
  return (
    <Edit title={<BoardNavigationTitle />} {...props}>
      <SimpleForm>
        <TextField source="id" label="ID" />
        <TextField source="name" label="게시판" />
        <ReferenceInput label="Board" source="board.id" reference="boards">
          <SelectInput optionText="title" />
        </ReferenceInput>
      </SimpleForm>
    </Edit>
  )
}

export default BoardNavigationEdit
