import React, { useEffect, useState } from 'react'
import { message, Skeleton } from 'antd'
import {
  OrderDirection,
  PostReportOrderField,
  ReportReadStatus,
  useReportedPostsLazyQuery,
} from 'generated/graphql'
import { useHistory, useLocation } from 'react-router-dom'
import { AdminMenuTitle } from 'components/AdminMenuTitle'
import TableReportPost from '../component/table/TableReportPost'
import useAuthenticationCheck from 'resources/best-posts/hooks/useAuthenticationCheck'
import generateVariablesReportPost from '../gql/variable/generateVariablesReportPost'
import FormDateRangePickerWithSelectInput from '../../component/form/FormDateRangePickerWithSelectInput'
import RadioReadConditionSelect from '../../component/radio/RadioReadConditionSelect'
import convertPaginiationQueryString from 'app.module/module/convertPaginiationQueryString'

type VaraiblesState = {
  readState: string
  startDate: string | null
  endDate: string | null
  selectCondition: string
  inputCondition: string | null
  field: PostReportOrderField
  direction: OrderDirection
}

const ScreenReportPost = () => {
  useAuthenticationCheck()

  const router = useHistory()
  const { search } = useLocation()
  const urlParams: any = convertPaginiationQueryString(search)

  const [variables, setVariables] = useState<VaraiblesState>({
    readState: urlParams.readState || 'unread',
    startDate: null,
    endDate: null,
    selectCondition: 'title',
    inputCondition: null,
    field: PostReportOrderField['LAST_REPORTED_AT'],
    direction: OrderDirection['DESC'],
  })

  const [refetch, { data, loading, error }] = useReportedPostsLazyQuery({
    fetchPolicy: 'no-cache',
    variables: {
      ...generateVariablesReportPost(),
      filterBy: {
        reportReadStatus:
          ReportReadStatus[variables['readState'].toUpperCase()],
      },
    },
  })

  if (error) {
    message.error('에러가 발생했습니다.')
    return <div>에러가 발생했습니다.</div>
  }

  const handleFormSubmit = async ({
    startDate,
    endDate,
    selectCondition,
    inputCondition,
  }) => {
    setVariables({
      ...variables,
      startDate,
      endDate,
      selectCondition,
      inputCondition,
    })
    router.push(
      `?page=1&pageSize=${Number(urlParams.pageSize) || 10}&readState=${
        variables.readState
      }`,
    )
  }

  useEffect(() => {
    router.push(
      `?page=${Number(urlParams.page) || 1}&pageSize=${Number(
        urlParams.pageSize,
      ) || 10}&readState=${variables.readState}`,
    )
  }, [])

  useEffect(() => {
    refetch({
      variables: {
        filterBy: {
          startDate: variables.startDate,
          endDate: variables.endDate,
          reportReadStatus:
            ReportReadStatus[variables['readState'].toUpperCase()],
          [variables.selectCondition]: variables.inputCondition,
        },
        orderBy: {
          field: PostReportOrderField[variables.field],
          direction: OrderDirection[variables.direction],
        },
        pagination: {
          page: Number(urlParams.page) || 1,
          pageSize: Number(urlParams.pageSize) || 10,
        },
      },
    })
  }, [variables, search])

  const handleChangeReadState = async (state: string) => {
    setVariables({
      ...variables,
      readState: state,
    })
    router.push(
      `?page=1&pageSize=${urlParams.pageSize || 10}&readState=${state}`,
    )
  }

  const handleChangeTableSorting = (field: string, direction: string) => {
    setVariables(prev => {
      if (prev.direction === direction) return prev

      return {
        ...prev,
        field: PostReportOrderField[field],
        direction: OrderDirection[direction],
      }
    })

    router.push(
      `?page=1&pageSize=${urlParams.pageSize || 10}&readState=${
        variables.readState
      }`,
    )
  }

  return (
    <>
      <AdminMenuTitle
        title="신고 게시글"
        description="신고된 게시글을 조회힙니다"
      />
      <RadioReadConditionSelect onChangeReadState={handleChangeReadState} />
      <FormDateRangePickerWithSelectInput
        type="title"
        onFormSubmit={handleFormSubmit}
      />
      {loading ? (
        <Skeleton />
      ) : (
        <TableReportPost
          total={data?.reportedPosts?.totalCount}
          data={data?.reportedPosts?.nodes}
          onDirectionChange={handleChangeTableSorting}
        />
      )}
    </>
  )
}

export default ScreenReportPost
