import React from 'react'
import styled from 'styled-components'
import { Button, Input } from 'antd'

type TProps = {
  props?: object | any
}

const SearchUserConditionInput: React.FC<TProps> = props => {
  return (
    <StyledWrapper>
      <div className="form">
        <Input
          {...props}
          className="form-input"
          placeholder="검색어를 입력하세요."
        />
        <Button className="form-search" htmlType="submit">
          검색
        </Button>
      </div>
    </StyledWrapper>
  )
}

export default SearchUserConditionInput

const StyledWrapper = styled.div`
  .form {
    display: flex;
  }
  .form-input {
    width: 500px;
  }
  .form-search {
    margin-left: 5px;
  }
`
