import {
  AuthCheckDocument,
  AuthLoginDocument,
  AuthLogoutDocument,
  AuthGetPermissionsDocument,
} from 'generated/graphql.main'
import { apolloClient } from './graphql'

export function createAuthProvider() {
  return {
    login: async ({ username, password }) => {
      const result = await apolloClient.mutate({
        variables: { email: username, password },
        mutation: AuthLoginDocument,
        context: {
          clientName: 'main',
        },
      })

      const { user } = result.data.login
      return user
        ? null
        : Promise.reject(new Error('login error: current user not found'))
    },

    logout: async () => {
      // called when the user clicks on the logout button
      const result = await apolloClient.mutate({
        mutation: AuthLogoutDocument,
        context: {
          clientName: 'main',
        },
      })
      const logout = result.data.logout
      return logout
        ? null
        : Promise.reject(new Error('logout error: current user not found'))
    },

    checkError: params => {
      console.error(params)
    },

    checkAuth: async () => {
      const result = await apolloClient.query({
        fetchPolicy: 'network-only',
        query: AuthCheckDocument,
        context: {
          clientName: 'main',
        },
      })
      const { currentUser } = result.data

      if (!currentUser.isSuperuser) {
        return Promise.reject(
          new Error('permission error: current user is not superuser'),
        )
      }

      return currentUser
        ? null
        : Promise.reject(new Error('check auth error: current user not found'))
    },

    getPermissions: async () => {
      // called when the user navigates to a new location
      const result = await apolloClient.query({
        fetchPolicy: 'network-only',
        context: {
          clientName: 'main',
        },
        query: AuthGetPermissionsDocument,
      })

      const { currentUser } = result.data
      if (!currentUser) {
        return Promise.reject(
          new Error('permission error: current user not found'),
        )
      }

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { id, __typename, ...other } = currentUser
      return other
    },
  }
}
