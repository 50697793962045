import {
  BestPostsQueryVariables,
  OrderDirection,
  PostOrderField,
} from 'generated/graphql'
import { FormInstance } from 'antd'
import { formDataType } from '../../components/BestPostRequirement'

const getQueryVariableFrom = (
  formInstance: FormInstance<formDataType>,
): BestPostsQueryVariables => {
  const inputValues = formInstance.getFieldsValue()

  return {
    filterBy: {
      minimumCommentCount: Number(inputValues.comment),
      minimumViewCount: Number(inputValues.view),
      minimumLikeCount: Number(inputValues.like),
      scrapCount: Number(inputValues.scrap),
    },
    orderBy: {
      field: PostOrderField['SCORE'],
      direction: OrderDirection['DESC'],
    },
    pagination: {
      page: 1,
      pageSize: 100,
    },
  }
}

export default getQueryVariableFrom
