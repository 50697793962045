import React, { useState } from 'react'
import { AdminMenuTitle } from 'components/AdminMenuTitle'
import { Block as BlockIcon } from '@material-ui/icons'
import FormUsersBan from '../component/form/FormUsersBan'
import TableUsersBan from '../component/table/TableUsersBan'
import ModalConfirmReleaseUser from '../component/modal/ModalConfirmReleaseUser'
import useAuthenticationCheck from '../../../../resources/best-posts/hooks/useAuthenticationCheck'
import {
  BanLogsOrderField,
  OrderDirection,
  useBanLogsQuery,
  useUnbanUsersMutation,
} from 'generated/graphql'
import { message, Skeleton } from 'antd'
import { format } from 'date-fns'
import { useHistory } from 'react-router-dom'

const ScreenUsersBanManagement = () => {
  const router = useHistory()
  useAuthenticationCheck()

  const [releaseUser, setReleaseUser] = useState<any[]>([])
  const [disabledButton, setDisabledButton] = useState(true)
  const [isModalVisible, setIsModalVisible] = useState(false)

  const { data, loading, error, refetch } = useBanLogsQuery({
    variables: {
      filterBy: {
        userID: null,
        nickname: null,
      },
      pagination: {
        page: 1,
        pageSize: 10,
      },
      orderBy: {
        field: BanLogsOrderField['START_AT'],
        direction: OrderDirection['DESC'],
      },
    },
  })

  const [unbanUsersMutation] = useUnbanUsersMutation({
    onError: () => {
      message.error('조건 변경에 실패하였습니다.')
      setIsModalVisible(false)
    },
    onCompleted: () => {
      message.success('성공적으로 반영되었습니다.')
      setIsModalVisible(false)
    },
  })

  if (error) {
    message.error('에러가 발생했습니다.')
    return <div>에러가 발생했습니다.</div>
  }

  const handleGetClickedUser = selectedUser => {
    setDisabledButton(selectedUser?.length == 0)
    setReleaseUser(selectedUser)
  }

  const handleOk = async () => {
    await unbanUsersMutation({
      variables: {
        logIDs: releaseUser.map(item => item.key),
      },
    })
    refetch()
  }

  const handleRefetch = (key, inputVal) => {
    refetch({
      filterBy: {
        [key]: inputVal === '' ? null : inputVal,
      },
      pagination: {
        page: 1,
        pageSize: 10,
      },
    })
    router.push(`?page=1&pageSize=10`)
    setDisabledButton(true)
  }

  return (
    <>
      <AdminMenuTitle
        icon={<BlockIcon />}
        title="활동 정지 유저"
        description="활동 정지 유저를 관리합니다"
      />
      <ModalConfirmReleaseUser
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={() => setIsModalVisible(false)}
      />
      <FormUsersBan
        onReleaseUser={() => setIsModalVisible(true)}
        disabledButton={disabledButton}
        onRefetch={handleRefetch}
      />
      {loading ? (
        <Skeleton />
      ) : (
        <TableUsersBan
          totalCount={data?.banLogs?.totalCount}
          dataSource={data?.banLogs?.nodes?.map(data => {
            return {
              key: data?.id,
              id: data?.user?.id,
              nickname: data?.user?.nickname,
              description: data?.description,
              startAt: format(new Date(data?.startAt), 'yyyy-MM-dd'),
              endAt: format(new Date(data?.endAt), 'yyyy-MM-dd'),
              deletedAt:
                data?.deletedAt === null
                  ? ''
                  : format(new Date(data?.deletedAt), 'yyyy-MM-dd'),
            }
          })}
          onCheckedUser={handleGetClickedUser}
          onRefetch={refetch}
        />
      )}
    </>
  )
}

export default ScreenUsersBanManagement
