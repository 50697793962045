import React from 'react';
import { Select } from 'antd';
const { Option } = Select;

type TProps = {
  props?: object | any;
};

const SelectBanUserPeriod: React.FC<TProps> = (props) => {
  return (
    <Select {...props} className="select" defaultValue="7">
      <Option value="1">1일</Option>
      <Option value="7">7일</Option>
      <Option value="15">15일</Option>
      <Option value="30">30일</Option>
      <Option value="permanent">무기한 활동정지</Option>
    </Select>
  );
};

export default SelectBanUserPeriod;
