import React, { FunctionComponent } from 'react'
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  DateField,
  ReferenceArrayField,
  ReferenceInput,
  SelectInput,
  SingleFieldList,
  ChipField,
  EditButton,
  BulkDeleteButton,
  Button,
  crudUpdateMany,
  Filter,
  TextInput,
} from 'react-admin'
import { useDispatch } from 'react-redux'

const BulkUpdateGlobalNoticeButton: FunctionComponent<any> = props => {
  const { label, basePath, resource, selectedIds, value } = props

  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(
      crudUpdateMany(resource, selectedIds, { globalNotice: value }, basePath),
    )
  }

  return <Button label={label} onClick={handleClick} />
}

const PostBulkActionButtons: FunctionComponent = props => {
  return (
    <>
      <BulkUpdateGlobalNoticeButton
        label="msg.global-notice.set"
        {...props}
        value={true}
      />
      <BulkUpdateGlobalNoticeButton
        label="msg.global-notice.unset"
        {...props}
        value={false}
      />
      {/* Add the default bulk delete action */}
      <BulkDeleteButton {...props} />
    </>
  )
}

const PostFilter: FunctionComponent = props => {
  return (
    <Filter {...props}>
      <TextInput label="제목 또는 아이디" source="q" alwaysOn />
      <ReferenceInput label="태그" source="tagID" reference="tags">
        <SelectInput optionText="name" />
      </ReferenceInput>
    </Filter>
  )
}

export const PostList: FunctionComponent = props => (
  <List
    {...props}
    bulkActionButtons={<PostBulkActionButtons />}
    filters={<PostFilter />}
  >
    <Datagrid>
      <TextField source="id" label="ID" />
      <TextField source="board.title" label="게시판" sortable={false} />
      <TextField source="username" label="아이디" sortable={false} />
      <TextField source="title" label="제목" sortable={false} />
      <BooleanField source="globalNotice" label="전체 공지 여부" />
      <ReferenceArrayField
        label="소속된 게시글 모음"
        reference="post-collections"
        source="postCollectionIDs"
        sortable={false}
      >
        <SingleFieldList>
          <ChipField source="title" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField
        label="태그"
        reference="tags"
        source="tagIDs"
        sortable={false}
      >
        <SingleFieldList {...props}>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <DateField source="updatedAt" label="최근 수정일" showTime />
      <EditButton />
    </Datagrid>
  </List>
)

export default PostList
