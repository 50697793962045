import gql from 'graphql-tag'
import {
  BoardGetListDocument,
  BoardGetDocument,
  BoardGetManyDocument,
} from 'generated/graphql'
import { QueryBuilder } from './base.query'

export function createBoardQueryBuilder(): QueryBuilder {
  return {
    getList: {
      query: BoardGetListDocument,
    },
    getOne: {
      query: BoardGetDocument,
    },
    getMany: {
      query: BoardGetManyDocument,
    },
  }
}

gql`
  fragment BoardFragment on Board {
    id
    title
  }
`

gql`
  query BoardGetList($filterBy: BoardFiltersInput, $pagination: Pagination) {
    data: boards(filterBy: $filterBy, pagination: $pagination) {
      data: nodes {
        ...BoardFragment
      }
      total: totalCount
    }
  }
`

gql`
  query BoardGet($id: ID!) {
    data: board(id: $id) {
      ...BoardFragment
    }
  }
`

gql`
  query BoardGetMany($ids: [ID!]!) {
    data: boardsByID(ids: $ids) {
      ...BoardFragment
    }
  }
`
