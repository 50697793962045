import { RequirementInputsFragment } from 'generated/graphql'

const setFormFieldsTo = (
  formInstance: any,
  initialValue: RequirementInputsFragment,
) => {
  formInstance.setFieldsValue({
    comment: initialValue.comment,
    view: initialValue.view,
    scrap: initialValue.scrap,
    like: initialValue.like,
  })
}

export default setFormFieldsTo
