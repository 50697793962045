import React, { useState } from 'react'
import styled from 'styled-components'
import { Radio, RadioChangeEvent } from 'antd'
import { useLocation } from 'react-router-dom'
import convertPaginiationQueryString from 'app.module/module/convertPaginiationQueryString'
const { Button, Group } = Radio

type TProps = {
  onChangeReadState: (value: any) => void
}

const RadioReadConditionSelect: React.FC<TProps> = ({ onChangeReadState }) => {
  const { search } = useLocation()
  const urlParams: any = convertPaginiationQueryString(search)
  const [mode, setMode] = useState(urlParams['readState'] ?? 'unread')

  const handleModeChange = (event: RadioChangeEvent) => {
    setMode(event.target.value)
    onChangeReadState(event.target.value)
  }

  return (
    <StyledWrapper>
      <div className="radio-group">
        <Group buttonStyle="solid" onChange={handleModeChange} value={mode}>
          <Button value="unread">안읽음</Button>
          <Button value="read">읽음</Button>
        </Group>
      </div>
    </StyledWrapper>
  )
}

export default RadioReadConditionSelect

const StyledWrapper = styled.div`
  .radio-group {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 10px;
  }
`
