import React from 'react'
import styled from 'styled-components'
import { Descriptions, Tag, Typography } from 'antd'
import { CLIENT_BASE_URL } from 'config'
import { PostQuery } from 'generated/graphql'
const { Paragraph } = Typography
const { Item } = Descriptions

type TProps = {
  data?: PostQuery['post'] | null
}

const DescriptonReportPost: React.FC<TProps> = ({ data }) => {
  const url = `${CLIENT_BASE_URL}/community/${data?.id}`

  const generateTag = () => {
    if (data?.deletedAt === null) return <Tag color="processing">아니오</Tag>
    if (data?.deletedAt === undefined)
      return '게시글 삭제 여부가 존재하지 않습니다.'
    return <Tag color="error">네</Tag>
  }

  return (
    <StyledWrapper>
      <h2>
        <Paragraph>
          <blockquote>게시글 상세 정보</blockquote>
        </Paragraph>
      </h2>
      <Descriptions
        bordered
        size="small"
        className="description"
        column={1}
        labelStyle={{ width: 200 }}
        contentStyle={{ backgroundColor: 'white' }}
      >
        <Item label="게시글 제목">{data?.title || ''}</Item>
        <Item label="게시글 URL">
          <a href={url} target="_blank">
            {url}
          </a>
        </Item>
        <Item label="신고대상">{`닉네임 : ${data?.nickname || ''} / ID : ${data
          ?.author?.id || ''}`}</Item>
        <Item label="누적신고수">{data?.reportCount || ''}</Item>
        <Item label="신고사유">
          {data?.reportCountByDescription
            ? data?.reportCountByDescription?.map((data, index) => {
                return (
                  <Tag color="default" key={index}>
                    {data?.description ?? '신고 사유 없음'} : {data.count}
                  </Tag>
                )
              })
            : '신고 사유가 없습니다.'}
        </Item>
        <Item label="게시글 삭제여부">{generateTag()}</Item>
      </Descriptions>
    </StyledWrapper>
  )
}

export default DescriptonReportPost

const StyledWrapper = styled.div`
  .description,
  .collapse {
    margin-top: 15px;
  }
`
