import React from 'react'
import { Table } from 'antd'
import { useHistory, useLocation } from 'react-router-dom'
import convertPaginiationQueryString from 'app.module/module/convertPaginiationQueryString'
import ColumnsReportPost from '../column/ColumnsReportPost'
import { format } from 'date-fns'
import { SortDirection } from 'app.feature/board/report/module/constant/sortOrderBy'
import { Field } from 'app.feature/board/report/module/reportOrderField'

type TProps = {
  data: any
  total: number | undefined
  onDirectionChange: (field, direction) => void
}

const TableReportPost: React.FC<TProps> = ({
  data,
  total,
  onDirectionChange,
}) => {
  const dataSource = data?.map(item => {
    return {
      key: item?.id,
      id: item?.author?.id,
      nickname: item?.nickname,
      title: item?.title,
      lastReportedAt: format(
        new Date(item?.lastReportedAt),
        'yyyy-MM-dd HH:mm',
      ),
      reportCount: item?.reportCount,
      ipaddress: item?.ipaddress,
      reportDescription: item?.reportDescription,
    }
  })

  const router = useHistory()
  const { search } = useLocation()
  const tableColumns: any = ColumnsReportPost()
  const pagination: any = convertPaginiationQueryString(search)

  const paginationOptions = {
    pageSize: Number(pagination.pageSize || 10),
    current: Number(pagination.page) || 1,
    showSizeChanger: true,
    total,
    onChange(page, pageSize) {
      router.push(
        `?page=${page}&pageSize=${pageSize}&readState=${pagination.readState}`,
      )
    },
  }

  const onChangeTabSorting = (...restParams: any) => {
    if (restParams[3].action !== 'sort') return
    const field = Field[restParams[2].field]
    const direction = SortDirection[restParams[2].order]
    onDirectionChange(field, direction)
  }

  return (
    <Table
      bordered
      size="small"
      onChange={onChangeTabSorting}
      pagination={paginationOptions}
      columns={tableColumns}
      dataSource={dataSource}
      locale={{ emptyText: '데이터가 없습니다.' }}
    />
  )
}

export default TableReportPost
