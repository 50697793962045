import React, { FC } from 'react'
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  ReferenceArrayField,
  DateField,
  EditButton,
} from 'react-admin'

const PostCollectionList: FC = props => {
  return (
    <List {...props}>
      <Datagrid>
        <TextField source="id" label="ID" />
        <TextField source="title" label="제목" sortable={false} />
        <ReferenceArrayField label="게시물" reference="posts" source="postIDs">
          <Datagrid>
            <TextField source="id" label="ID" />
            <TextField source="board.title" label="게시판" />
            <TextField source="title" label="제목" />
          </Datagrid>
        </ReferenceArrayField>
        <BooleanField source="automation.enabled" label="자동화 사용 여부" />
        <DateField source="updatedAt" label="최근 수정일" showTime />
        <EditButton />
      </Datagrid>
    </List>
  )
}

export default PostCollectionList
