import gql from 'graphql-tag'
import {
  UserGetListDocument,
  UserGetDocument,
  UserGetManyDocument,
} from 'generated/graphql'
import { QueryBuilder } from './base.query'

export function createUserQueryBuilder(): QueryBuilder {
  return {
    getList: {
      query: UserGetListDocument,
    },
    getOne: {
      query: UserGetDocument,
    },
    getMany: {
      query: UserGetManyDocument,
    },
  }
}

gql`
  fragment UserFragment on User {
    id
    username
    nickname
    posts {
      totalCount
    }
  }
`

gql`
  query UserGetList(
    $filterBy: UserFilters
    $orderBy: UserOrder
    $pagination: Pagination
  ) {
    data: users(
      filterBy: $filterBy
      orderBy: $orderBy
      pagination: $pagination
    ) {
      data: nodes {
        ...UserFragment
      }
      total: totalCount
    }
  }
`

gql`
  query UserGet($id: ID!) {
    data: user(id: $id) {
      ...UserFragment
    }
  }
`

gql`
  query UserGetMany($ids: [ID!]!) {
    data: usersByID(ids: $ids) {
      ...UserFragment
    }
  }
`
