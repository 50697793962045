import { message } from 'antd';

const generateMutationMessage = (errorMessage, completeMessage) => {
  return {
    onError: () => {
      message.error(`${errorMessage}`);
    },
    onCompleted: () => {
      message.success(`${completeMessage}`);
    },
  };
};

export default generateMutationMessage;
