import React from 'react'
import { useParams } from 'react-router-dom'
import { message, Skeleton } from 'antd'
import { useCommentsQuery } from 'generated/graphql'
import { AdminMenuTitle } from 'components/AdminMenuTitle'
import DescriptionReportComment from '../component/description/DescriptionReportComment'
import TableDetailReportComment from '../component/table/TableDetailReportComment'

const ScreenDetailReportComment = () => {
  const params = useParams()
  const { data, loading, error, refetch } = useCommentsQuery({
    variables: {
      commentId: params['id'],
      filterBy: {
        commentID: params['id'],
      },
      pagination: {
        page: 1,
        pageSize: 10,
      },
    },
  })

  if (error) {
    message.error('에러가 발생했습니다.')
    return <div>에러가 발생했습니다.</div>
  }

  return (
    <>
      <AdminMenuTitle
        title="신고 댓글 관리"
        description="신고 댓글을 관리합니다"
      />
      {loading ? (
        <Skeleton />
      ) : (
        <>
          <DescriptionReportComment data={data?.comment} />
          <TableDetailReportComment
            total={data?.commentAbuseReportLogs?.totalCount}
            data={data?.commentAbuseReportLogs?.nodes}
            deletedAt={data?.comment?.deletedAt}
            onRefetch={refetch}
          />
        </>
      )}
    </>
  )
}

export default ScreenDetailReportComment
