import { useAuthCheckQuery } from 'generated/graphql.main'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const useAuthenticationCheck = () => {
  const { data, loading } = useAuthCheckQuery()
  const history = useHistory()

  useEffect(() => {
    if (loading) return
    if (data?.currentUser?.id) return
    history.push('/login')
  }, [data])
}

export default useAuthenticationCheck
