import React, { FC } from 'react'
import {
  List,
  Datagrid,
  TextField,
  DateField,
  EditButton
} from 'react-admin'

const TagList: FC = props => {
  return (
    <List {...props}>
      <Datagrid>
        <TextField source="id" />
        <TextField source="name" />
        <DateField source="createdAt" />
        <DateField source="updatedAt" />
        <EditButton />
      </Datagrid>
    </List>
  )
}

export default TagList
