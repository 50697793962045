import { isEqual } from 'lodash'

export function stripTypename(value: any): any {
  if (!value || typeof value !== 'object') {
    return value
  }

  if (Array.isArray(value)) {
    return value.map(stripTypename)
  }

  const result: any = {}
  Object.keys(value).forEach(key => {
    if (key === '__typename') {
      return
    }
    result[key] = stripTypename(value[key])
  })

  return result
}

export function diff(prev: any, cur: any) {
  const result: any = {}

  const keys = Object.keys(cur)
  for (let i = 0; i < keys.length; ++i) {
    const key = keys[i]

    if (isEqual(prev[key], cur[key])) {
      continue
    }

    result[key] = cur[key]
  }

  return result
}
