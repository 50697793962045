import gql from 'graphql-tag'
import {
  PostCollectionGetListDocument,
  PostCollectionGetDocument,
  PostCollectionGetManyDocument,
  PostCollectionCreateDocument,
  PostCollectionUpdateDocument,
  PostCollectionFragmentFragment,
} from 'generated/graphql'
import { QueryBuilder } from './base.query'

function parseRecord(record: PostCollectionFragmentFragment) {
  const { posts, automation, ...other } = record
  const { boards, ...animationOthers } = automation!

  return {
    ...other,
    postIDs: posts.map(post => post.id),
    automation: {
      ...animationOthers,
      boardIDs: boards.map(board => board.id),
    },
  }
}

export function createPostCollectionQueryBuilder(): QueryBuilder {
  return {
    getList: {
      query: PostCollectionGetListDocument,
    },
    getOne: {
      query: PostCollectionGetDocument,
    },
    getMany: {
      query: PostCollectionGetManyDocument,
    },
    create: {
      query: PostCollectionCreateDocument,
    },
    update: {
      query: PostCollectionUpdateDocument,
    },
    parseRecord,
  }
}

gql`
  fragment PostCollectionFragment on PostCollection {
    id
    title
    posts(pagination: { page: 1, pageSize: 100 }) {
      id
    }
    automation {
      enabled
      boards {
        id
      }
      filters {
        views {
          gte
        }
      }
    }
    updatedAt
  }
`

gql`
  query PostCollectionGetList(
    $filterBy: PostCollectionFiltersInput
    $orderBy: PostCollectionOrder
    $pagination: Pagination
  ) {
    data: postCollections(
      filterBy: $filterBy
      orderBy: $orderBy
      pagination: $pagination
    ) {
      data: nodes {
        ...PostCollectionFragment
      }
      total: totalCount
    }
  }
`

gql`
  query PostCollectionGet($id: ID!) {
    data: postCollection(id: $id) {
      ...PostCollectionFragment
    }
  }
`

gql`
  query PostCollectionGetMany($ids: [ID!]!) {
    data: postCollectionsByID(ids: $ids) {
      ...PostCollectionFragment
    }
  }
`

gql`
  mutation PostCollectionCreate($input: PostCollectionInput!) {
    data: postCollectionCreate(input: $input) {
      data: postCollection {
        ...PostCollectionFragment
      }
    }
  }
`

gql`
  mutation PostCollectionUpdate($id: ID!, $input: PostCollectionInput!) {
    data: postCollectionUpdate(id: $id, input: $input) {
      data: postCollection {
        ...PostCollectionFragment
      }
    }
  }
`
