import {
  OrderDirection,
  PostReportOrderField,
  ReportedPostsQueryVariables,
} from 'generated/graphql'

const generateVariablesReportPost: () =>
  | ReportedPostsQueryVariables
  | undefined = () => {
  return {
    pagination: {
      page: 1,
      pageSize: 10,
    },
    orderBy: {
      field: PostReportOrderField['LAST_REPORTED_AT'],
      direction: OrderDirection['DESC'],
    },
  }
}

export default generateVariablesReportPost
