import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import {
  OrderDirection,
  PostTrashOrderField,
  useTrashedPostsLazyQuery,
} from 'generated/graphql'
import { message, Skeleton } from 'antd'
import { AdminMenuTitle } from 'components/AdminMenuTitle'
import TableDeletedPost from '../component/table/TableDeletedPost'
import FormDateRangePickerWithSelectInput from 'app.feature/board/report/component/form/FormDateRangePickerWithSelectInput'
import useAuthenticationCheck from 'resources/best-posts/hooks/useAuthenticationCheck'
import generateVariableDeletedPost from '../gql/variable/generateVariableDeletedPost'
import convertPaginiationQueryString from 'app.module/module/convertPaginiationQueryString'

type VaraiblesState = {
  startDate: string | null
  endDate: string | null
  selectCondition: string
  inputCondition: string | null
  field: PostTrashOrderField
  direction: OrderDirection
}

const ScreenDeletedPost = () => {
  useAuthenticationCheck()

  const router = useHistory()
  const { search } = useLocation()
  const urlParams: any = convertPaginiationQueryString(search)

  const [variables, setVariables] = useState<VaraiblesState>({
    startDate: null,
    endDate: null,
    selectCondition: 'title',
    inputCondition: null,
    field: PostTrashOrderField['DELETED_AT'],
    direction: OrderDirection['DESC'],
  })

  const [refetch, { data, loading, error }] = useTrashedPostsLazyQuery({
    fetchPolicy: 'no-cache',
    variables: {
      ...generateVariableDeletedPost(),
    },
  })

  if (error) {
    message.error('에러가 발생했습니다.')
    return <div>에러가 발생했습니다.</div>
  }

  const handleFormSubmit = async ({
    startDate,
    endDate,
    selectCondition,
    inputCondition,
  }) => {
    setVariables({
      ...variables,
      startDate,
      endDate,
      selectCondition,
      inputCondition,
    })
    router.push(`?page=1&pageSize=${Number(urlParams.pageSize) || 10}`)
  }

  useEffect(() => {
    router.push(
      `?page=${Number(urlParams.page) || 1}&pageSize=${Number(
        urlParams.pageSize,
      ) || 10}`,
    )
  }, [])

  useEffect(() => {
    refetch({
      variables: {
        filterBy: {
          startDate: variables['startDate'],
          endDate: variables['endDate'],
          [variables.selectCondition]: variables.inputCondition,
        },
        orderBy: {
          field: PostTrashOrderField[variables.field],
          direction: OrderDirection[variables.direction],
        },
        pagination: {
          page: Number(urlParams.page) || 1,
          pageSize: Number(urlParams.pageSize) || 10,
        },
      },
    })
  }, [variables, search])

  const handleChangeTableSorting = (field: string, direction: string) => {
    setVariables(prev => {
      if (prev.direction === direction) return prev

      return {
        ...prev,
        field: PostTrashOrderField[field],
        direction: OrderDirection[direction],
      }
    })

    router.push(`?page=1&pageSize=${urlParams.pageSize || 10}`)
  }

  return (
    <>
      <AdminMenuTitle
        title="삭제된 게시글"
        description="삭제된 게시글을 관리합니다"
      />
      <FormDateRangePickerWithSelectInput
        type="title"
        onFormSubmit={handleFormSubmit}
      />
      {loading ? (
        <Skeleton />
      ) : (
        <TableDeletedPost
          data={data?.trashedPosts?.nodes}
          total={data?.trashedPosts?.totalCount}
          onDirectionChange={handleChangeTableSorting}
        />
      )}
    </>
  )
}

export default ScreenDeletedPost
