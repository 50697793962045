import React, { useEffect } from 'react'
import { AdminMenuTitle } from 'components/AdminMenuTitle'
import { ThumbUpOutlined as ThumbUpOutlinedIcon } from '@material-ui/icons'
import BestPostTable from '../components/BestPostTable'
import BestPostRequirement from '../components/BestPostRequirement'
import gql from 'graphql-tag'
import { message } from 'antd'
import {
  OrderDirection,
  PostOrderField,
  useBestOfBestRequirementQuery,
  useBestPostsLazyQuery,
} from 'generated/graphql'
import { Skeleton } from 'antd'
import useAuthenticationCheck from '../hooks/useAuthenticationCheck'

gql`
  query BestOfBestRequirement {
    bestOfBestRequirement {
      id
      ...RequirementInputs
    }
  }

  query BestPosts(
    $pagination: Pagination
    $filterBy: PostFiltersInput
    $orderBy: PostOrder
  ) {
    bestOfBestPostPreviews(
      pagination: $pagination
      filterBy: $filterBy
      orderBy: $orderBy
    ) {
      nodes {
        id
        createdAt
        views
        commentCount
        likes
        score
        scrapCount
        title
      }
    }
  }

  mutation UpdateBestOfBestRequirement(
    $input: CollectionRequirementUpdateInput!
  ) {
    updateBestOfBestRequirement(input: $input) {
      collectionRequirement {
        comment
        like
        scrap
        view
        id
      }
    }
  }
`

const ScreenBestPostsManagement = () => {
  const { data, loading, error } = useBestOfBestRequirementQuery()
  useAuthenticationCheck()

  useEffect(() => {
    if (!data) return

    loadTableData({
      variables: {
        filterBy: {
          minimumCommentCount: data.bestOfBestRequirement?.comment,
          minimumViewCount: data.bestOfBestRequirement?.view,
          minimumLikeCount: data.bestOfBestRequirement?.like,
          scrapCount: data.bestOfBestRequirement?.scrap,
        },
        pagination: {
          page: 1,
          pageSize: 100,
        },
        orderBy: {
          field: PostOrderField['SCORE'],
          direction: OrderDirection['DESC'],
        },
      },
    })
  }, [data])

  const [
    loadTableData,
    { loading: tableDataLoading, data: bestPostTable, refetch },
  ] = useBestPostsLazyQuery()

  if (loading) {
    return <Skeleton active />
  }

  if (error) {
    message.error('에러가 발생했습니다.')
    return <div>에러가 발생했습니다.</div>
  }

  return (
    <>
      <AdminMenuTitle
        icon={<ThumbUpOutlinedIcon />}
        title="명예의 전당 관리"
        description="명예의 전당 게시판의 조건을 변경할 수 있습니다."
      />
      <BestPostRequirement
        initialValue={data?.bestOfBestRequirement!}
        onRefetch={refetch}
      />
      {tableDataLoading ? (
        <Skeleton />
      ) : (
        <BestPostTable
          onRefetch={refetch}
          dataSource={bestPostTable?.bestOfBestPostPreviews?.nodes}
        />
      )}
    </>
  )
}

export default ScreenBestPostsManagement
